import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import Pagination from 'components/common/pagination';
import Tag from 'components/common/tag';
import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';
import useEquipmentStatus from 'helpers/useEquipmentStatus';
import useExpirationColor from 'helpers/useExpirationColor';

function HoseBunTable({ hoseBuns, paginationVariables, setPaginationVariables, resultCount, pageInfo, sortBy, setSortBy }) {
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  const {textColor} = useExpirationColor();
  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }
  return (
    <Wrapper>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('plNumber')}>
                PL-number <StyledOrderIcon active={sortBy.type === 'plNumber' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('serialNumber')}>
                Serial Nr. <StyledOrderIcon active={sortBy.type === 'serialNumber' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('diameter')}>
                Diameter <StyledOrderIcon active={sortBy.type === 'diameter' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('location')}>
                Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('type')}>
                Type <StyledOrderIcon active={sortBy.type === 'type' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('status')}>
                Status <StyledOrderIcon active={sortBy.type === 'status' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('expireDate')}>
                Expire date <StyledOrderIcon active={sortBy.type === 'expireDate' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell>
                Last products
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hoseBuns.length
              ? hoseBuns.map((hoseBun) => (
                  <TableRow key={hoseBun.id}>
                    <TableCell>
                      <Link to={`/dashboard/equipment/hoseBuns/${hoseBun.id}`}>{hoseBun.plNumber}</Link>
                    </TableCell>
                    <TableCell>
                      {hoseBun.serialNumber}
                    </TableCell>
                    <TableCell>
                      {hoseBun.diameter}
                    </TableCell>
                    <TableCell>
                      {hoseBun.location}
                    </TableCell>
                    <TableCell>
                      {hoseBun.type}
                    </TableCell>
                    <TableCell>
                      {hoseBun.status && <Tag color={tagColor(hoseBun.status)} label={equipmentStatusLabel(hoseBun.status)} />}
                    </TableCell>
                    <TableCell style={{color: textColor(hoseBun.expireDate)}}>
                      {hoseBun.expireDate ? moment(hoseBun.expireDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}
                    </TableCell>
                    <TableCell>
                      {hoseBun.lastProducts}
                    </TableCell>
                  </TableRow>
                ))
              : <TableRow>
              <TableCell>No results</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              }
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  
`;

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default HoseBunTable;
import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { UPDATE_AVATAR } from 'redux/reducers/auth';
import Block from 'components/common/block';
import ToggleInput from 'components/common/toggleInput';
import Avatar from 'components/common/avatar';
import Tag from 'components/common/tag';
import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import NotFound from 'components/common/404';

import UserOptions from './userOptions';

const GetUserQuery = gql`
  query GetUser($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        firstName
        lastName
        email
        phone
        roles
        created
        imageUrl
        roles
        status
      }
    }
  }
`;

const UpdateUserMutation = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(updateUserData: $input) {
      user {
        id
        firstName
        lastName
        email
        phone
        roles
        created
        roles
        imageUrl
        status
      }
    }
  }
`;

const ArchiveUserMutation = gql`
  mutation ArchiveUser($input: ArchiveUserInput!) {
    archiveUser(archiveUserData: $input) {
      success
    }
  }
`;

const UploadImageUserMutation = gql`
  mutation UploadImageUser($input: UploadImageUserInput!) {
    uploadImageUser(uploadImageUserData: $input) {
      user {
        id
        imageUrl
      }
    }
  }
`;

const ResendOnboardingMailMutation = gql`
  mutation ResendOnboardingMail($input: ResendOnboardingMailInput!) {
    resendOnboardingMail(resendOnboardingMailData: $input) {
      user {
        id
        status
      }
    }
  }
`;

const roles = [
  'admin',
  'member',
]

function User() {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = history.location.pathname.split('/users/')[1];
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [editing, setEditing] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState([]);
  const loggedInUser = useSelector(state => state.auth.user);
  const isSelf = loggedInUser.id === id;
  const isAdmin = loggedInUser.roles.includes('admin');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = React.useState(false);
  const [tempImageDisplay, setTempImageDisplay] = React.useState(null);
  const [tempImageFile, setTempImageFile] = React.useState(null);
  const [onboardingConfirmationVisible, setOnboardingConfirmationVisible] = React.useState(false);

  const { data: queryData, error, loading: queryLoading, refetch: refetchUser } = useQuery(GetUserQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onCompleted(result) {
      const user = result.node;
      if (!user) return

      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone || '');
      setEmail(user.email);
      setUserRoles(user.roles);
    },
    onError(e) {
      console.log(e);
    }
  });

  const input = {
    firstName,
    lastName,
    phone,
    roles: userRoles,
  };

  if (!isSelf) input.userId = id;
  if (isSelf) delete input.roles;

  const [executeUpdateUser, { loading: updateLoading, data: updateData }] = useMutation(UpdateUserMutation, {
    variables: {
      input,
    },
    onError(e) {
      console.log(e);
    }
  });

  const [executeArchiveUser] = useMutation(ArchiveUserMutation, {
    variables: {
      input: {
        userId: id,
      },
    },
    onCompleted() {
      history.push('/dashboard/users');
    },
    onError(e) {
      console.log(e);
    }
  });

  const [executeNewAvatar] = useMutation(UploadImageUserMutation, {
    onCompleted(result) {
      dispatch({
        type: UPDATE_AVATAR,
        payload: {
          imageUrl: result.uploadImageUser.user.imageUrl,
        }
      });
      setTempImageDisplay(null);
      setTempImageFile(null);
      refetchUser();
    },
    onError(e) {
      console.log(e);
    }
  });

  const [executeOnboardingMail] = useMutation(ResendOnboardingMailMutation, {
    variables: {
      input: {
        userId: queryData && queryData.node ? queryData.node.id : null,
      }
    },
    onCompleted(result) {
      setOnboardingConfirmationVisible(true);
    },
    onError(e) {
      console.log(e);
    }
  });

  function save() {
    if (tempImageDisplay) {
      executeNewAvatar({
        variables: {
          input: {
            file: tempImageFile,
          }
        }
      });
    }
    executeUpdateUser();
    setEditing(false);
  }

  function cancel() {
    const user = updateData ? updateData.updateUser.user : queryData.node;

    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setEmail(user.email);
    setUserRoles(user.roles);
    setTempImageDisplay(null);
    setTempImageFile(null);

    setEditing(false);
  }

  function roleInputHandler(val) {
    if (userRoles.includes(val)) {
      setUserRoles(userRoles.filter(r => r !== val));
    } else {
      setUserRoles([...userRoles, val]);
    }
  }

  function newImageHandler(file) {
    setTempImageDisplay(URL.createObjectURL(file));
    setTempImageFile(file);
  }

  if (queryLoading || updateLoading) return <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />;
  if (error || !queryData.node) return <NotFound />
  return (
    <StyledBlock
      title={
        <BlockTitle>
          <p>GENERAL INFORMATION</p>
          {
            isAdmin || isSelf
              ? <UserOptions
                  editing={editing}
                  setEditing={setEditing}
                  save={save}
                  cancel={cancel}
                  archivePrompt={() => setDeleteConfirmationVisible(true)}
                  isAdmin={isAdmin}
                  isSelf={isSelf}
                  sendActivationMail={executeOnboardingMail}
                  notOnboarded={queryData.node.status === 'onboarding'}
                />
              : null
          }
        </BlockTitle>
      }
    >
      <Content>
        <Column>
          <ToggleInput
            id="firstName"
            label="First name"
            variant="outlined"
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
            type="text"
            fullWidth
            style={{ marginBottom: '32px' }}
            editing={editing}
          />
          <ToggleInput
            id="lastName"
            label="Last name"
            variant="outlined"
            onChange={e => setLastName(e.target.value)}
            value={lastName}
            type="text"
            fullWidth
            style={{ marginBottom: '32px' }}
            editing={editing}
          />
          <ToggleInput
            label="Email address"
            value={email}
            style={{ marginBottom: '32px' }}
          />
          <ToggleInput
            id="phone"
            label="Phone"
            variant="outlined"
            onChange={e => setPhone(e.target.value)}
            value={phone}
            type="text"
            fullWidth
            editing={editing}
          />
        </Column>
        <Column>
          <Avatar
            size={112}
            user={queryData.node}
            style={{ marginBottom: '32px' }}
            editable={isSelf && editing}
            fontSize={32}
            tempImage={tempImageDisplay}
            newImageHandler={newImageHandler}
          />
          <ToggleInput
            label="Date registered"
            value={moment(queryData.node.created).format('DD/MM/YYYY')}
            style={{ marginBottom: '32px' }}
          />
          {
            editing
              ? <RolesWrapper>
                  <RolesLabel>Roles</RolesLabel>
                  {
                    roles.map((r) => (
                      <Role key={r}>
                        <Checkbox
                          disabled={r === 'member' || isSelf}
                          checked={userRoles.includes(r)}
                          onChange={() => roleInputHandler(r)}
                          name={`${r}-checked`}
                          color="primary"
                        />
                        <Tag color={r === 'member' ? 'yellow' : 'green'} label={r} />
                      </Role>
                    ))
                  }
                </RolesWrapper>
              : <RolesWrapper>
                  <RolesLabel>Roles</RolesLabel>
                  {userRoles.map(r => <Tag color={r === 'member' ? 'yellow' : 'green'} label={r} key={r} style={{ marginBottom: '8px' }} />)}
                </RolesWrapper>
          }
        </Column>
      </Content>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={deleteConfirmationVisible}
        autoHideDuration={6000}
        onClose={() => setDeleteConfirmationVisible(false)}
        message="Delete this user?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchiveUser}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setDeleteConfirmationVisible(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={onboardingConfirmationVisible}
        autoHideDuration={6000}
        onClose={() => setOnboardingConfirmationVisible(false)}
        message="Activation email was sent."
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOnboardingConfirmationVisible(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;
  margin-bottom: 32px;

  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const RolesWrapper = styled.div`
  padding: 0 12px;
  width: 100%;
`;

const RolesLabel = styled.p`
  color: black;
  font-size: 11px;
  margin-bottom: 8px;
`;

const Role = styled.div`
  display: flex;
  align-items: center;
`;

export default User;

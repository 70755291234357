import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Block from 'components/common/block';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';

import EditableVessel from 'components/common/editableStsVessel';

const StsVesselsQuery = gql`
  query StsVessels ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsvessels(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          name
          type
          captain
          captainEmail
        }
      }
    }
  }
`;

const CreateStsVesselMutation = gql`
  mutation CreateStsVessel($input: CreateStsVesselInput!) {
    createStsVessel(createStsVesselData: $input) {
      stsvessel {
        id
        created
      }
    }
  }
`;

function Vessels({ id, projectState, setProjectState }) {
  const { data, refetch } = useQuery(StsVesselsQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (!projectState.vesselsComplete && result.stsvessels.edges.length) {
        setProjectState({ ...projectState, vesselsComplete: true });
      }
    }
  });

  const [executeCreateVessel, { loading }] = useMutation(CreateStsVesselMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      refetch();
      if (!projectState.vesselsComplete ) {
        setProjectState({ ...projectState, vesselsComplete: true });
      }
    },
  });

  const vessels = data && data.stsvessels ? data.stsvessels.edges.map(v => v.node) : [];

  return (
    <StyledBlock title="ADD SHIPS">
      {
        vessels.map((v, i) => <EditableVessel key={v.id} vessel={v} index={i} refetch={refetch} />)
      }
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={executeCreateVessel}
          endIcon={<AddIcon />}
        >
         Add ship
        </Button>
      </ButtonWrapper>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;

  @media (${props => props.theme.breakpoints.tablet}) {
    margin-bottom: 68px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export default Vessels;

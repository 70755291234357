import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import Block from 'components/common/block';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const AddMiscellanyMutation = gql`
  mutation CreateMiscellany($input: CreateMiscellanyInput!) {
    createMiscellany(createMiscellanyData: $input) {
      miscellany {
        id
      }
    }
  }
`;

function NewMiscellany() {
  const [plNumber, setPlNumber] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [contents, setContents] = React.useState(['']);
  const [addedSnackbarOpen, setAddedSnackbarOpen] = React.useState(false);

  const {statusValues} = useEquipmentStatus();

  const [addMiscellany, { loading }] = useMutation(AddMiscellanyMutation, {
    variables: {
      input: {
        plNumber,
        location,
        status,
        contents,
      },
    },
    onCompleted() {
      setPlNumber('');
      setLocation('');
      setStatus('');
      setContents(['']);
      setAddedSnackbarOpen(true);
    },
    onError(e) {
      console.log(e)
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAddedSnackbarOpen(false);
  };

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
    {id: 'contents', label: 'Contents', onChange: val => setContents([val]), value: contents, type: 'contents'}, 
  ];

  const disabled = !plNumber.length || loading;

  return (
    <StyledBlock title="ADD MISCELLANEOUS">
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} forceEdit />)
        }
      </Grid>
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={addMiscellany}
          disabled={disabled}
        >
          Add miscellaneous
        </Button>
      </ButtonWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={addedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Miscellaneous successfully added"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  max-width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export default NewMiscellany;

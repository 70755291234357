import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Block from 'components/common/block';
import FileUpload from 'components/common/fileUpload';
import useDebounce from 'helpers/useDebounce';

import { ReactComponent as PublishIcon } from 'assets/vectors/download-16px.svg';
import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';
import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';

const CreateStsTimestampMutation = gql`
  mutation CreateStsTimestamp($input: CreateStsTimestampInput!) {
    createStsTimestamp(createStsTimestampData: $input) {
      ststimestamp {
        id
        created
      }
    }
  }
`;

const UploadDocument = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

const StsTimestampSuggestionsQuery = gql`
  query StsTimestampSuggestions ($first: Int, $after: String, $sortBy: [String], $search: String){
    ststimestampSuggestions(first: $first, after: $after sortBy: $sortBy, search: $search) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          value
        }
      }
    }
  }
`;

function New({ id, refetchList }) {
  const [date, setDate] = React.useState(moment());
  const [useCurrentTime, setUseCurrentTime] = React.useState(false);
  const [location, setLocation] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [files, setFiles] = React.useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = React.useState(null)

  const debouncedSearch = useDebounce(description, 300);

  const { data: suggestionsData } = useQuery(StsTimestampSuggestionsQuery, {
    variables: {
      search: debouncedSearch.length ? debouncedSearch : null,
      sortBy: ['value asc'],
      first: 10,
    },
    skip: !debouncedSearch.length,
    fetchPolicy: 'network-only',
  });

  const input = {
    stsprojectId: id,
    useCurrentTime,
    location,
    description,
  };

  if (!useCurrentTime) {
    input.localDatetime = date.format('YYYY-MM-DDTHH:mm');
  } 

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocument);


  const [executeCreateTimeStamp, { loading: timeStampLoading }] = useMutation(CreateStsTimestampMutation, {
    variables: {
      input,
    },
    onCompleted(result) {
      const timeStampId = result.createStsTimestamp.ststimestamp.id;

      files.forEach((file) => {
        executeUploadDocument({
          variables: {
            input: {
              file,
              filename: file.name,
              ststimestampId: timeStampId,
            }
          }
        })
      })

      refetchList();

      setDate(moment());
      setUseCurrentTime(false);
      setLocation('');
      setDescription('');
      setFiles([]);
    },
  });

  function checkboxHandler() {
    if (!useCurrentTime) {
      setDate(moment());
    }
    setUseCurrentTime(!useCurrentTime);
  }

  async function addFile(file) {
    setFiles([...files, file]);
  }

  function removeFile(fileName) {
    setFiles(files.filter(f => f.name !== fileName));
  }

  const suggestions = suggestionsData ? suggestionsData.ststimestampSuggestions.edges.map(u => u.node) : [];

  return (
    <StyledBlock title="ADD TIMESTAMP">
      {
        documentLoading || timeStampLoading
          ? <CircularProgress size={64} style={{ margin: '64px auto', display: 'block' }} />
          : (
              <>
                <Row>
                  <KeyboardDateTimePicker
                    variant="inline"
                    label="Date and time"
                    format="DD/MM/YYYY HH:mm"
                    id="new-log-entry-date-time"
                    value={date}
                    onChange={setDate}
                    ampm={false}
                    inputVariant="outlined"
                    disabled={useCurrentTime}
                    helperText="Local time"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useCurrentTime}
                        onChange={checkboxHandler}
                        name="Now"
                        color="primary"
                      />
                    }
                    label="Now"
                    style={{ marginLeft: '32px', marginBottom: '16px' }}
                  />
                </Row>
                <Autocomplete
                  value={selectedSuggestion}
                  onChange={(event, newValue) => setSelectedSuggestion(newValue)}
                  onBlur={e => setDescription(e.target.value)}
                  inputValue={description}
                  onInputChange={(event, newInputValue) => setDescription(newInputValue)}
                  id="suggestions"
                  fullWidth
                  options={suggestions}
                  autoHighlight
                  getOptionLabel={(option) => option.value || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  noOptionsText="No results"
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.value}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Description"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      fullWidth
                      multiline
                      rows={4}
                      style={{ marginBottom: '32px' }}
                    />
                  )}
                />
                <TextField
                  id="Location"
                  label="Location (optional)"
                  variant="outlined"
                  onChange={e => setLocation(e.target.value)}
                  value={location}
                  type="text"
                  fullWidth
                  style={{ marginBottom: '32px' }}
                />
                <ButtonWrapper style={{ marginBottom: '16px' }}>
                  <FileUpload
                    id="newFile"
                    allowedTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv', 'application/vnd.ms-excel']}
                    upload={addFile}
                  >
                    <Add htmlFor="newFile">
                      ADD FILE <DocumentIcon />
                      <input
                        type="file"
                        required
                        id="newFile"
                        onChange={addFile}
                        style={{ display: 'none' }}
                      />
                    </Add>
                  </FileUpload>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={documentLoading || timeStampLoading || !description || !date}
                    onClick={executeCreateTimeStamp}
                    endIcon={<StyledPublishIcon />}
                  >
                    Publish
                  </Button>
                </ButtonWrapper>
                {
                  files.length
                    ? (
                        <FileList>
                          <FilesLabel>Filename</FilesLabel>
                          {
                            files.map(f => (
                              <FileRow key={f.name}>
                                <FileName>{f.name}</FileName>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={() => removeFile(f.name)}>
                                  <TrashIcon fontSize="small" />
                                </IconButton>
                              </FileRow>
                            ))
                          }
                        </FileList>
                      )
                    : null
                }
              </>
            )
      }
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 50%;

  @media (${props => props.theme.breakpoints.tablet}) {
   width: 100%;
   margin-bottom: 16px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

const StyledPublishIcon = styled(PublishIcon)`
  transform: rotate(180deg);
  color: white;
`;

const Add = styled.label`
  color: ${props => props.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  padding: 8px;
  border-radius: 4px;

  & svg {
    margin-left: 8px;
    opacity: .5
  }
`;

const FileList = styled.div`
  
`;

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;

const FilesLabel = styled.p`
  font-weight: 500;
  margin-bottom: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    margin-top: 16px;
  }
`;

const FileName = styled.p`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 500;
`;

export default New;
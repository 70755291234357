import React from 'react';
import styled from 'styled-components';

import List from './list';
import New from './new';

function Timeline({ id, allowEdit, preview, className, timestamps, loading, refetch }) {
  return (
    <Wrapper preview={preview} className={className}>
      <List
        id={id}
        timestamps={timestamps}
        loading={loading}
        refetchList={refetch}
        allowEdit={allowEdit}
        preview={preview}
      />
      { allowEdit && <New refetchList={refetch} id={id} /> }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;

  @media (${props => props.theme.breakpoints.tablet}) {
   flex-direction: column-reverse;
  }
`;

export default Timeline;
import React from 'react';
import styled from 'styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/vectors/more-16px.svg';

function ChildlessSectionItem({ itemIcon, itemPath, itemTitle, className, secondary }) {
  let { path } = useRouteMatch();
  return (
    <Wrapper className={className} secondary={secondary}>
      <ItemHeading to={`${path}${itemPath}`} exact={itemPath === '/'}>
        {itemIcon}
        <ItemTitle>{itemTitle}</ItemTitle>
        {!secondary && <StyledChevronRight />}
      </ItemHeading>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 8px;
  padding-left: ${props => props.secondary ? 0 : '8px'}
`;

const ItemHeading = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: #ECECEC;
  }

  &.active {
    background: #ECECEC;
  }
`;

const ItemTitle = styled.p`
  color: ${props => props.theme.colors.textColor};
  margin-left: 8px;
  font-weight: 500;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-left: auto;
  flex: none;
`;

export default ChildlessSectionItem;
import moment from 'moment';
import theme from 'theme';

export default function useExpirationColor() {
  function isAlmostExpired(date) {
    return moment(date).isBefore(moment().add(1, 'months'));
  }

  function isExpired(date) {
    return moment(date).isBefore(moment());
  }

  function textColor(date) {
    if (!date) {
      return theme.colors.textColor;
    } else if (isExpired(date)) {
      return theme.colors.red;
    } else if (isAlmostExpired(date)) {
      return theme.colors.orange;
    } else {
      return theme.colors.textColor;
    }
  }

  return {
    textColor,
    isExpired,
    isAlmostExpired,
  }
}
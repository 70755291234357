import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Block from 'components/common/block';
import { ReactComponent as StartDateIcon } from 'assets/vectors/start-date-16px.svg';
import { ReactComponent as EndDateIcon } from 'assets/vectors/end-date-16px.svg';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        projectId
        startDate
        endDate
        location
        description
        timezoneOffset
        product
        reference
        quantity
      }
    }
  }
`;

const CreateStsProjectMutation = gql`
  mutation CreateStsProject($input: CreateStsProjectInput!) {
    createStsProject(createStsProjectData: $input) {
      stsproject {
        id
        projectId
        startDate
        endDate
        location
        description
        timezoneOffset
        product
        reference
        quantity
      }
    }
  }
`;

const UpdateStsProjectMutation = gql`
  mutation UpdateStsProject($input: UpdateStsProjectInput!) {
    updateStsProject(updateStsProjectData: $input) {
      stsproject {
        id
        projectId
        startDate
        endDate
        location
        description
        timezoneOffset
        product
        reference
        quantity
      }
    }
  }
`;

const timeZoneRegex = /GMT[+-][0-9]{2}:[0-9]{2}/g;

function General({ projectState, setProjectState, id, setId, onStepChange, isActive, setEquipmentInfo }) {
  const [projectId, setProjectId] = React.useState('');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [location, setLocation] = React.useState('');
  const [timeZone, setTimeZone] = React.useState('GMT+');
  const [minutesOffset, setMinutesOffset] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [equipment, setEquipment] = React.useState('');
  const [product, setProduct] = React.useState('');
  const [reference, setReference] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [timezoneError, setTimeZoneError] = React.useState(false);

  const prevRef = React.useRef();

  React.useEffect(() => {
    prevRef.current = isActive;
  });

  const previouslyActive = prevRef.current;

  React.useEffect(() => {
    if (previouslyActive && !isActive) {
      if (updateProjectInfoData) {
        if (hasGeneralInformationDiff(updateProjectInfoData.updateStsProject.stsproject)) {
          executeUpdateProjectInformation();
        }
      } else if (queryData) {
        if (hasGeneralInformationDiff(queryData.node)) {
          executeUpdateProjectInformation();
        }
      } else if (createProjectData) {
        if (hasGeneralInformationDiff(createProjectData.createStsProject.stsproject)) {
          executeUpdateProjectInformation();
        }
      } else {
        executeCreateProject();
      }
    }
    // eslint-disable-next-line
  }, [isActive, previouslyActive])

  function timeZoneInputHandler(val) {
    if (val.match(timeZoneRegex)) {
      const hoursAsMinutes = parseInt(val.split('GMT')[1].split(':')[0].substring(1)) * 60;
      const minutes = parseInt(val.split(':')[1]);
      const totalMinutes = hoursAsMinutes + minutes;
      const operator = val[3];
      const minutesOffset = `${operator}${totalMinutes}`;

      setMinutesOffset(minutesOffset);
      setTimeZoneError(false);
    } else {
      setTimeZoneError(true);
    }
    setTimeZone(val);
  }

  function getTimeZoneFromMinutes(minutes) {
    let prefix = '+';
    let convertedMinutes = 0;

    if (minutes < 0) {
      prefix = '-';
      convertedMinutes = Math.abs(minutes);
    } else {
      convertedMinutes = minutes;
    }

    const hours = convertedMinutes / 60;
    const roundedHours = JSON.stringify(Math.floor(hours)).padStart(2, '0');
    const remainingMinutes = JSON.stringify(convertedMinutes - (roundedHours * 60)).padStart(2, '0');

    return `GMT${prefix}${roundedHours}:${remainingMinutes}`;
  }

  // Check if general tab is complete
  React.useEffect(() => {
    const valid = !!projectId && !!startDate && !!endDate && !!location && !!timeZone.match(timeZoneRegex) && !!product;

    if (projectState.generalComplete && !valid) {
      setProjectState({ ...projectState, generalComplete: false });
    }

    if (!projectState.generalComplete && valid) {
      setProjectState({ ...projectState, generalComplete: true }); 
    }
  }, [projectId, startDate, endDate, location, timeZone, projectState, setProjectState, product]);

  const { data: queryData } = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const project = result.node;

      if (project.projectId) setProjectId(project.projectId);
      if (project.startDate) setStartDate(moment(project.startDate), 'YYYY-MM-DD');
      if (project.endDate) setEndDate(moment(project.endDate), 'YYYY-MM-DD');
      if (project.location) setLocation(project.location);
      if (project.description) setDescription(project.description);
      if (project.product) setProduct(project.product);
      if (project.reference) setReference(project.reference);
      if (project.quantity) setQuantity(project.quantity);
      if (project.equipment) {
        setEquipment(project.equipment);
        setProjectState({ ...projectState, equipmentComplete: true }); 
      };
      if (project.timezoneOffset) {
        setMinutesOffset(project.timezoneOffset);
        setTimeZone(getTimeZoneFromMinutes(project.timezoneOffset))
      };
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeCreateProject, { data: createProjectData }] = useMutation(CreateStsProjectMutation, {
    variables: {
      input: {
        projectId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        location,
        timezoneOffset: minutesOffset,
        description,
        product,
        reference,
        quantity,
      },
    },
    onCompleted(result) {
      setId(result.createStsProject.stsproject.id);
      window.history.replaceState(null, "", `?id=${result.createStsProject.stsproject.id}#step=2`);
    },
  });

  const [executeUpdateProjectInformation, { data: updateProjectInfoData }] = useMutation(UpdateStsProjectMutation, {
    variables: {
      input: {
        stsprojectId: id,
        projectId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        location,
        timezoneOffset: minutesOffset,
        description,
        product,
        reference,
        quantity,
      },
    },
  });

  function hasGeneralInformationDiff(node) {
    const currentData = {
      stsprojectId: id,
      projectId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      location,
      timezoneOffset: minutesOffset,
      description,
      equipment,
      product,
      reference,
      quantity,
    };

    const prevData = {
      stsprojectId: node.id,
      projectId: node.projectId,
      startDate: moment(node.startDate).format('YYYY-MM-DD'),
      endDate: moment(node.endDate).format('YYYY-MM-DD'),
      location: node.location,
      timezoneOffset: node.timezoneOffset,
      description: node.description,
      equipment: node.equipment,
      product: node.product,
      reference: node.reference,
      quantity: node.quantity,
    };

    return !_.isEqual(currentData, prevData);
  }

  return (
    <StyledBlock title="ADD GENERAL INFORMATION">
      <Segment>
        <Column>
          <TextField
            id="projectId"
            label="Project ID"
            variant="outlined"
            onChange={e => setProjectId(e.target.value)}
            value={projectId}
            type="text"
            helperText="STS-xxxx-00"
            fullWidth
            style={{ marginBottom: '16px' }}
          />
          <TextField
            id="reference"
            label="Client reference"
            variant="outlined"
            onChange={e => setReference(e.target.value)}
            value={reference}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/YYYY"
            id="date-picker-start-date"
            label="Start date"
            value={startDate}
            onChange={setStartDate}
            keyboardIcon={<StartDateIcon />}
            autoOk
            fullWidth
            style={{ marginBottom: '32px' }}
            inputVariant="outlined"
          />
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/YYYY"
            id="date-picker-end-date"
            label="End date"
            value={endDate}
            onChange={setEndDate}
            keyboardIcon={<EndDateIcon />}
            autoOk
            fullWidth
            inputVariant="outlined"
          />
        </Column>
      </Segment>
      <Segment>
        <Column>
          <TextField
            id="Rendezvous"
            label="Rendezvous"
            variant="outlined"
            onChange={e => setLocation(e.target.value)}
            value={location}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
          <TextField
            id="timeZone"
            label="Time zone"
            variant="outlined"
            onChange={e => timeZoneInputHandler(e.target.value.toUpperCase())}
            value={timeZone}
            type="text"
            helperText="GMT+01:00"
            error={timezoneError}
            inputProps={{ maxLength: 9 }}
            fullWidth
          />
        </Column>
      </Segment>
      <Segment>
        <Column>
          <TextField
            id="Product"
            label="Product"
            variant="outlined"
            onChange={e => setProduct(e.target.value)}
            value={product}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
          <TextField
            id="Quantity"
            label="Quantity"
            variant="outlined"
            onChange={e => setQuantity(e.target.value)}
            value={quantity}
            type="text"
            fullWidth
          />
        </Column>
      </Segment>
      <Segment style={{ border: 0 }}>
        <Column style={{ width: '100%', margin: 0 }}>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            onChange={e => setDescription(e.target.value)}
            value={description}
            type="text"
            fullWidth
            multiline
            rows={4}
            helperText="General notes"
          />
        </Column>
      </Segment>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;

  @media (${props => props.theme.breakpoints.laptop}) {
    margin-bottom: 68px;
  }
`;

const Segment = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (${props => props.theme.breakpoints.laptop}) {
    display: block;
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;
  margin-bottom: 32px;

  @media (${props => props.theme.breakpoints.laptop}) {
    width: 100%;
  }
`;

export default General;

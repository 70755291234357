import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from 'components/common/modal';
import { ReactComponent as SendIcon } from 'assets/vectors/send-white-16px.svg';
import File from 'components/common/fileRow';
import FileUpload from 'components/common/fileUpload';
import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';

const GetMaintenanceQuery = gql`
  query GetEquipmentLog($id: ID!) {
    node(id: $id) {
      ... on EquipmentLog {
        id
        timestamp
        location
        remarks
        attachedDocuments {
          id
          filename
          created
          documentUrl
        }
      }
    }
  }
`;

const ArchiveDocumentMutation = gql`
  mutation ArchiveDocument($input: ArchiveDocumentInput!) {
    archiveDocument(archiveDocumentData: $input) {
      success
    }
  }
`;

const UploadDocumentMutation = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

const CreateMaintenanceMutation = gql`
  mutation CreateEquipmentLog($input: CreateEquipmentLogInput!) {
    createEquipmentLog(createEquipmentLogData: $input) {
      equipmentLog {
        id
        created
      }
    }
  }
`;

const UpdateMaintenanceMutation = gql`
  mutation UpdateEquipmentLog($input: UpdateEquipmentLogInput!) {
    updateEquipmentLog(updateEquipmentLogData: $input) {
      equipmentLog {
        id
        created
      }
    }
  }
`;

function MaintenanceModal({ fenderId, setShowMaintenanceModal, refetchMaintenance, id, setEditId }) {
  const [maintenanceId, setMaintenanceId] = React.useState(id)
  const [timestamp, setTimestamp] = React.useState(null);
  const [location, setLocation] = React.useState('');
  const [remarks, setRemarks] = React.useState('');
  const [executeCreateMaintenance, { loading: createLoading }] = useMutation(CreateMaintenanceMutation, {
    variables: {
      input: {
        fenderId,
        timestamp,
        location,
        remarks
      }
    },
    onCompleted(res) {
      setMaintenanceId(res.createEquipmentLog.equipmentLog.id)
    },
  });

  const [executeUpdateMaintenance, { loading: updateLoading }] = useMutation(UpdateMaintenanceMutation, {
    variables: {
      input: {
        equipmentLogId: maintenanceId,
        timestamp,
        location,
        remarks
      }
    },
    onCompleted(res) {
      // setMaintenanceId(res.createEquipmentLog.equipmentLog.id)
    },
  });

  const { data, loading: queryLoading, refetch } = useQuery(GetMaintenanceQuery, {
    variables: {
      id: maintenanceId,
    },
    onCompleted: (res) => {
      setTimestamp(res.node.timestamp);
      setLocation(res.node.location);
      setRemarks(res.node.remarks);
    },
    skip: !maintenanceId,
    fetchPolicy: 'network-only',
  });

  const [executeArchiveDocument, { loading: removeDocumentLoading }] = useMutation(ArchiveDocumentMutation, {
    onCompleted(result) {
      refetch();
    },
  });

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocumentMutation, {
    onCompleted() {
      refetch();
    }
  });

  function removeFile(id) {
    executeArchiveDocument({
      variables: {
        input: {
          documentId: id,
        }
      }
    })
  }

  function addFile(file) {
    executeUploadDocument({
      variables: {
        input: {
          file,
          filename: file.name,
          equipmentLogId: maintenanceId,
        }
      },
      skip: !maintenanceId,
    })
  }

  function close() {
    setEditId(null)
    refetchMaintenance();
    setShowMaintenanceModal(false);
  }

  const documents = data && data.node ? data.node.attachedDocuments : [];

  const disabledStyling = {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    cursor: 'default',
    pointerEvents: 'none',
    borderColor: 'white',
  }

  const disabled = createLoading || updateLoading || queryLoading || documentLoading || removeDocumentLoading || !timestamp;

  return (
    <Modal preventOutsideClick onClose={close} title="SEND REPORT">
      <Title>JOB LOG ENTRY</Title>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/YYYY"
            id="fenderMaintenanceId"
            label="Date"
            value={timestamp}
            onChange={setTimestamp}
            autoOk
            fullWidth
            inputVariant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="fenderLocation"
            label="Location"
            variant="outlined"
            onChange={e => setLocation(e.target.value)}
            value={location}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            id="fenderRemarks"
            label="Remarks"
            variant="outlined"
            onChange={e => setRemarks(e.target.value)}
            value={remarks}
            type="text"
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={maintenanceId ? executeUpdateMaintenance : executeCreateMaintenance}
          disabled={disabled}
          endIcon={<SendIcon />}
        >
          {maintenanceId ? 'Update entry' : 'Add entry'}
        </Button>
      </ButtonWrapper>
      <Title>DOCUMENTS</Title>
      <Files>
        <FileHead>
          <FilesLabel>Attached files</FilesLabel>
          <FilesLabel>Date uploaded</FilesLabel>
        </FileHead>
        {
          documents.length
            ? documents.map(f => <File key={f.id} file={f} uploadDate={moment(f.created).format('DD/MM/YYYY')} showDelete removeFile={removeFile} />)
            : <File noResults />
        }
        { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
      </Files>
      {!maintenanceId && <DocumentHelper>Documents can only be added  <br />after the entry has been created.</DocumentHelper>}
      <UploadWrapper>
        <FileUpload
          id="newFile"
          allowedTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv', 'application/vnd.ms-excel']}
          upload={addFile}
          disabled={!maintenanceId || disabled}
        >
          <Add htmlFor="newFile" style={(!maintenanceId || disabled) ? disabledStyling : {}}>
            ADD FILE <DocumentIcon />
            <input
              type="file"
              required
              id="newFile"
              onChange={addFile}
              style={{ display: 'none' }}
            />
          </Add>
        </FileUpload>
      </UploadWrapper>
    </Modal>
  )
}

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const FileHead = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;
const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: black;
  font-weight: 500
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Add = styled.label`
  color: ${props => props.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  padding: 8px;
  border-radius: 4px;

  & svg {
    margin-left: 8px;
    opacity: .5
  }
`;

const DocumentHelper = styled.p`
  text-align: right;
  font-size: 12px
`;

export default MaintenanceModal;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import Block from 'components/common/block';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const AddReducerMutation = gql`
  mutation CreateReducer($input: CreateReducerInput!) {
    createReducer(createReducerData: $input) {
      reducer {
        id
      }
    }
  }
`;

function NewReducer() {
  const [serialNumber, setSerialNumber] = React.useState('');
  const [material, setMaterial] = React.useState('');
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [plNumber, setPlNumber] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [aFlange, setAFlange] = React.useState('');
  const [aConnection, setAConnection] = React.useState(0);
  const [bConnection, setBConnection] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [bFlange, setBFlange] = React.useState('');
  const [remark, setRemark] = React.useState('');
  const [addedSnackbarOpen, setAddedSnackbarOpen] = React.useState(false);

  const {statusValues} = useEquipmentStatus();

  const [addReducer, { loading }] = useMutation(AddReducerMutation, {
    variables: {
      input: {
        serialNumber,
        material,
        length: length || 0,
        location,
        plNumber,
        status,
        aFlange,
        aConnection: aConnection || 0,
        bConnection: bConnection || 0,
        weight: weight || 0,
        bFlange,
        remark,
      },
    },
    onCompleted() {
      setSerialNumber('');
      setMaterial('');
      setLength(0);
      setLocation('');
      setPlNumber('');
      setStatus('');
      setAFlange('');
      setAConnection(0);
      setBConnection(0);
      setWeight(0);
      setBFlange('');
      setRemark('');
      setAddedSnackbarOpen(true);
    },
    onError(e) {
      console.log(e)
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAddedSnackbarOpen(false);
  };

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'length', label: 'Length in cm', onChange: setLength, value: length, type: 'number'},
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'material', label: 'Material', onChange: setMaterial, value: material, type: 'text'}, 
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'aConnection', label: 'Connection A in inches', onChange: setAConnection, value: aConnection, type: 'number'}, 
    {id: 'aFlange', label: 'Flange A', onChange: setAFlange, value: aFlange, type: 'text'}, 
    {id: 'bConnection', label: 'Connection B in inches', onChange: setBConnection, value: bConnection, type: 'number'},
    {id: 'bFlange', label: 'Flange B', onChange: setBFlange, value: bFlange, type: 'text'},
    {id: 'remark', label: 'Remark', onChange: setRemark, value: remark, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading;

  return (
    <StyledBlock title="ADD REDUCER">
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} forceEdit />)
        }
      </Grid>
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={addReducer}
          disabled={disabled}
        >
          Add reducer
        </Button>
      </ButtonWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={addedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Reducer successfully added"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  max-width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export default NewReducer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { LOGIN, LOGOUT } from 'redux/reducers/auth';
import UserNode from 'helpers/userNode';

const RefreshTokenMutation = gql`
  mutation RefreshToken {
    refreshToken {
      authorizationKey
      ${UserNode}
    }
  }
`;

function TokenCheck({ children }) {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);

  const [executeRefresh, { loading }] = useMutation(RefreshTokenMutation, {
    onCompleted(result) {
      if (result.refreshToken.user) {
        dispatch({
          type: LOGIN,
          payload: {
            token: result.refreshToken.authorizationKey,
            user: result.refreshToken.user,
          }
        });
      } else {
        dispatch({ type: LOGOUT });
      }
    },
    onError(e) {
      dispatch({ type: LOGOUT });
    },
  });

  React.useEffect(() => {
    if (!loggedIn) {
      const token = localStorage.getItem('token');

      if (token) {
        executeRefresh();
      } else {
        dispatch({ type: LOGOUT });
      }
    }
  }, [dispatch, executeRefresh, loggedIn]);

  if (loading) return null;

  return (
    <>
      {children}
    </>
  );
}

export default TokenCheck;

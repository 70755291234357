import React from 'react';
import styled, { withTheme } from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

import ImageUpload from './imageUpload';

function CustomAvatar({ size, user, theme, fontSize, style, editable, tempImage, newImageHandler }) {
  if (!user) {
    return (
      <Avatar
        alt="?"
        style={{
          height: `${size}px`,
          width: `${size}px`,
          backgroundColor: theme.colors.primaryColor,
          fontSize: `${fontSize}px`,
        }}
      >
        ?
        </Avatar>
    )
  }

  const name = user.fullName ? user.fullName : user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '?';
  return (
    <Wrapper size={size} style={style}>
      <Avatar
        alt={name}
        src={tempImage || user.imageUrl}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          backgroundColor: theme.colors.primaryColor,
          fontSize: `${fontSize}px`,
        }}
      >
        {name[0].toUpperCase()}
      </Avatar>
      {
        editable && <ImageUpload id="newAvatar" onComplete={newImageHandler} imageSize={160} cropShape="round" aspect={1} allowedTypes={['image/jpeg', 'image/jpg', 'image/png']} />
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`;

export default withTheme(CustomAvatar);

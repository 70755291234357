import React from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { LOGOUT, LOGIN } from 'redux/reducers/auth';
import UserNode from 'helpers/userNode';
import Block from 'components/common/block';
import AuthForm from 'components/common/authForm';

const ClientUserOnboardingDetailsQuery = gql`
  query ClientUserOnboardingDetails($token: String!) {
    userOnboardingDetails(token: $token) {
      ${UserNode}
    }
  }
`;

const ClientUserOnboardingMutation = gql`
  mutation ClientUserOnboarding($input: UserOnboardingInput!) {
    userOnboarding(userOnboardingData: $input) {
      authorizationKey
      ${UserNode}
    }
  }
`;

function Onboarding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = history.location.pathname.split('/welcome/')[1];
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { error, loading: queryLoading } = useQuery(ClientUserOnboardingDetailsQuery, {
    variables: {
      token,
    },
    onCompleted(result) {
      const user = result.userOnboardingDetails.user;

      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone);
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    dispatch({ type: LOGOUT });
  }, [dispatch]);

  const [finishMemberOnboarding, { loading: finishLoading }] = useMutation(ClientUserOnboardingMutation, {
    variables: {
      input: {
        token,
        userFirstName: firstName,
        userLastName: lastName,
        userPassword: password,
        userPhone: phone,
      },
    },
    onCompleted(result) {
      dispatch({
        type: LOGIN,
        payload: {
          token: result.userOnboarding.authorizationKey,
          user: result.userOnboarding.user,
        }
      });

      history.push({
        pathname: '/dashboard',
      });
    },
    onError(e) {
      console.log(e);
    }
  });

  function submitHandler(e) {
    e.preventDefault();
    finishMemberOnboarding();
  }

  const isDisabled = finishLoading || queryLoading || !firstName.length || !lastName.length || !password.length;

  return (
    <AuthForm onSubmit={submitHandler}>
      {
        error
          ? (
            <Block title="TOKEN EXPIRED">
              <Text>This invitation has already been used or has expired. If your account has already been activated, click below to go to the login page.</Text>
              <ButtonWrapper>
                <NavLink to="/" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary">LOGIN</Button>
                </NavLink>
              </ButtonWrapper>
            </Block>
          )
          : (
            <>
              <Block title="WELCOME">
                <Text>You have been added to the Pro Liquid Project Manager. Please verify your details.</Text>
                <TextField
                  id="firstName"
                  label="First name"
                  variant="outlined"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  type="text"
                  autoComplete="given-name"
                  fullWidth
                  style={{ marginBottom: '32px' }}
                />
                <TextField
                  id="lastName"
                  label="Last name"
                  variant="outlined"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  type="text"
                  autoComplete="family-name"
                  fullWidth
                  style={{ marginBottom: '32px' }}
                />
                <TextField
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  onChange={e => setPhone(e.target.value)}
                  value={phone}
                  type="text"
                  autoComplete="tel-national"
                  fullWidth
                  style={{ marginBottom: '32px' }}
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  style={{ marginBottom: '32px' }}
                />
                <ButtonWrapper>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isDisabled}
                  >
                    Confirm
                  </Button>
                </ButtonWrapper>
              </Block>
            </>
          )
      }
    </AuthForm>
  )  
}

const Text = styled.p`
  color: ${props => props.theme.colors.textColor};
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`; 

export default Onboarding;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import Modal from 'components/common/modal';
import { ReactComponent as DescendingIcon } from 'assets/vectors/sortd-16px.svg';
import { ReactComponent as AscendingIcon } from 'assets/vectors/sorta-16px.svg';
import { ReactComponent as SendIcon } from 'assets/vectors/send-white-16px.svg';

const SendStsProjectReportMutation = gql`
  mutation SendStsProjectReport($input: SendStsProjectReportInput!) {
    sendStsProjectReport(sendStsProjectReportData: $input) {
      success
    }
  }
`;

const StsProjectsQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        stsclients {
          edges {
            node {
              id
              email
            }
          }
        }
        users {
          edges {
            node {
              id
              user {
                id
                fullName
                email
              }
            }
          }
        }
      }
    }
  }
`;

function ReportModal({ id, setShowReportModal, timestampRefetch, timestamps }) {
  const [sort, setSort] = React.useState('descending');
  const [note, setNote] = React.useState('Good day, dear all,\n\nPlease find below our latest timings concerning the underneath mentioned operation.');

  const [recipients, setRecipients] = React.useState([]);

  const { data, loading } = useQuery(StsProjectsQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
    onCompleted(result) {
      const clients = data.node.stsclients.edges.map(s => s.node).filter(s => s.email)
      const users = data.node.users.edges.map(s => s.node);

      const newStateRecipients = [];

      clients.forEach(client => {
        newStateRecipients.push({
          id: client.id,
          data: {
            email: client.email,
            label: client.email,
          },
          checked: true,
        })
      });

      users.forEach(user => {
        if (!user || !user.user) return;
        newStateRecipients.push({
          id: user.user.id,
          data: {
            email: user.user.email,
            label: user.user.fullName,
          },
          checked: false,
        })
      });

      setRecipients(newStateRecipients);
    }
  });

  const [executeSendReport, { loading: sendLoading }] = useMutation(SendStsProjectReportMutation, {
    variables: {
      input: {
        stsprojectId: id,
        note,
        order: sort,
        recipients: recipients.filter(r => r.data.email && r.checked).map(r => r.data.email),
      }
    },
    onCompleted() {
      timestampRefetch();
      setShowReportModal();
    },
  });

  function checkboxHandler(clickedRecipient) {
    const updatedRecipients = recipients.map(recipient => (
      recipient.id === clickedRecipient.id
        ? {...recipient, checked: !recipient.checked}
        : recipient
    ));

    setRecipients(updatedRecipients);
  }

  const newCount = timestamps.filter(t => t.isReported === false).length;
  const sendDisabled = loading || sendLoading || !recipients.filter(r => r.checked).length

  return (
    <Modal onClose={() => setShowReportModal(false)} title="SEND REPORT">
      <Title>SEND REPORT</Title>
      <Count>{newCount} new message(s), {timestamps.length} total.</Count>
      <SortWrapper>
        <SortOption active={sort === 'descending'} onClick={() => setSort('descending')}>
          <DescendingIcon />
          <SortLabel>Newest first</SortLabel>
        </SortOption>
        <SortOption active={sort === 'ascending'} onClick={() => setSort('ascending')}>
          <AscendingIcon />
          <SortLabel>Oldest first</SortLabel>
        </SortOption>
      </SortWrapper>
      <TextField
        id="notes"
        label="Notes"
        variant="outlined"
        onChange={e => setNote(e.target.value)}
        value={note}
        type="text"
        fullWidth
        multiline
        rows={4}
        style={{ margin: '32px 0' }}
      />
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                recipients
              </TableCell>
              <TableCell align="right">
                receive update?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipients.map((recipient) => (
              <TableRow key={recipient.id}>
                <TableCell>
                  {recipient.data.label || recipient.data.email}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={recipient.checked}
                    onChange={() => checkboxHandler(recipient)}
                    name="Now"
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          disabled={sendDisabled}
          onClick={executeSendReport}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const Count = styled.p`
  color: ${props => props.theme.colors.textColor};
`;

const SortWrapper = styled.div`
  display: flex;
  margin-top: 32px;
`;

const SortOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & p {
    color: ${props => props.active ? props.theme.colors.primaryColor : props.theme.colors.textColor};
  }

  & path.colored {
    fill: ${props => props.active ? props.theme.colors.primaryColor : props.theme.colors.textColor};
  }
`;

const SortLabel = styled.p`
  color: ${props => props.theme.colors.textColor};
  margin: 0 16px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;


export default ReportModal;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import useExpirationColor from 'helpers/useExpirationColor';
import Block from 'components/common/block';
import Pagination from 'components/common/pagination';
import { ReactComponent as FenderIcon } from 'assets/vectors/fenderIcon.svg';

const FendersQuery = gql`
  query Fenders ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $expiresIn: Int){
    fenders(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, expiresIn: $expiresIn) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          plNumber
          expireDatePvValve
          serialNumber
        }
      }
    }
  }
`;

const resultCount = 5;

function Fenders() {
  const [paginationVariables, setPaginationVariables] = React.useState({ first: resultCount });
  const {textColor} = useExpirationColor();
  const { data, loading } = useQuery(FendersQuery, {
    variables: {
      expiresIn: 31,
      sortBy: ['expireDatePvValve asc'],
      ...paginationVariables,
    },
    fetchPolicy: 'network-only',
  });

  const fenders = data ? data.fenders.edges.map(u => u.node) : [];
  const pageInfo = data ? data.fenders.pageInfo : {};

  return (
    <StyledBlock title={<BlockTitle><FenderIcon style={{ marginRight: '8px'}} /> EXPIRING FENDERS</BlockTitle>}>
      {
        loading
          ? <CircularProgress size={64} style={{ margin: '64px auto', display: 'block' }} />
          : <TableContainer>
              <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      PL Nr.
                    </TableCell>
                    <TableCell>
                      Serial Nr.
                    </TableCell>
                    <TableCell>
                      Expire date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    fenders.length
                      ? fenders.map((fender) => (
                          <TableRow key={fender.id}>
                            <TableCell>
                              <Link to={`/dashboard/equipment/fenders/${fender.id}`}>{fender.plNumber}</Link>
                            </TableCell>
                            <TableCell>
                              {fender.serialNumber}
                            </TableCell>
                            <TableCell style={{color: textColor(fender.expireDatePvValve)}}>
                              {fender.expireDatePvValve ? moment(fender.expireDatePvValve, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}
                            </TableCell>
                          </TableRow>
                        ))
                      : <TableRow>
                          <TableCell>No expiring fenders</TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
      }
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
        style={{
          paddingTop: '16px',
          marginTop: 'auto',
        }}
      />
    </StyledBlock>
  );
}

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primaryColor};
`;

const StyledBlock = styled(Block)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default Fenders;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetReducerQuery = gql`
  query GetReducer($id: ID!) {
    node(id: $id) {
      ... on Reducer {
        id
        plNumber
        status
        length
        material
        location
        aConnection
        aFlange
        bConnection
        bFlange
        serialNumber
        remark
        created
        lastUpdated
        weight
      }
    }
  }
`;

const UpdateReducerMutation = gql`
  mutation UpdateReducer($input: UpdateReducerInput!) {
    updateReducer(updateReducerData: $input) {
      reducer {
        id
      }
    }
  }
`;

const ArchiveReducerMutation = gql`
  mutation ArchiveReducer($input: ArchiveReducerInput!) {
    archiveReducer(archiveReducerData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [serialNumber, setSerialNumber] = React.useState('');
  const [material, setMaterial] = React.useState('');
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [plNumber, setPlNumber] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [aFlange, setAFlange] = React.useState('');
  const [aConnection, setAConnection] = React.useState(0);
  const [bConnection, setBConnection] = React.useState(0);
  const [bFlange, setBFlange] = React.useState('');
  const [remark, setRemark] = React.useState('');
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);
  const [weight, setWeight] = React.useState(0);
  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetReducerQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const reducer = result.node;
      if (!reducer) return;

      if (reducer.serialNumber) setSerialNumber(reducer.serialNumber);
      if (reducer.material) setMaterial(reducer.material);
      if (reducer.length) setLength(reducer.length);
      if (reducer.location) setLocation(reducer.location);
      if (reducer.plNumber) setPlNumber(reducer.plNumber);
      if (reducer.status) setStatus(reducer.status);
      if (reducer.aFlange) setAFlange(reducer.aFlange);
      if (reducer.aConnection) setAConnection(reducer.aConnection);
      if (reducer.bFlange) setBFlange(reducer.bFlange);
      if (reducer.bConnection) setBConnection(reducer.bConnection);
      if (reducer.remark) setRemark(reducer.remark);
      if (reducer.weight) setWeight(reducer.weight);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateReducerMutation, {
    variables: {
      input: {
        reducerId: id,
        serialNumber,
        material,
        length: length || 0,
        location,
        plNumber,
        status,
        aFlange,
        aConnection: aConnection || 0,
        bConnection: bConnection || 0,
        weight: weight || 0,
        bFlange,
        remark,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveReducerMutation, {
    variables: {
      input: {
        reducerId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/reducers');
    },
    onError: (e) => {
      console.log(e)
    }
  });

  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateReducer.reducer : data.node;

    setSerialNumber(lastSaved.serialNumber)
    setMaterial(lastSaved.material)
    setLength(lastSaved.length)
    setLocation(lastSaved.location)
    setPlNumber(lastSaved.plNumber)
    setStatus(lastSaved.status)
    setAConnection(lastSaved.aConnection)
    setAFlange(lastSaved.aFlange)
    setBConnection(lastSaved.bConnection)
    setBFlange(lastSaved.bFlange)
    setRemark(lastSaved.remark)
    setWeight(lastSaved.weight)
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'length', label: 'Length in cm', onChange: setLength, value: length, type: 'number'},
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'material', label: 'Material', onChange: setMaterial, value: material, type: 'text'}, 
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'aConnection', label: 'Connection A in inches', onChange: setAConnection, value: aConnection, type: 'number'}, 
    {id: 'aFlange', label: 'Flange A', onChange: setAFlange, value: aFlange, type: 'text'}, 
    {id: 'bConnection', label: 'Connection B in inches', onChange: setBConnection, value: bConnection, type: 'number'},
    {id: 'bFlange', label: 'Flange B', onChange: setBFlange, value: bFlange, type: 'text'},
    {id: 'remark', label: 'Remark', onChange: setRemark, value: remark, type: 'text'},
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this reducer?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import ToggleInput from 'components/common/toggleInput';
import Tag from 'components/common/tag';
import useEquipmentStatus from 'helpers/useEquipmentStatus';
import useExpirationColor from 'helpers/useExpirationColor';

const md = 3;
const xs = 6;

function GeneralInformationField({field, editing, forceEdit}) {
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  const {textColor} = useExpirationColor();
  function renderField(field) {
    const isEditing = forceEdit || editing;

    switch(field.type) {
      case 'text':
      case 'number':
        return (
          <ToggleInput
            id={field.id}
            label={field.label}
            variant="outlined"
            onChange={e => field.onChange(e.target.value)}
            value={field.value}
            type={field.type}
            fullWidth
            editing={isEditing}
          />
        )
      case 'date':
        return (
          isEditing
            ? (
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                id={field.id}
                label={field.label}
                value={field.value}
                onChange={field.onChange}
                autoOk
                fullWidth
                inputVariant="outlined"
              />
            )
            : (
              <>
                <Key>{field.label}</Key>
                <Val style={{
                  color: field.withExpirationHighlight && textColor(field.value)
                }}>{field.value ? moment(field.value).format('DD/MM/YYYY') : '-'}</Val>
              </>
            )
        )
      case 'select':
        const sortedFields = field.options.sort((a, b) => a.label.localeCompare(b.label));
        return (
          isEditing
            ? (
              <FormControl fullWidth>
                <InputLabel variant="outlined" id={`${field.id}Label`}>{field.label}</InputLabel>
                <Select
                  labelId={`${field.id}Label`}
                  variant="outlined"
                  id={field.id}
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}
                  label={field.label}
                >
                  {
                    sortedFields.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
                  }
                </Select>
              </FormControl>
            )
            : <>
                <Key>{field.label}</Key>
                <Tag style={{ marginLeft: '4px'}} color={tagColor(field.value)} label={equipmentStatusLabel(field.value)} />
              </>
        )
      case 'empty':
        return ''
      case 'contents':
        return (
          <ToggleInput
            id={field.id}
            label={field.label}
            variant="outlined"
            onChange={e => field.onChange(e.target.value)}
            value={field.value}
            type="text"
            fullWidth
            multiline
            rows={4}
            editing={isEditing}
          />
        )
      default:
        return <p>Unknown field type</p>
    }
  }
  return (
    <Grid item md={field.id === 'contents' ? 12 : md} xs={field.id === 'contents' ? 12 : xs} style={{marginLeft: field.id === 'status' && 'auto'}}>
      {renderField(field)}
    </Grid>
  )
}

const Key = styled.p`
  color: black;
  font-size: 11px;
  margin-bottom: 4px;
  margin-left: 12px;
  font-weight: 500
`;

const Val = styled.p`
  color: rgba(0,0,0,0.87);
  margin-left: 12px
`;


export default GeneralInformationField;

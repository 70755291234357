import React from 'react';

import Reducers from 'components/common/equipment/reducers/selectedReducers';
import Fenders from 'components/common/equipment/fenders/selectedFenders';
import Hoses from 'components/common/equipment/hoses/selectedHoses';
import HoseBuns from 'components/common/equipment/hoseBuns/selectedHoseBuns';
import InsulationFlanges from 'components/common/equipment/insulationFlanges/selectedInsulationFlanges';
import StsBoxes from 'components/common/equipment/stsBoxes/selectedStsBoxes';
import Miscellaneous from 'components/common/equipment/miscellaneous/selectedMiscellanies';

import General from './general';

function Equipment({ id, allowEdit }) {
  return (
    <>
      <General id={id} />
      <Reducers projectId={id} allowEdit={allowEdit} />
      <Fenders projectId={id} allowEdit={allowEdit} />
      <Hoses projectId={id} allowEdit={allowEdit} />
      <HoseBuns projectId={id} allowEdit={allowEdit} />
      <InsulationFlanges projectId={id} allowEdit={allowEdit} />
      <StsBoxes projectId={id} allowEdit={allowEdit} />
      <Miscellaneous projectId={id} allowEdit={allowEdit} />
    </>
  )
}

export default Equipment;

import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tag from 'components/common/tag';
import Pagination from 'components/common/pagination';
import Modal from 'components/common/modal';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';
import useEquipmentStatus from 'helpers/useEquipmentStatus';
import Filters from 'components/common/equipment/filters';
import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';

const MiscellaniesQuery = gql`
  query Miscellanies ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $status: String, $location: String){
    miscellanies(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, status: $status, location: $location) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          plNumber
          location
          contents
          status
          lastProducts
        }
      }
    }
  }
`;

const AddMiscellaniesMutation = gql`
  mutation AddStsProjectEquipment($input: AddStsProjectEquipmentInput!) {
    addStsProjectEquipment(addStsProjectEquipmentData: $input) {
      success
    }
  }
`;

const resultCount = 10;

function MiscellaniesModal({ setShowMiscellaniesModal, projectId, refetch, addedMiscellanies, onAdd }) {
  const [selectedMiscellanies, setSelectedMiscellanies] = React.useState([]);
  const [paginationVariables, setPaginationVariables] = React.useState({first: resultCount});
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  const [filters, setFilters] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({ type: 'plNumber', direction: 'asc' });

  const filterVariables = React.useMemo(() => {
    return filters.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.key]: cur.val,
      }
    }, {});
  }, [filters]);

  const { data, loading: listLoading } = useQuery(MiscellaniesQuery, {
    variables: {
      ...paginationVariables,
      ...filterVariables,
      sortBy: [`${sortBy.type} ${sortBy.direction}`]
    },
    fetchPolicy: 'network-only',
  });

  const [addMiscellanies, { loading: addLoading }] = useMutation(AddMiscellaniesMutation, {
    variables: {
      input: {
        stsprojectId: projectId,
        equipmentIds: selectedMiscellanies,
        equipmentType: 'miscellany',
      },
    },
    onCompleted() {
      refetch();
      onAdd && onAdd();
      setShowMiscellaniesModal(false);
    },
    onError(e) {
      console.log(e)
    }
  });

  const miscellanies = data ? data.miscellanies.edges.map(u => u.node) : [];
  const pageInfo = data ? data.miscellanies.pageInfo : {};

  function checkboxHandler(miscellanyId) {
    if (selectedMiscellanies.includes(miscellanyId)) {
      setSelectedMiscellanies(selectedMiscellanies.filter(id => id !== miscellanyId));
    } else {
      setSelectedMiscellanies([...selectedMiscellanies, miscellanyId]);
    }
  }

  function checkboxDisabled(miscellany) {
    return addedMiscellanies.includes(miscellany.id) || miscellany.status !== 'stand_by';
  }

  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }

  const addButtonDisabled = listLoading || addLoading || !selectedMiscellanies.length

  return (
    <Modal onClose={() => setShowMiscellaniesModal(false)} title="STS BOXES" style={{ width: '90%'}} preventOutsideClick>
      <Title>ADD MISCELLANEOUS</Title>
      <Filters filters={filters} setFilters={setFilters} equipmentType="miscellany" />
      {
        listLoading
          ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
          : (
            <>
              <TableContainer>
                <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('plNumber')}>
                        PL Nr. <StyledOrderIcon active={sortBy.type === 'plNumber' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('location')}>
                        Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('contents')}>
                        Contents <StyledOrderIcon active={sortBy.type === 'contents' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('status')}>
                        Status <StyledOrderIcon active={sortBy.type === 'status' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell>
                        Last products
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    !miscellanies.length
                      ? <TableRow>
                          <TableCell>No results</TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      : (
                        miscellanies.map(miscellany => (
                          <TableRow key={miscellany.id}>
                            <TableCell>
                              <Checkbox
                                checked={selectedMiscellanies.includes(miscellany.id)}
                                onChange={() => checkboxHandler(miscellany.id)}
                                name="Now"
                                color="primary"
                                disabled={checkboxDisabled(miscellany)}
                              />
                            </TableCell>
                            <TableCell>
                              <Link target="_blank" href={`/dashboard/equipment/miscellaneous/${miscellany.id}`}>{miscellany.plNumber}</Link>
                            </TableCell>
                            <TableCell>
                              {miscellany.location}
                            </TableCell>
                            <TableCell style={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                              {miscellany.contents}
                            </TableCell>
                            <TableCell>
                              <Tag color={tagColor(miscellany.status)} label={equipmentStatusLabel(miscellany.status)} />
                            </TableCell>
                            <TableCell>
                              {miscellany.lastProducts}
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
              />
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addMiscellanies}
                  endIcon={<AddIcon />}
                  disabled={addButtonDisabled}
                >
                Add
                </Button>
              </ButtonWrapper>
            </>
          )
      }
    </Modal>
  );
}

const Link = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;


export default MiscellaniesModal;

import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tag from 'components/common/tag';
import Pagination from 'components/common/pagination';
import Modal from 'components/common/modal';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';
import useEquipmentStatus from 'helpers/useEquipmentStatus';
import Filters from 'components/common/equipment/filters';
import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';

const InsulationFlangesQuery = gql`
  query InsulationFlanges ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $status: String, $size: Float, $type: String, $location: String){
    insulationFlanges(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, status: $status, size: $size, type: $type, location: $location) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          plNumber
          serialNumber
          size
          type
          location
          status
          lastProducts
        }
      }
    }
  }
`;

const AddInsulationFlangesMutation = gql`
  mutation AddStsProjectEquipment($input: AddStsProjectEquipmentInput!) {
    addStsProjectEquipment(addStsProjectEquipmentData: $input) {
      success
    }
  }
`;

const resultCount = 10;

function InsulationFlangesModal({ setShowInsulationFlangesModal, projectId, refetch, addedInsulationFlanges, onAdd }) {
  const [selectedInsulationFlanges, setSelectedInsulationFlanges] = React.useState([]);
  const [paginationVariables, setPaginationVariables] = React.useState({first: resultCount});
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  const [filters, setFilters] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({ type: 'plNumber', direction: 'asc' });

  const filterVariables = React.useMemo(() => {
    return filters.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.key]: cur.val,
      }
    }, {});
  }, [filters]);

  const { data, loading: listLoading } = useQuery(InsulationFlangesQuery, {
    variables: {
      ...paginationVariables,
      ...filterVariables,
      sortBy: [`${sortBy.type} ${sortBy.direction}`]
    },
    fetchPolicy: 'network-only',
  });

  const [addInsulationFlanges, { loading: addLoading }] = useMutation(AddInsulationFlangesMutation, {
    variables: {
      input: {
        stsprojectId: projectId,
        equipmentIds: selectedInsulationFlanges,
        equipmentType: 'insulation_flange',
      },
    },
    onCompleted() {
      refetch();
      onAdd && onAdd();
      setShowInsulationFlangesModal(false);
    },
    onError(e) {
      console.log(e)
    }
  });

  const insulationFlanges = data ? data.insulationFlanges.edges.map(u => u.node) : [];
  const pageInfo = data ? data.insulationFlanges.pageInfo : {};

  function checkboxHandler(insulationFlangeId) {
    if (selectedInsulationFlanges.includes(insulationFlangeId)) {
      setSelectedInsulationFlanges(selectedInsulationFlanges.filter(id => id !== insulationFlangeId));
    } else {
      setSelectedInsulationFlanges([...selectedInsulationFlanges, insulationFlangeId]);
    }
  }

  function checkboxDisabled(insulationFlange) {
    return addedInsulationFlanges.includes(insulationFlange.id) || insulationFlange.status !== 'stand_by';
  }

  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }

  const addButtonDisabled = listLoading || addLoading || !selectedInsulationFlanges.length

  return (
    <Modal onClose={() => setShowInsulationFlangesModal(false)} title="INSULATION FLANGES" style={{ width: '90%'}} preventOutsideClick>
      <Title>ADD INSULATION FLANGES</Title>
      <Filters filters={filters} setFilters={setFilters} equipmentType="insulation_flange" />
      {
        listLoading
          ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
          : (
            <>
              <TableContainer>
                <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('plNumber')}>
                        PL Nr. <StyledOrderIcon active={sortBy.type === 'plNumber' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('serialNumber')}>
                        Serial Nr. <StyledOrderIcon active={sortBy.type === 'serialNumber' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('size')}>
                        Size <StyledOrderIcon active={sortBy.type === 'size' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('type')}>
                        Type <StyledOrderIcon active={sortBy.type === 'type' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('location')}>
                        Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('status')}>
                        Status <StyledOrderIcon active={sortBy.type === 'status' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell>
                        Last products
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    !insulationFlanges.length
                      ? <TableRow>
                          <TableCell>No results</TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      : (
                        insulationFlanges.map(insulationFlange => (
                          <TableRow key={insulationFlange.id}>
                            <TableCell>
                              <Checkbox
                                checked={selectedInsulationFlanges.includes(insulationFlange.id)}
                                onChange={() => checkboxHandler(insulationFlange.id)}
                                name="Now"
                                color="primary"
                                disabled={checkboxDisabled(insulationFlange)}
                              />
                            </TableCell>
                            <TableCell>
                              <Link target="_blank" href={`/dashboard/equipment/insulationFlanges/${insulationFlange.id}`}>{insulationFlange.plNumber}</Link>
                            </TableCell>
                            <TableCell>
                              {insulationFlange.serialNumber}
                            </TableCell>
                            <TableCell>
                              {insulationFlange.size}
                            </TableCell>
                            <TableCell>
                              {insulationFlange.type}
                            </TableCell>
                            <TableCell>
                              {insulationFlange.location}
                            </TableCell>
                            <TableCell>
                              {insulationFlange.status && <Tag color={tagColor(insulationFlange.status)} label={equipmentStatusLabel(insulationFlange.status)} />}
                            </TableCell>
                            <TableCell>
                              {insulationFlange.lastProducts}
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
              />
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addInsulationFlanges}
                  endIcon={<AddIcon />}
                  disabled={addButtonDisabled}
                >
                Add
                </Button>
              </ButtonWrapper>
            </>
          )
      }
    </Modal>
  );
}

const Link = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default InsulationFlangesModal;

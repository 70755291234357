import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';

import UserNode from 'helpers/userNode';
import { LOGIN } from 'redux/reducers/auth';
import AuthForm from 'components/common/authForm';
import Block from 'components/common/block';

const LoginMutation = gql`
  mutation Login($input: LoginInput!) {
    login(loginData: $input) {
      authorizationKey
      ${UserNode}
    }
  }
`;

function Login() {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  let history = useHistory();

  const [executeLogin, { loading }] = useMutation(LoginMutation, {
    variables: {
      input: {
        email,
        password,
      },
    },
    onCompleted(result) {
      if (result.login.user) {
        dispatch({
          type: LOGIN,
          payload: {
            token: result.login.authorizationKey,
            user: result.login.user,
          }
        });
      } else {
        setPassword('');
      }
    },
    onError(e) {
      setPassword('');
      setErrorSnackbarOpen(true);
    }
  });

  React.useEffect(() => {
    if (loggedIn) {
      let url = '/dashboard';

      if (history.location.state && history.location.state.returnUrl && history.location.state.returnUrl !== '/') {
        url = history.location.state.returnUrl;
      }

      history.push(url);
    }
  }, [loggedIn, history])

  function submitHandler(e) {
    e.preventDefault();
    executeLogin();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarOpen(false);
  };

  const isDisabled = loading || !email.length || !password.length;

  return (
    <AuthForm onSubmit={submitHandler}>
      <Block title="LOGIN">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          onChange={e => setEmail(e.target.value)}
          value={email}
          type="email"
          autoComplete="email"
          fullWidth
          style={{ marginBottom: '32px' }}
        />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
          value={password}
          type="password"
          autoComplete="current-password"
          fullWidth
          style={{ marginBottom: '16px' }}
        />
        <Link to="/forgot-password">Forgot your password?</Link>
        <ButtonWrapper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Login
          </Button>
        </ButtonWrapper>
      </Block>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Invalid credentials or unknown user"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </AuthForm>
  );
}

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
  margin-bottom: 32px;
  display: block;
  outline: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`; 

export default Login;

import React from 'react';
import StepWizard from 'react-step-wizard';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import Nav from './nav';
import General from './general';
import Vessels from './vessels';
import Users from './users';
import Equipment from './equipment';
import Docs from './docs';

function NewSts() {
  const history = useHistory();
  const [id, setId] = React.useState(qs.parse(history.location.search, { ignoreQueryPrefix: true }).id);

  const [projectState, setProjectState] = React.useState({
    generalComplete: false,
    vesselsComplete: false,
    usersComplete: false,
    equipmentComplete: false,
    docsComplete: false,
  });

  return (
    <StepWizard
      isHashEnabled
      nav={
        <Nav
          generalComplete={projectState.generalComplete}
          vesselsComplete={projectState.vesselsComplete}
          usersComplete={projectState.usersComplete}
          equipmentComplete={projectState.equipmentComplete}
          docsComplete={projectState.docsComplete}
          id={id}
        />
      }
    >
      <General
        projectState={projectState}
        setProjectState={setProjectState}
        id={id}
        setId={setId}
      />
      <Vessels
        projectState={projectState}
        setProjectState={setProjectState}
        id={id}
      />
      <Users
        projectState={projectState}
        setProjectState={setProjectState}
        id={id}
      />
      <Equipment
        projectState={projectState}
        setProjectState={setProjectState}
        id={id}
      />
      <Docs
        id={id}
      />
    </StepWizard>
  );
}

export default NewSts;

import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';

import { ReactComponent as EditIcon } from 'assets/vectors/edit-avatar-16px.svg';
import { ReactComponent as CloseIcon } from 'assets/vectors/close-16px.svg';
import { ReactComponent as WhiteCloseIcon } from 'assets/vectors/close-white-16px.svg';
import { ReactComponent as CheckIcon } from 'assets/vectors/check-white-16px.svg';

function ImageCropper({ selectedImage, onFinishCropping, imageSize, cropShape, aspect }) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [busy, setBusy] = React.useState(false);

  const createImage = url => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const getCroppedImage = async () => {
    const imageSrc = selectedImage;
    const pixelCrop = croppedAreaPixels;
    const rot = rotation;
    const image = await createImage(imageSrc);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rot));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const onCropComplete = (croppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  };

  const complete = async () => {
    setBusy(true);
    const newImage = await getCroppedImage();
    await setBusy(false);
    onFinishCropping(newImage);
  };

  return (
    <>
      <CropperWrapper>
        <Cropper
          image={selectedImage}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          cropShape={cropShape}
          aspect={aspect}
          zoomWithScroll={false}
        />
      </CropperWrapper>
      <Control>
        <Sliders>
          <SliderBlock>
            <SliderLabel>Zoom</SliderLabel>
            <Slider value={zoom} onChange={(e, val) => setZoom(val)} min={1} max={3} step={0.1} aria-labelledby="zoom-slider" />
          </SliderBlock>
          <SliderBlock>
            <SliderLabel>Rotation</SliderLabel>
            <Slider value={rotation} onChange={(e, val) => setRotation(val)} min={0} max={360} step={1} aria-labelledby="rotation-slider" />
          </SliderBlock>
        </Sliders>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={complete}
            disabled={busy}
            endIcon={<CheckIcon />}
          >
           Done
          </Button>
        </ButtonWrapper>
      </Control>
    </>
  );
}

function ImageUpload({ id, onComplete, imageSize, cropShape, aspect, allowedTypes }) {
  const [errorSnackbarVisible, setErrorSnackbarVisible] = React.useState(false);
  const [cropModalVisible, setCropModalVisible] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);

  function handleClose() {
    setImageSrc(null);
    setCropModalVisible(false);
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  function reduceSize(dataUrl) {
    const image = new Image();
    image.src = dataUrl;
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');

      let ratio = 1;
      if (image.width >= image.height) {
        ratio = (imageSize * 2) / image.width;
      } else {
        ratio = (imageSize * 2) / image.height;
      }

      canvas.width = image.width * ratio;
      canvas.height = image.height * ratio;

      canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL();
      setImageSrc(dataUrl);
    }, false);
  }

  async function onChange(e) {
    setCropModalVisible(true);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      if (allowedTypes.includes(file.type)) {
        const imageDataUrl = await readFile(file);
        reduceSize(imageDataUrl);
      } else {
        handleClose();
        setErrorSnackbarVisible(true);
      }
    }
  }
  
  async function onFinishCropping(promise) {
    const blob = await promise;
    const file = new File([blob], 'new_file', { type: 'image/jpeg' });
    onComplete(file);
    setCropModalVisible(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarVisible(false);
  };

  return (
    <>
      <EditOverlay>
        <EditButton htmlFor={id}>
          <EditIcon />
          <input
            type="file"
            required
            id={id}
            onChange={onChange}
            style={{ display: 'none' }}
          />
        </EditButton>
      </EditOverlay>
      {
        cropModalVisible && (
          <Modal>
            <InnerModal>
              <CloseButtonWrapper>
                <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </CloseButtonWrapper>
              <ImageCropper selectedImage={imageSrc} onFinishCropping={onFinishCropping} imageSize={imageSize} cropShape={cropShape} aspect={aspect} />
            </InnerModal>
          </Modal>
        )
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Invalid file type"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <WhiteCloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

const EditOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  &:hover {
    label {
      display: flex;
    }
  }
`;

const EditButton = styled.label`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerModal = styled.div`
  width: 640px;
  max-width: 90%;
  background: white;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CropperWrapper = styled.div`
  position: relative;
  height: 500px;
  width: 100%;
`;

const Control = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Sliders = styled.div`
  padding: 32px;
  width: 50%;
`;

const SliderBlock = styled.div`
  width: 100%;
  margin: 16px auto;
`;

const SliderLabel = styled.p`
  color: ${props => props.theme.colors.textColor};
  text-align: center;
  margin-bottom: 8px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ImageUpload;

import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import Block from 'components/common/block';
import File from 'components/common/fileRow';

function MaintenanceDocuments({ documents, docsLoading, docsError }) {
  if (docsError) return null;

  return (
    <StyledBlock title="DOCUMENTS">
      <Files>
        <FileHead>
          <FilesLabel>Attached files</FilesLabel>
          <FilesLabel>Date uploaded</FilesLabel>
        </FileHead>
        {
          documents.length
            ? documents.map(f => <File key={f.id} file={f} uploadDate={moment(f.created).format('DD/MM/YYYY')} />)
            : <File noResults />
        }
        { docsLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
      </Files>
    </StyledBlock>
  )
}

const StyledBlock = styled(Block)`
  max-width: 100%;
  margin-bottom: 16px;
`;

const FileHead = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;
const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: black;
  font-weight: 500
`;

export default MaintenanceDocuments;

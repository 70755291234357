import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';

import Block from 'components/common/block';
import ToggleInput from 'components/common/toggleInput';

import Reducers from 'components/common/equipment/reducers/selectedReducers';
import Fenders from 'components/common/equipment/fenders/selectedFenders';
import Hoses from 'components/common/equipment/hoses/selectedHoses';
import HoseBuns from 'components/common/equipment/hoseBuns/selectedHoseBuns';
import InsulationFlanges from 'components/common/equipment/insulationFlanges/selectedInsulationFlanges';
import StsBoxes from 'components/common/equipment/stsBoxes/selectedStsBoxes';
import Miscellaneous from 'components/common/equipment/miscellaneous/selectedMiscellanies';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        location
        product
        quantity
      }
    }
  }
`;

function Equipment({ id, projectState, setProjectState }) {
  const {data} = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    skip: !id,
    onError(e) {
      console.log(e);
    }
  });
  
  function onAdd() {
    if (!projectState.equipmentComplete) {
      setProjectState({ ...projectState, equipmentComplete: true });
    }
  }

  if (!data) return null;
  
  return (
    <>
      <StyledBlock title="EQUIPMENT">
        <Grid container spacing={2}>
          <Grid item md={4}>
            <ToggleInput
              id="product"
              label="Product"
              variant="outlined"
              value={data.node.product}
              type="text"
              fullWidth
              editing={false}
              style={{ marginBottom: '16px' }}
            />
          </Grid>
          <Grid item md={4}>
            <ToggleInput
              id="quantity"
              label="Quantity"
              variant="outlined"
              value={data.node.quantity}
              type="text"
              fullWidth
              editing={false}
              style={{ marginBottom: '16px' }}
            />
          </Grid>
          <Grid item md={4}>
            <ToggleInput
              id="location"
              label="Location"
              variant="outlined"
              value={data.node.location}
              type="text"
              fullWidth
              editing={false}
              style={{ marginBottom: '16px' }}
            />
          </Grid>
        </Grid>
      </StyledBlock>
      <Reducers projectId={id} onAdd={onAdd} allowEdit={true} />
      <Fenders projectId={id} onAdd={onAdd} allowEdit={true} />
      <Hoses projectId={id} onAdd={onAdd} allowEdit={true} />
      <HoseBuns projectId={id} onAdd={onAdd} allowEdit={true} />
      <InsulationFlanges projectId={id} onAdd={onAdd} allowEdit={true} />
      <StsBoxes projectId={id} onAdd={onAdd} allowEdit={true} />
      <Miscellaneous projectId={id} onAdd={onAdd} allowEdit={true} />
    </>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
  margin-bottom: 16px;
`;

export default Equipment;

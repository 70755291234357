const theme = {
  colors: {
    primaryColor: '#48AC41',
    textColor: '#000000DE',
    borderColor: '#B7B7B7',
    blue: '#4D9DE0',
    red: '#E15554',
    yellow: '#E1BC29',
    orange: '#E19729',
    black: '#4D4D4D'
  },
  breakpoints: {
    mobile: 'max-width: 480px',
    tablet: 'max-width: 768px',
    laptop: 'max-width: 1024px',
    desktop: 'max-width: 1200px',
    tv: 'min-width: 1201px',
  }
};

export default theme;
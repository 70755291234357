import React from 'react';
import styled from 'styled-components';

function Block({ title, children, noTitle, className, style }) {
  return (
    <Wrapper className={className} style={style}>
      {!noTitle && <Title>{title}</Title>}
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  box-shadow: 0px 1px 3px #00000029;
  max-width: 100%;
  position: relative;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

export default Block;
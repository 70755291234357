import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import Block from 'components/common/block';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const AddFenderMutation = gql`
  mutation CreateFender($input: CreateFenderInput!) {
    createFender(createFenderData: $input) {
      fender {
        id
      }
    }
  }
`;

function NewFender() {
  const [serialNumber, setSerialNumber] = React.useState('');
  const [diameter, setDiameter] = React.useState(0);
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [plNumber, setPlNumber] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [manufacturer, setManufacturer] = React.useState('');
  const [manufactureDate, setManufactureDate] = React.useState(null);
  const [dateInService, setDateInService] = React.useState(null);
  const [pressure, setPressure] = React.useState('');
  const [testDatePvValve, setTestDatePvValve] = React.useState(null);
  const [expireDatePvValve, setExpireDatePvValve] = React.useState(null);
  const [pvIdNumber, setPvIdNumber] = React.useState('');
  const [rope, setRope] = React.useState('');
  const [addedSnackbarOpen, setAddedSnackbarOpen] = React.useState(false);
  const [hoursInUse, setHoursInUse] = React.useState(0);
  const [weight, setWeight] = React.useState(0);

  const {statusValues} = useEquipmentStatus();

  const [addFender, { loading }] = useMutation(AddFenderMutation, {
    variables: {
      input: {
        serialNumber,
        diameter: diameter || 0,
        length: length || 0,
        location,
        plNumber,
        status,
        manufacturer,
        manufactureDate: manufactureDate ? moment(manufactureDate).format('YYYY-MM-DD') : null,
        dateInService: dateInService ? moment(dateInService).format('YYYY-MM-DD') : null,
        pressure,
        testDatePvValve: testDatePvValve ? moment(testDatePvValve).format('YYYY-MM-DD') : null,
        expireDatePvValve: expireDatePvValve ? moment(expireDatePvValve).format('YYYY-MM-DD') : null,
        pvIdNumber,
        rope,
        hoursInUse: hoursInUse || 0,
        weight: weight || 0,
      },
    },
    onCompleted(result) {
      setSerialNumber('');
      setDiameter(0);
      setLength(0);
      setLocation('');
      setPlNumber('');
      setStatus('');
      setManufacturer('');
      setManufactureDate(null);
      setDateInService(null);
      setPressure('');
      setTestDatePvValve(null);
      setExpireDatePvValve(null);
      setPvIdNumber('');
      setRope('');
      setHoursInUse(0);
      setAddedSnackbarOpen(true);
      setWeight(0)
    },
    onError(e) {
      console.log(e)
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAddedSnackbarOpen(false);
  };

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'}, 
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'diameter', label: 'Diameter in meters', onChange: setDiameter, value: diameter, type: 'number'}, 
    {id: 'length', label: 'Length in meters', onChange: setLength, value: length, type: 'number'}, 
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'manufacturer', label: 'Manufacturer', onChange: setManufacturer, value: manufacturer, type: 'text'}, 
    {id: 'manufactureDate', label: 'Manufacture date', onChange: setManufactureDate, value: manufactureDate, type: 'date'}, 
    {id: 'dateInService', label: 'Date in service', onChange: setDateInService, value: dateInService, type: 'date'}, 
    {id: 'pressure', label: 'Pressure', onChange: setPressure, value: pressure, type: 'text'}, 
    {id: 'testDatePvValve', label: 'Test date PV valve', onChange: setTestDatePvValve, value: testDatePvValve, type: 'date'}, 
    {id: 'expireDatePvValve', label: 'Expire date PV valve', onChange: setExpireDatePvValve, value: expireDatePvValve, type: 'date'}, 
    {id: 'pvIdNumber', label: 'PV ID Nr.', onChange: setPvIdNumber, value: pvIdNumber, type: 'text'}, 
    {id: 'rope', label: 'Rope', onChange: setRope, value: rope, type: 'text'}, 
    {id: 'hoursInUse', label: 'Hours in use', onChange: setHoursInUse, value: hoursInUse, type: 'number'},
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading;

  return (
    <StyledBlock title="ADD FENDER">
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} forceEdit />)
        }
      </Grid>
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={addFender}
          disabled={disabled}
        >
          Add fender
        </Button>
      </ButtonWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={addedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Fender successfully added"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  max-width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export default NewFender;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetMiscellanyQuery = gql`
  query GetMiscellany($id: ID!) {
    node(id: $id) {
      ... on Miscellany {
        id
        plNumber
        location
        contents
        status
      }
    }
  }
`;

const UpdateMiscellanyMutation = gql`
  mutation UpdateMiscellany($input: UpdateMiscellanyInput!) {
    updateMiscellany(updateMiscellanyData: $input) {
      miscellany {
        id
      }
    }
  }
`;

const ArchiveMiscellanyMutation = gql`
  mutation ArchiveMiscellany($input: ArchiveMiscellanyInput!) {
    archiveMiscellany(archiveMiscellanyData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [plNumber, setPlNumber] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [contents, setContents] = React.useState(['']);
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);

  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetMiscellanyQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const miscellany = result.node;
      if (!miscellany) return;

      if (miscellany.plNumber) setPlNumber(miscellany.plNumber);
      if (miscellany.location) setLocation(miscellany.location);
      if (miscellany.status) setStatus(miscellany.status);
      if (miscellany.contents) setContents(miscellany.contents);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateMiscellanyMutation, {
    variables: {
      input: {
        miscellanyId: id,
        plNumber,
        location,
        status,
        contents,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveMiscellanyMutation, {
    variables: {
      input: {
        miscellanyId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/miscellanies');
    },
    onError: (e) => {
      console.log(e)
    }
  });


  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateMiscellany.miscellany : data.node;

    setPlNumber(lastSaved.plNumber)
    setLocation(lastSaved.location)
    setStatus(lastSaved.status)
    setContents(lastSaved.contents)
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
    {id: 'contents', label: 'Contents', onChange: val => setContents([val]), value: contents, type: 'contents'}, 
  ];

  const disabled = !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this miscellaneous?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

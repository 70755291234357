import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import Pagination from 'components/common/pagination';
import Tag from 'components/common/tag';

import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';

function ProjectTable({ stsprojects, paginationVariables, setPaginationVariables, resultCount, pageInfo, sortBy, setSortBy }) {
  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }
  return (
    <Wrapper>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('projectId')}>
                Project ID <StyledOrderIcon active={sortBy.type === 'projectId' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('reference')}>
                Reference <StyledOrderIcon active={sortBy.type === 'reference' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('location')}>
                Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell>
                Ships
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('created')}>
                Creation date <StyledOrderIcon active={sortBy.type === 'created' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('startDate')}>
                Start date <StyledOrderIcon active={sortBy.type === 'startDate' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('endDate')}>
                End date <StyledOrderIcon active={sortBy.type === 'endDate' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer', width: '120px' }} onClick={() => sortHandler('dateStatus')}>
                Status <StyledOrderIcon active={sortBy.type === 'dateStatus' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stsprojects.map((project) => (
              <TableRow key={project.id}>
                <TableCell>
                  <ProjectLink to={`/dashboard/sts/${project.id}`}>{project.projectId}</ProjectLink>
                </TableCell>
                <TableCell>
                  {project.reference}
                </TableCell>
                <TableCell>
                  {project.location}
                </TableCell>
                <TableCell>
                  {project.stsvessels.edges.map((v, i) => `${v.node.name || '-'}${i === project.stsvessels.edges.length - 1 ? '' : ', '}`)}
                </TableCell>
                <TableCell>
                  {moment(project.created, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {moment(project.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {moment(project.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  <Tag color={project.dateStatus === 'planned' ? 'yellow' : project.dateStatus === 'finished' ? 'blue' : 'green'} label={project.dateStatus} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  
`;

const ProjectLink = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default ProjectTable;
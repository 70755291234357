import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FilterQuery = gql`
  query EquipmentFilter($equipmentType: String!) {
    equipmentFilters(equipmentType: $equipmentType)
  }
`;

function Filters({filters, setFilters, equipmentType}) {
  const { data, loading } = useQuery(FilterQuery, {
    variables: {
      equipmentType,
    },
    fetchPolicy: 'network-only',
  });

  
  const filterOptions = React.useMemo(() => {
    let availableFilters = data && data.equipmentFilters ? JSON.parse(data.equipmentFilters) : {};

    if (equipmentType === 'fender' || equipmentType === 'hose' || equipmentType === 'hose_bun') {
      availableFilters = {
        ...availableFilters,
        expiresIn: ['1 month', 'expired'], //TODO: Have these come from the backend
      }
    }

    return Object.keys(availableFilters).map((key, index) => {
      return {
        id: index,
        key: _.camelCase(key),
        options: availableFilters[key].filter(o => o !== ''),
      }
    });
  }, [data, equipmentType])

  const [newFilterKey, setNewFilterKey] = React.useState('');
  const [newFilterVal, setNewFilterVal] = React.useState('');

  const [selectedVal, setSelectedVal] = React.useState(null)

  function addFilter() {
    setFilters([...filters, {key: newFilterKey, val: newFilterVal}])
    setNewFilterKey('')
    setNewFilterVal('')
  }

  function removeFilter(key) {
    const newfilters = filters.filter(f => f.key !== key);
    setFilters(newfilters);
  }

  function keyDisabled(key) {
    return filters.filter(f => f.key === key).length > 0;
  }

  function selectableOptions() {
    if (!newFilterKey.length) return [];

    return filterOptions.filter(f => f.key === newFilterKey)[0].options;
  }

  if (loading) return <CircularProgress size={32} style={{ margin: '0 auto', display: 'block' }} />

  const enabled = (newFilterVal === 0 || !!newFilterVal) && !!newFilterKey;

  return (
    <Wrapper>
      <FormControl variant="outlined" className="input">
        <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
        <Select
          labelId="reducersFilterKeyLabel"
          id="reducersFilterKey"
          value={newFilterKey}
          onChange={e => setNewFilterKey(e.target.value)}
          label="Filter"
          style={{ minWidth: '100px', marginRight: '16px'}}
        >
          {
            filterOptions.map(option => (
              <MenuItem key={option.id} value={option.key} style={{textTransform: 'capitalized'}} disabled={keyDisabled(option.key)}>{option.key}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Autocomplete
        className="input"
        value={selectedVal}
        onChange={(event, newValue) => setSelectedVal(newValue)}
        onBlur={e => setNewFilterVal(e.target.value)}
        disabled={!newFilterKey}
        inputValue={newFilterVal}
        onInputChange={(event, newInputValue) => setNewFilterVal(newInputValue)}
        id="options"
        options={selectableOptions()}
        autoHighlight
        getOptionLabel={(option) => option ? typeof option !== 'string' ? JSON.stringify(option) : option : ''}
        getOptionSelected={(option, value) => option === value}
        noOptionsText="No results"
        style={{marginRight: '16px'}}
        renderOption={(option) => (
          <React.Fragment>
            <span>{option}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Equals"
            style={{ minWidth: '200px'}}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Button variant="contained" color="primary" onClick={addFilter} disabled={!enabled} style={{ marginRight: '16px'}}>
        ADD FILTER
      </Button>
      {
        filters.map(filter => (
          <Chip
            key={`${filter.key}: ${filter.val}`}
            label={`${filter.key}: ${filter.val}`}
            onDelete={() => removeFilter(filter.key)}
            style={{ margin: '16px 16px 16px 0'}}
          />
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (${props => props.theme.breakpoints.mobile}) {
    & .input {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;

export default Filters;
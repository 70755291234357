import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import File from 'components/common/fileRow';
import FileUpload from 'components/common/fileUpload';
import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';
import moment from 'moment';

const GetHoseBunDocumentsQuery = gql`
  query GetHoseBun($id: ID!) {
    node(id: $id) {
      ... on HoseBun {
        id
        attachedDocuments {
          id
          filename
          created
          documentUrl
        }
      }
    }
  }
`;

const ArchiveDocumentMutation = gql`
  mutation ArchiveDocument($input: ArchiveDocumentInput!) {
    archiveDocument(archiveDocumentData: $input) {
      success
    }
  }
`;

const UploadDocumentMutation = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

function Documents({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const { data, loading, refetch } = useQuery(GetHoseBunDocumentsQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeArchiveDocument] = useMutation(ArchiveDocumentMutation, {
    onCompleted(result) {
      refetch();
    },
  });

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocumentMutation, {
    onCompleted() {
      refetch();
    }
  });

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;

  function removeFile(id) {
    executeArchiveDocument({
      variables: {
        input: {
          documentId: id,
        }
      }
    })
  }

  function addFile(file) {
    executeUploadDocument({
      variables: {
        input: {
          file,
          filename: file.name,
          hoseBunId: id,
        }
      }
    })
  }

  if (!data.node) return null;
  const documents = data && data.node ? data.node.attachedDocuments : [];

  return (
    <Block
      title={
        <BlockTitle>
          DOCUMENTS
          {
              isAdmin && (
                <EditProject
                  editing={editing}
                  setEditing={setEditing}
                  noSave
                  cancel={() => setEditing(false)}
                  cancelLabel="Finish"
                />
              )
            }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Inner>
        {
          editing && (
            <UploadWrapper>
              <FileUpload
                id="newFile"
                allowedTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv', 'application/vnd.ms-excel']}
                upload={addFile}
              >
                <Add htmlFor="newFile">
                  ADD FILE <DocumentIcon />
                  <input
                    type="file"
                    required
                    id="newFile"
                    onChange={addFile}
                    style={{ display: 'none' }}
                  />
                </Add>
              </FileUpload>
            </UploadWrapper>
          )
        }
        <Files>
          <FileHead>
            <FilesLabel>Attached files</FilesLabel>
            <FilesLabel>Date uploaded</FilesLabel>
          </FileHead>
          {
            documents.length
              ? documents.map(f => <File key={f.id} file={f} uploadDate={moment(f.created).format('DD/MM/YYYY')} showDelete={editing} removeFile={removeFile} />)
              : <File noResults />
          }
          { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
        </Files>
      </Inner>
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Inner = styled.div`
  padding: 0 12px;
`;

const FileHead = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Add = styled.label`
  color: ${props => props.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  padding: 8px;
  border-radius: 4px;

  & svg {
    margin-left: 8px;
    opacity: .5
  }
`;

const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: black;
  font-weight: 500
`;

export default Documents;

import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';

import Pagination from 'components/common/pagination';
import Tag from 'components/common/tag';
import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

function ReducerTable({ reducers, paginationVariables, setPaginationVariables, resultCount, pageInfo, sortBy, setSortBy }) {
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }
  return (
    <Wrapper>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('plNumber')}>
                PL Nr. <StyledOrderIcon active={sortBy.type === 'plNumber' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('serialNumber')}>
                Serial Nr. <StyledOrderIcon active={sortBy.type === 'serialNumber' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('material')}>
                Material <StyledOrderIcon active={sortBy.type === 'material' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('length')}>
                Length <StyledOrderIcon active={sortBy.type === 'length' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('location')}>
                Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('aConnection')}>
                Connection A <StyledOrderIcon active={sortBy.type === 'aConnection' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('aFlange')}>
                Flange A <StyledOrderIcon active={sortBy.type === 'aFlange' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('bConnection')}>
                Connection B <StyledOrderIcon active={sortBy.type === 'bConnection' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('bFlange')}>
                Flange B <StyledOrderIcon active={sortBy.type === 'bFlange' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('status')}>
                Status <StyledOrderIcon active={sortBy.type === 'status' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell>
                Last products
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reducers.length
              ? reducers.map((reducer) => (
                  <TableRow key={reducer.id}>
                    <TableCell>
                      <Link to={`/dashboard/equipment/reducers/${reducer.id}`}>{reducer.plNumber}</Link>
                    </TableCell>
                    <TableCell>
                      {reducer.serialNumber}
                    </TableCell>
                    <TableCell>
                      {reducer.material}
                    </TableCell>
                    <TableCell>
                      {reducer.length}
                    </TableCell>
                    <TableCell>
                      {reducer.location}
                    </TableCell>
                    <TableCell>
                      {reducer.aConnection}
                    </TableCell>
                    <TableCell>
                      {reducer.aFlange}
                    </TableCell>
                    <TableCell>
                      {reducer.bConnection}
                    </TableCell>
                    <TableCell>
                      {reducer.bFlange}
                    </TableCell>
                    <TableCell>
                      {reducer.status && <Tag color={tagColor(reducer.status)} label={equipmentStatusLabel(reducer.status)} />}
                    </TableCell>
                    <TableCell>
                      {reducer.lastProducts}
                    </TableCell>
                  </TableRow>
                ))
              : <TableRow>
                  <TableCell>No results</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  
`;

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default ReducerTable;
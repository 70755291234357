import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import UseWindowWidth from 'helpers/useWindowWidth';
import NotFound from 'components/common/404';

import Nav from './nav';
import General from './overview/general';
import Vessels from './overview/vessels';
import Personnel from './overview/personnel';
import Clients from './overview/clients';
import Documents from './overview/documents';
import Invoicing from './overview/invoicing';
import Timeline from './timeline';
import ReportModal from './reportModal';
// import CsvModal from './csvModal';
import Equipment from './equipment';

const StsProjectsQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        users {
          edges {
            node {
              id
              user {
                id
              }
              role
            }
          }
        }
      }
    }
  }
`;

const StsTimestampsQuery = gql`
  query StsTimestamps ($sortBy: [String], $stsprojectId: ID){
    ststimestamps(sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          localDatetime
          utcDatetime
          location
          description
          isReported
          attachedDocuments {
            id
            filename
            extension
          }
        }
      }
    }
  }
`;

function Sts() {
  const history = useHistory();
  const id = history.location.pathname.split('/sts/')[1];
  const [view, setView] = React.useState('general');
  const loggedInUser = useSelector(state => state.auth.user);
  const [allowEdit, setAllowEdit] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const userRoles = loggedInUser.roles;
  const isAdmin = userRoles.includes('admin')
  const windowWidth = UseWindowWidth();

  const { data: timestampData, loading: timestampLoading, refetch: timestampRefetch, error: timestampError } = useQuery(StsTimestampsQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['localDatetime desc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const {loading} = useQuery(StsProjectsQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const project = result.node;
      if (!project) return;
      
      const users = project.users.edges.map(u => u.node);
      
      users.forEach((user) => {
        if (loggedInUser.id === user.user.id && user.role === 'mooringmaster') {
          setAllowEdit(true);
        }
      })
    },
    skip: !id || userRoles.includes('admin'),
    onError(e) {
      console.log(e);
    }
  });

  React.useEffect(() => {
    if (userRoles.includes('admin')) {
      setAllowEdit(true)
    }
  }, [loggedInUser, userRoles]);

  if (loading) return null;
  if (timestampError) return <NotFound />

  const timestamps = timestampData ? timestampData.ststimestamps.edges.map(t => t.node) : [];
  const hasTimestamps = timestamps.length > 0;

  return (
    <Wrapper>
      <Nav view={view} setView={setView} hasTimestamps={hasTimestamps} setShowReportModal={setShowReportModal} timestamps={timestamps} />
      {
        view === 'general'
          ? <ProjectWrapper>
              <ProjectBlocks>
                <General id={id} allowEdit={isAdmin} />
                <Vessels id={id} allowEdit={isAdmin} />
                <Personnel id={id} allowEdit={isAdmin} />
                <Clients id={id} allowEdit={isAdmin} />
                <Documents id={id} allowEdit={allowEdit} />
                { isAdmin && <Invoicing id={id} allowEdit timestamps={timestamps} /> }
              </ProjectBlocks>
              { windowWidth > 1504 &&  <StyledTimeline id={id} allowEdit={false} preview timestamps={timestamps} loading={timestampLoading} refetch={timestampRefetch} />}
            </ProjectWrapper>
          : view === 'timeline'
            ? <Timeline id={id} allowEdit={allowEdit} timestamps={timestamps} loading={timestampLoading} refetch={timestampRefetch} />
            : <Equipment id={id} allowEdit={isAdmin} />
      }
      { showReportModal && <ReportModal id={id} setShowReportModal={setShowReportModal} timestampRefetch={timestampRefetch} timestamps={timestamps} /> }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (${props => props.theme.breakpoints.tablet}) {
    padding-bottom: 32px;
  }
`;

const ProjectWrapper = styled.div`
  display: flex;

`;

const ProjectBlocks = styled.div`
  width: 1200px;
  max-width: 100%;
`;

const StyledTimeline = styled(Timeline)`
  margin-left: 16px;
`;

export default Sts;

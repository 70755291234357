import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';

import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';

const UpdateStsClientMutation = gql`
  mutation UpdateStsClient($input: UpdateStsClientInput!) {
    updateStsClient(updateStsClientData: $input) {
      stsclient {
        id
        email
      }
    }
  }
`;

const ArchiveStsClientMutation = gql`
  mutation ArchiveStsClient($input: ArchiveStsClientInput!) {
    archiveStsClient(archiveStsClientData: $input) {
      success
    }
  }
`;

const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function EditableStsClient({ stsclient, refetch }) {
  const clientRef = React.useRef(null);
  const [email, setEmail] = React.useState(stsclient.email || '');
  const [emailError, setEmailError] = React.useState(false);

  const [executeUpdateStsClient, { loading: updateLoading }] = useMutation(UpdateStsClientMutation, {
    variables: {
      input: {
        stsclientId: stsclient.id,
        email,
      },
    },
  });

  const [executeArchiveStsClient, { loading: archiveLoading }] = useMutation(ArchiveStsClientMutation, {
    variables: {
      input: {
        stsclientId: stsclient.id,
      },
    },
    onCompleted() {
      refetch();
    }
  });

  function hasMadeChanges() {
    const currentData = {
      stsclientId: stsclient.id,
      email,
    };

    const prevData = {
      stsclientId: stsclient.id,
      email: stsclient.email || '',
    };

    return !_.isEqual(currentData, prevData);
  }

  const handleClickOutside = (event) => {
    if (clientRef.current && clientRef.current.contains(event.target)) {
      return;
    }

    if (archiveLoading || updateLoading) {
      return;
    }

    if (emailError) {
      return;
    }
    
    if (hasMadeChanges()) {
      executeUpdateStsClient();
    }
  };

  function emailInputHandler(val) {
    if (val.match(mailRegex)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setEmail(val)
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <Wrapper ref={clientRef}>
      <TextField
        id={`user-${stsclient.id}-email`}
        label="Email"
        variant="outlined"
        onChange={e => emailInputHandler(e.target.value)}
        value={email}
        type="email"
        autoComplete={stsclient.id}
        fullWidth
        error={emailError}
      />
      <IconButton
        onClick={executeArchiveStsClient}
        disabled={updateLoading || archiveLoading}
        aria-label="delete client"
      >
        <TrashIcon />
      </IconButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  width: 367px;
  max-width: 100%;

  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export default EditableStsClient;

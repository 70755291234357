import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as PlusIcon } from 'assets/vectors/add-white-16px.svg';
import ActionBar from 'components/common/actionBar';
import Block from 'components/common/block';
import useDebounce from 'helpers/useDebounce';

import StsTable from './stsTable';
import Filters from './filters';

const StsProjectsQuery = gql`
  query StsProjects ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $search: String, $dateStatus: String){
    stsprojects(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, search: $search, dateStatus: $dateStatus) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          projectId
          startDate
          endDate
          dateStatus
          reference
          location
          created
          stsvessels {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

function OverviewSTS() {
  const history = useHistory();
  const urlParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  function resultCountDefaultData() {
    if (urlParams.first || urlParams.last) {
      return urlParams.first || urlParams.last;
    } else {
      return 10;
    }
  }
  
  function searchDefaultData() {
    if (urlParams.search) {
      return urlParams.search;
    } else {
      return '';
    }
  }

  function paginationDefaultData() {
    if (urlParams.after) {
      return { first: resultCount, after: urlParams.after };
    } else if (urlParams.before) {
      return { last: resultCount, before: urlParams.before };
    } else {
      return { first: resultCount };
    }
  }

  function statusDefaultData() {
    if (urlParams.status) {
      return urlParams.status;
    } else {
      return 'all';
    }
  }

  function sortByDefaultData() {
    if (urlParams.sortBy) {
      return { type: urlParams.sortBy.split('.')[0], direction: urlParams.sortBy.split('.')[1]};
    } else {
      return { type: 'created', direction: 'desc' };
    }
  }

  const [resultCount, setResultCount] = React.useState(resultCountDefaultData());
  const [paginationVariables, setPaginationVariables] = React.useState(paginationDefaultData());
  const [status, setStatus] = React.useState(statusDefaultData());
  const [search, setSearch] = React.useState(searchDefaultData());
  const [sortBy, setSortBy] = React.useState(sortByDefaultData());
  const debouncedSearch = useDebounce(search, 300);

  React.useEffect(() => {
    if ((paginationVariables.before || paginationVariables.after) && debouncedSearch.length && debouncedSearch !== urlParams.search) {
      setPaginationVariables({
        first: resultCount,
      })
    }
  }, [debouncedSearch, paginationVariables, resultCount, urlParams]);

  React.useEffect(() => {
    const params = {
      search: debouncedSearch.length ? debouncedSearch : undefined,
      after: paginationVariables.after || undefined,
      first: paginationVariables.first || undefined,
      last: paginationVariables.last || undefined,
      before: paginationVariables.before || undefined,
      status: status || undefined,
      sortBy: `${sortBy.type}.${sortBy.direction}`,
    }

    // history.push(`/dashboard/sts?${qs.stringify(params)}`);
    window.history.replaceState(null, "", `/dashboard/sts?${qs.stringify(params)}`);
  }, [debouncedSearch, paginationVariables, history, resultCount, status, sortBy]);

  const { data, loading } = useQuery(StsProjectsQuery, {
    variables: {
      search: debouncedSearch.length ? debouncedSearch : null,
      sortBy: [`${sortBy.type} ${sortBy.direction}`],
      dateStatus: status === 'all' ? null : status,
      ...paginationVariables,
    },
    fetchPolicy: 'network-only',
  });

  function statusHandler(status) {
    setStatus(status);
    if (paginationVariables.before || paginationVariables.after) {
      setPaginationVariables({
        first: resultCount,
      })
    }
  }

  const stsprojects = data ? data.stsprojects.edges.map(u => u.node) : [];
  const pageInfo = data ? data.stsprojects.pageInfo : {};

  return (
    <>
      <StyledActionBar>
        <Group>
          <GroupLabel>Status</GroupLabel>
          <FormControl variant="outlined" style={{ margin: '0 8px'}}>
            <Select
              id="status"
              value={status}
              onChange={e => statusHandler(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="planned">Planned</MenuItem>
              <MenuItem value="ongoing">Ongoing</MenuItem>
              <MenuItem value="finished">Finished</MenuItem>
            </Select>
          </FormControl>
        </Group>
        {
          isAdmin && (
            <StyledLink to="/dashboard/sts/new">
              <Button
                variant="contained"
                color="primary"
                endIcon={<PlusIcon />}
              >
                Add project 
              </Button>
            </StyledLink>
          )
        }
      </StyledActionBar>
      <Block noTitle>
        <Filters
          resultCount={resultCount}
          setResultCount={setResultCount}
          search={search}
          setSearch={setSearch}
          setPaginationVariables={setPaginationVariables}
        />
        {
          loading
            ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
            : <StsTable
                stsprojects={stsprojects}
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
        }
      </Block>
    </>
  );
}

const StyledActionBar = styled(ActionBar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: block;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const GroupLabel = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 18px;
`;

export default OverviewSTS;

import React from 'react';
import styled from 'styled-components';
import Block from 'components/common/block';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import EditProject from 'components/common/editProject';
import EditableClient from 'components/common/editableStsClient';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';

const StsClientQuery = gql`
  query StsClient ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsclients(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

const CreateStsClientMutation = gql`
  mutation CreateStsClient($input: CreateStsClientInput!) {
    createStsClient(createStsClientData: $input) {
      stsclient {
        id
        email
      }
    }
  }
`;

function ClientsTable({ clients }) {
  if (!clients.length) return <NoResult>No clients were added</NoResult>;
  return (
    <TableContainer>
      <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            clients.map((stsClient, index) => (
              <TableRow key={stsClient.id}>
                <TableCell style={{ borderBottom: index === clients.length - 1 && 0 }}>
                  {
                    stsClient.email
                      ? <Mail href={`mailto:${stsClient.email}`}>{stsClient.email}</Mail>
                      : <p>-</p>
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EditClients({ clients, refetch, createClientLoading, executeCreateClient }) {
  return (
    <>
      { clients.map(stsclient => <EditableClient stsclient={stsclient} key={stsclient.id} refetch={refetch} />)}
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          disabled={createClientLoading}
          onClick={executeCreateClient}
          endIcon={<AddIcon />}
        >
         Add client
        </Button>
      </ButtonWrapper>
    </>
  );
}

function Clients({ id, allowEdit }) {
  const [editing, setEditing] = React.useState(false);
  const { data, loading, error, refetch } = useQuery(StsClientQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeCreateClient, { loading: createClientLoading }] = useMutation(CreateStsClientMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      refetch()
    },
  });

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error) return <p>Could not load clients</p>;

  function cancel() {
    setEditing(false);
  }

  const clients = data ? data.stsclients.edges.map(u => u.node) : [];

  return (
    <Block
      title={
        <BlockTitle>
          CLIENTS
          {
            allowEdit && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                cancel={cancel}
                noSave
                cancelLabel="Finish"
              /> 
            )
          } 
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}>
      {
        editing
          ? <EditClients clients={clients} refetch={refetch} createClientLoading={createClientLoading} executeCreateClient={executeCreateClient} />
          : <ClientsTable clients={clients} />
      }
    </Block>
  );
}

const Mail = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
  display: block;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

const NoResult = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  padding: 0 12px;
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Clients;

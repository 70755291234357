import React from 'react';
import styled from 'styled-components'

function ActionBar({ style, className, children }) {
  return (
    <Wrapper style={style} className={className}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  border: 0.5px solid ${props => props.theme.colors.borderColor};
  margin-bottom: 24px;
`;

export default ActionBar;
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { ReactComponent as MoreIcon } from 'assets/vectors/more-menu-16px.svg';

function UserOptions({ editing, setEditing, save, cancel, archivePrompt, isAdmin, isSelf, sendActivationMail, notOnboarded }) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function toggleEdit(e) {
    handleClose(e)
    setEditing(!editing)
  }

  function saveHandler(e) {
    handleClose(e)
    save();
  }

  function cancelHandler(e) {
    handleClose(e);
    cancel();
  }

  function archiveHandler(e) {
    handleClose(e);
    archivePrompt();
  }

  function activateHandler(e) {
    handleClose(e);
    sendActivationMail();
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <StyledMoreIcon open={open} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  { !editing && <MenuItem onClick={toggleEdit}>Edit</MenuItem> }
                  { editing && <MenuItem onClick={saveHandler}>Save</MenuItem> }
                  { editing && <MenuItem onClick={cancelHandler}>Cancel</MenuItem>}
                  { !editing && isAdmin && !isSelf && <MenuItem onClick={archiveHandler}>Archive user</MenuItem>}
                  { isAdmin && !isSelf && notOnboarded && <MenuItem onClick={activateHandler}>Re-send activation mail</MenuItem>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const StyledMoreIcon = styled(MoreIcon)`
  transform: rotate(${props => props.open ? '90deg' : 0});
  transition: ease all .2s;
`;

export default UserOptions;
import React from 'react';
import styled, { withTheme } from 'styled-components';

function Tag({ label, color, theme, style }) {
  function getColor(c) {
    switch(c) {
      case 'green':
        return theme.colors.primaryColor;
      case 'blue':
        return theme.colors.blue;
      case 'red':
        return theme.colors.red;
      case 'yellow':
        return theme.colors.yellow;
      case 'orange':
        return theme.colors.orange;
      case 'black':
        return theme.colors.black;
      case 'grey':
        return theme.colors.borderColor;
      default:
        return c;
    }
  }
  return (
    <Wrapper style={style} color={getColor(color)} isEmpty={label === '-'}>{label}</Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${props => props.color};
  color: ${props => props.isEmpty ? props.theme.colors.textColor : 'white'};
  text-align: center;
  width: fit-content;
  text-transform: capitalize;
`;

export default withTheme(Tag);

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Logo } from 'assets/vectors/drop_logo.svg';

function AuthForm({ children, onSubmit }) {
  return (
    <StyledForm onSubmit={onSubmit}>
      {children}
      <StyledLogo />
    </StyledForm>
  );
}

const StyledForm = styled.form`
  margin: 128px auto;
  display: block;
  width: 360px;
  max-width: 100%;
  position: relative;

  & > div {
    z-index: 1
  }
`;

const StyledLogo = styled(Logo)`
  height: auto;
  width: 800px;
  opacity: .7;
  position: fixed;
  right: -400px;
  top: 25%;
`;

export default AuthForm;
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

function ToggleInput({ editing, label, value, style, noLabel, className, ...props }) {
  if (editing) {
    return (
      <TextField
        label={label}
        value={value}
        style={style}
        className={className}
        {...props}
      />
    )
  }
  return (
    <DataWrapper style={style} className={className}>
      {!noLabel && <Label>{label}</Label>}
      <Value>{value || '-'}</Value>
    </DataWrapper>
  );
}

const DataWrapper = styled.div`
  padding: 0 12px;
  width: 100%;
`;

const Label = styled.p`
  color: black;
  font-size: 11px;
  margin-bottom: 8px;
  font-weight: 500
`;

const Value = styled.p`
  color: rgba(0, 0, 0, 0.87);
  white-space: pre-wrap;
`;

export default ToggleInput;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';

import Block from 'components/common/block';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';

import EditableUser from 'components/common/editableStsProjectUser';
import EditableClient from 'components/common/editableStsClient';

const StsProjectUsersQuery = gql`
  query StsProjectUsers ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsprojectUsers(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          role
          user {
            id
            email
            phone
            fullName
          }
        }
      }
    }
  }
`;

const StsClientQuery = gql`
  query StsClient ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsclients(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

const CreateStsProjectUserMutation = gql`
  mutation CreateStsProjectUser($input: CreateStsProjectUserInput!) {
    createStsProjectUser(createStsProjectUserData: $input) {
      stsprojectUser {
        id
        role
        user {
          id
          email
          phone
        }
      }
    }
  }
`;

const CreateStsClientMutation = gql`
  mutation CreateStsClient($input: CreateStsClientInput!) {
    createStsClient(createStsClientData: $input) {
      stsclient {
        id
        email
      }
    }
  }
`;

function Users({ id, projectState, setProjectState }) {
  const { data: StsProjectUsersQueryData, refetch: StsProjectUsersQueryRefetch } = useQuery(StsProjectUsersQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (!projectState.usersComplete && result.stsprojectUsers.edges.length) {
        setProjectState({ ...projectState, usersComplete: true });
      }
    }
  });

  const { data: StsClientQueryData, refetch: StsClientQueryRefetch } = useQuery(StsClientQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (!projectState.usersComplete && result.stsclients.edges.length) {
        setProjectState({ ...projectState, usersComplete: true });
      }
    }
  });

  const [executeCreateUser, { loading: createUserLoading }] = useMutation(CreateStsProjectUserMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      StsProjectUsersQueryRefetch()
    },
  });

  const [executeCreateClient, { loading: createClientLoading }] = useMutation(CreateStsClientMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      StsClientQueryRefetch()
    },
  });

  const personnel = StsProjectUsersQueryData && StsProjectUsersQueryData.stsprojectUsers ? StsProjectUsersQueryData.stsprojectUsers.edges.map(v => v.node) : [];
  const clients = StsClientQueryData && StsClientQueryData.stsclients ? StsClientQueryData.stsclients.edges.map(v => v.node) : [];

  return (
    <>
      <StyledBlock title="ADD PERSONNEL">
        { personnel.map((stsprojectUser, index) => <EditableUser isLast={index === personnel.length - 1} stsprojectUser={stsprojectUser} key={stsprojectUser.id} refetch={StsProjectUsersQueryRefetch} />)}
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            disabled={createUserLoading}
            onClick={executeCreateUser}
            endIcon={<AddIcon />}
          >
           Add user
          </Button>
        </ButtonWrapper>
      </StyledBlock>
      <BottomBlock title="ADD CLIENTS" style={{ marginTop: '16px' }}>
        { clients.map(stsclient => <EditableClient stsclient={stsclient} key={stsclient.id} refetch={StsClientQueryRefetch} />)}
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            disabled={createClientLoading}
            onClick={executeCreateClient}
            endIcon={<AddIcon />}
          >
           Add client
          </Button>
        </ButtonWrapper>
      </BottomBlock>
    </>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
`;

const BottomBlock = styled(StyledBlock)`
  @media (${props => props.theme.breakpoints.tablet}) {
    margin-bottom: 68px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Users;

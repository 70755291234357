export default function useEquipmentStatus() {
  function tagColor(status) {
    switch(status) {
      case 'stand_by':
        return 'green';
      case 'deployed':
        return 'blue';
      case 'testing':
        return 'orange';
      case 'cleaning':
        return 'yellow';
      case 'damaged':
        return 'red';
      case 'in_repair':
        return 'black';
      case 'scrapped':
        return 'grey';
      default:
        return 'transparent'
    }
  }

  function statusValues() {
    return [
      {value: 'stand_by', label: 'Stand by'},
      {value: 'deployed', label: 'Deployed'},
      {value: 'testing', label: 'Testing'},
      {value: 'cleaning', label: 'Cleaning'},
      {value: 'damaged', label: 'Damaged'},
      {value: 'in_repair', label: 'In repair'},
      {value: 'scrapped', label: 'Scrapped'},
      {value: '', label: '-'},
    ]
  }

  function equipmentStatusLabel(status) {
    const res = statusValues().filter(s => s.value === status);

    return res.length ? res[0].label : '';
  }

  return {
    tagColor,
    statusValues,
    equipmentStatusLabel
  }
}
import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import AuthForm from 'components/common/authForm';
import Block from 'components/common/block';

const FORGOT_MUTATION = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordData: $input) {
      success
    }
  }
`;

function Forgot() {
  const [email, setEmail] = React.useState('');
  const [forgotSnackbarOpen, setForgotSnackbarOpen] = React.useState(false);

  const [executeForgot, { loading, data }] = useMutation(FORGOT_MUTATION, {
    variables: {
      input: {
        email,
      },
    },
    onCompleted(result) {
      setForgotSnackbarOpen(true);
    },
    onError(e) {
      
    }
  });

  function submitHandler(e) {
    e.preventDefault();
    executeForgot();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setForgotSnackbarOpen(false);
  };

  const isDisabled = loading || !email.length || !!data;

  return (
    <AuthForm onSubmit={submitHandler}>
      <Block title="FORGOT PASSWORD">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          onChange={e => setEmail(e.target.value)}
          value={email}
          type="email"
          autoComplete="email"
          fullWidth
          style={{ marginBottom: '16px' }}
        />
        <Link to="/">Back to login</Link>
        <ButtonWrapper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Send
          </Button>
        </ButtonWrapper>
      </Block>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={forgotSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="An e-mail has been sent to reset your password."
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </AuthForm>
  );
}

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
  margin-bottom: 32px;
  display: block;
  outline: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`; 

export default Forgot;

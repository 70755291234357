import React from 'react';
import styled from 'styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as ChevronDown } from 'assets/vectors/dropdown-16px.svg';

function SectionItem({ itemIcon, itemPath, itemTitle, itemOptions }) {
  let { path } = useRouteMatch();
  const active = window.location.pathname.includes(`${path}${itemPath}`);
  const anchorRef = React.useRef();
  const [open, setOpen] = React.useState(active);
  const [itemOptionsHeight, setItemOptionsHeight] = React.useState(undefined);

  React.useEffect(() => {
    setItemOptionsHeight(anchorRef && anchorRef.current ? anchorRef.current.offsetHeight : undefined);
  }, [anchorRef]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <Wrapper>
      <ItemHeading role="button" onClick={handleToggle} active={active}>
        {itemIcon}
        <ItemTitle>{itemTitle}</ItemTitle>
        <StyledChevronDown open={open} />
      </ItemHeading>
      <ItemOptions ref={anchorRef} itemOptionsHeight={itemOptionsHeight} open={open}>
        {
          itemOptions.map(option => (
            <Option exact key={option.id} to={`${path}${option.path}`}>
              <MenuItem>{option.label}</MenuItem>
            </Option>
          ))
        }
      </ItemOptions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 8px;
`;

const ItemHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background: ${props => props.active && '#ECECEC'};
  border-radius: 4px;

  &:hover {
    background: #ECECEC;
  }
`;

const ItemTitle = styled.p`
  color: ${props => props.theme.colors.textColor};
  margin-left: 8px;
  font-weight: 500;
`;

const StyledChevronDown = styled(ChevronDown)`
  transform: rotate(${props => props.open ? '180deg' : 0});
  transition: all ease .2s;
  margin-left: auto;
  flex: none;
`;

const ItemOptions = styled.div`
  overflow: hidden;
  height: ${props => props.itemOptionsHeight && !props.open ? 0 : `${props.itemOptionsHeight}px`};
  transition: all ease .2s;
`;

const Option = styled(NavLink)`
  display: block;
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  text-decoration: none;

  li {
    padding-left: 40px;
  }

  &.active {
    li {
      font-weight: 500;
    }
  }
`;

export default SectionItem;

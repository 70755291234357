import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

function Filters({ resultCount, setResultCount, search, setSearch, setPaginationVariables }) {
  function searchHandler(val)  {
    setSearch(val);
    if (!val) {
      setPaginationVariables({
        first: resultCount,
      });
    }
  }

  function resultCountHandler(val) {
    setPaginationVariables({
      first: val,
    })
    setResultCount(val);
  }

  return (
    <Wrapper>
      <Group>
        <GroupLabel>Show</GroupLabel>
        <FormControl variant="outlined" style={{ margin: '0 8px'}}>
          <Select
            id="resultCount"
            value={resultCount}
            onChange={e => resultCountHandler(e.target.value)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <GroupLabel>entries</GroupLabel>
      </Group>
      <Group>
        <TextField
          id="project-search"
          label="Search projects"
          variant="outlined"
          onChange={e => searchHandler(e.target.value)}
          value={search}
          type="text"
          fullWidth
        />
      </Group>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid ${props => props.theme.colors.borderColor};
  padding-bottom: 24px;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;

  @media (${props => props.theme.breakpoints.tablet}) {
    margin-top: 8px;
    width: 100%;
  }
`;

const GroupLabel = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 18px;
`;


export default Filters;

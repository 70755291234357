import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetHoseBunQuery = gql`
  query GetHoseBun($id: ID!) {
    node(id: $id) {
      ... on HoseBun {
        id
        plNumber
        diameter
        serialNumber
        location
        type
        status
        expireDate
        testDate
        manufactureDate
        remark
      }
    }
  }
`;

const UpdateHoseBunMutation = gql`
  mutation UpdateHoseBun($input: UpdateHoseBunInput!) {
    updateHoseBun(updateHoseBunData: $input) {
      hoseBun {
        id
      }
    }
  }
`;

const ArchiveHoseBunMutation = gql`
  mutation ArchiveHoseBun($input: ArchiveHoseBunInput!) {
    archiveHoseBun(archiveHoseBunData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [plNumber, setPlNumber] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [diameter, setDiameter] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [type, setType] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [expireDate, setExpireDate] = React.useState(null);
  const [testDate, setTestDate] = React.useState(null);
  const [manufactureDate, setManufactureDate] = React.useState(null);
  const [remark, setRemark] = React.useState('');
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);

  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetHoseBunQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const hoseBun = result.node;
      if (!hoseBun) return;

      if (hoseBun.plNumber) setPlNumber(hoseBun.plNumber);
      if (hoseBun.serialNumber) setSerialNumber(hoseBun.serialNumber);
      if (hoseBun.diameter) setDiameter(hoseBun.diameter);
      if (hoseBun.type) setType(hoseBun.type);
      if (hoseBun.location) setLocation(hoseBun.location);
      if (hoseBun.status) setStatus(hoseBun.status);
      if (hoseBun.expireDate) setExpireDate(hoseBun.expireDate);
      if (hoseBun.testDate) setTestDate(hoseBun.testDate);
      if (hoseBun.manufactureDate) setManufactureDate(hoseBun.manufactureDate);
      if (hoseBun.remark) setRemark(hoseBun.remark);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateHoseBunMutation, {
    variables: {
      input: {
        hoseBunId: id,
        plNumber,
        serialNumber,
        diameter: diameter || 0,
        type,
        location,
        status,
        expireDate: expireDate ? moment(expireDate).format('YYYY-MM-DD') : null,
        testDate: testDate ? moment(testDate).format('YYYY-MM-DD') : null,
        manufactureDate: manufactureDate ? moment(manufactureDate).format('YYYY-MM-DD') : null,
        remark,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveHoseBunMutation, {
    variables: {
      input: {
        hoseBunId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/hoseBuns');
    },
    onError: (e) => {
      console.log(e)
    }
  });


  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateHoseBun.hoseBun : data.node;

    setPlNumber(lastSaved.plNumber)
    setSerialNumber(lastSaved.serialNumber)
    setDiameter(lastSaved.diameter)
    setType(lastSaved.type)
    setLocation(lastSaved.location)
    setStatus(lastSaved.status)
    setExpireDate(lastSaved.expireDate)
    setTestDate(lastSaved.testDate)
    setManufactureDate(lastSaved.manufactureDate)
    setRemark(lastSaved.remark)
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'diameter', label: 'Diameter in inches', onChange: setDiameter, value: diameter, type: 'number'}, 
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'type', label: 'Type', onChange: setType, value: type, type: 'text'}, 
    {id: 'testDate', label: 'Test date', onChange: setTestDate, value: testDate, type: 'date'}, 
    {id: 'expireDate', label: 'Expire date', onChange: setExpireDate, value: expireDate, type: 'date', withExpirationHighlight: true}, 
    {id: 'manufactureDate', label: 'Manufacture date', onChange: setManufactureDate, value: manufactureDate, type: 'date'}, 
    {id: 'remark', label: 'Remark', onChange: setRemark, value: remark, type: 'text'},
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this hose bun?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

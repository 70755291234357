import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import useDebounce from 'helpers/useDebounce';
import Avatar from 'components/common/avatar';
import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';

const UsersQuery = gql`
  query Users ($first: Int, $sortBy: [String], $search: String){
    users(first: $first, sortBy: $sortBy, search: $search) {
      edges {
        node {
          id
          fullName
          email
          imageUrl
        }
      }
    }
  }
`;

const UpdateStsProjectUserMutation = gql`
  mutation UpdateStsProjectUser($input: UpdateStsProjectUserInput!) {
    updateStsProjectUser(updateStsProjectUserData: $input) {
      stsprojectUser {
        id
        role
        user {
          id
          email
          phone
        }
      }
    }
  }
`;

const ArchiveStsProjectUserMutation = gql`
  mutation ArchiveStsProjectUser($input: ArchiveStsProjectUserInput!) {
    archiveStsProjectUser(archiveStsProjectUserData: $input) {
      success
    }
  }
`;

function EditableStsProjectUser({ stsprojectUser, refetch, isLast }) {
  const userRef = React.useRef(null);
  const [user, setUser] = React.useState(stsprojectUser.user || {});
  const [role, setRole] = React.useState(stsprojectUser.role || '');
  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search, 300);

  const { data: usersData, loading: usersLoading } = useQuery(UsersQuery, {
    variables: {
      search: debouncedSearch.length ? debouncedSearch : null,
      sortBy: ['firstName asc'],
      first: 10,
    },
    skip: !debouncedSearch.length,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateStsUser, { loading: updateLoading }] = useMutation(UpdateStsProjectUserMutation, {
    variables: {
      input: {
        stsprojectUserId: stsprojectUser.id,
        userId: user ? user.id : null,
        role,
      },
    },
  });

  const [executeArchiveStsUser, { loading: archiveLoading }] = useMutation(ArchiveStsProjectUserMutation, {
    variables: {
      input: {
        stsprojectUserId: stsprojectUser.id,
      },
    },
    onCompleted() {
      refetch();
    }
  });

  function hasMadeChanges() {
    const currentData = {
      stsprojectUserId: stsprojectUser.id,
      userId: user ? user.id : '',
      role,
    };

    const prevData = {
      stsprojectUserId: stsprojectUser.id,
      userId: stsprojectUser.user ? stsprojectUser.user.id : undefined,
      role: stsprojectUser.role || '',
    };

    return !_.isEqual(currentData, prevData);
  }

  const handleClickOutside = (event) => {
    if (userRef.current && userRef.current.contains(event.target)) {
      return;
    }

    if (archiveLoading || updateLoading || usersLoading) {
      return;
    }
    
    if (hasMadeChanges()) {
      executeUpdateStsUser();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const users = usersData ? usersData.users.edges.map(u => u.node) : [];

  return (
    <Wrapper ref={userRef} isLast={isLast}>
      <Column>
        <Autocomplete
          value={user}
          onChange={(event, newValue) => setUser(newValue)}
          inputValue={search}
          onInputChange={(event, newInputValue) => setSearch(newInputValue)}
          id={`user-${stsprojectUser.id}`}
          fullWidth
          options={users}
          autoHighlight
          getOptionLabel={(option) => option.fullName || ''}
          getOptionSelected={(option, value) => option.id === value.id}
          noOptionsText="No results"
          renderOption={(option) => (
            <React.Fragment>
              <Avatar size={24} user={option} style={{ fontSize: '10px', marginRight: '8px' }} />
              <span>{option.fullName}</span>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="User"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Column>
      <Column>
        <FormControl variant="outlined" fullWidth className="selectForm">
          <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
          <Select
            labelId={`user-${stsprojectUser.id}-type-label`}
            id={`user-${stsprojectUser.id}-type`}
            value={role}
            onChange={e => setRole(e.target.value)}
            label="Role"
            style={{ width: '100%', marginRight: '8px' }}
          >
            <MenuItem value="mooringmaster">Mooring master</MenuItem>
            <MenuItem value="supervisor">Supervisor</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          onClick={executeArchiveStsUser}
          disabled={updateLoading || archiveLoading}
          aria-label="delete user"
          className="deleteButton"
        >
          <DeleteLabel>delete</DeleteLabel> <TrashIcon />
        </IconButton>
      </Column>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.isLast ? 'transparent' : props.theme.colors.borderColor};

  @media (${props => props.theme.breakpoints.laptop}) {
    & > :first-of-type {
      padding-right: 8px;
    }

    & > :last-of-type {
      padding-left: 8px;
    }
  }

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
    & > :first-of-type {
      padding-right: 0;
    }

    & > :last-of-type {
      padding-left: 0;
    }
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;
  margin-bottom: 32px;
  align-items: center;
  display: flex;
  position: relative;

  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;

    & .selectForm {
      margin-bottom: 16px;
    }

    & .deleteButton {
      position: absolute;
      bottom: -28px;
      right: 0;
    }
  }
`;

const DeleteLabel = styled.span`
  font-size: 11px;
  display: none;
  margin-right: 8px;

  @media (${props => props.theme.breakpoints.mobile}) {
    display: inline;
  }

  @media (${props => props.theme.breakpoints.tablet}) {
    display: inline;
  }
`;

export default EditableStsProjectUser;

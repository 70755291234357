import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Block from 'components/common/block';
import ToggleInput from 'components/common/toggleInput';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        location
        product
        quantity
      }
    }
  }
`;

function General({ id }) {
  const { data, loading } = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;

  return (
    <StyledBlock title="EQUIPMENT">
      <Split>
        <Column>
          <ToggleInput
            id="product"
            label="Product"
            variant="outlined"
            value={data.node.product}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
        <ToggleInput
            id="quantity"
            label="Quantity"
            variant="outlined"
            value={data.node.quantity}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
        <ToggleInput
            id="location"
            label="Location"
            variant="outlined"
            value={data.node.location}
            type="text"
            fullWidth
          />
        </Column>
      </Split>
    </StyledBlock>
  )
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
  margin-bottom: 16px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;

  @media (${props => props.theme.breakpoints.tablet}) {
    margin: 16px 0;
    padding: 16px 0;
    width: 100%;
  }
`;

export default General;

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import client from 'client';
import theme from 'theme';
import store from 'redux/store';

import TokenCheck from 'helpers/tokenCheck';
import PrivateRoute from 'helpers/privateRoute';

import Dashboard from 'components/dashboard';
import Login from 'components/auth/login';
import Forgot from 'components/auth/forgot';
import Logout from 'components/auth/logout';
import Reset from 'components/auth/reset';
import Onboarding from 'components/onboarding';
import NotFound from 'components/common/404';
import TopBar from 'components/topBar';

if (process.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    debug: process.env.REACT_APP_SENTRY_DEBUG,
    logLevel: process.env.REACT_APP_SENTRY_LOG_LEVELS,
    autoSessionTracking: false,
  });
}

const MUITheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.primaryColor,
      contrastText: "#fff"
    },
    secondary: {
      main: '#fff',
      contrastText: theme.colors.primaryColor,
    }
  },
  typography: {
    fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(',')
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <TokenCheck>
          <SCThemeProvider theme={theme}>
            <MUIThemeProvider theme={MUITheme}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <BrowserRouter>
                  <TopBar />
                  <Switch>
                    <PrivateRoute path='/dashboard' component={Dashboard} />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/forgot-password" component={Forgot} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/reset/:id" component={Reset} />
                    <Route exact path="/welcome/:id" component={Onboarding} />
                    <Route component={NotFound} />
                  </Switch>
                </BrowserRouter>
              </MuiPickersUtilsProvider>
            </MUIThemeProvider>
          </SCThemeProvider>
        </TokenCheck>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;

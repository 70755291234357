import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import ToggleInput from 'components/common/toggleInput';
import File from 'components/common/fileRow';
import FileUpload from 'components/common/fileUpload';
import { ReactComponent as DropdownIcon } from 'assets/vectors/dropdown-16px.svg';
import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';

import EntryOptions from './entryOptions';

const ArchiveStsTimestampMutation = gql`
  mutation ArchiveStsTimestamp($input: ArchiveStsTimestampInput!) {
    archiveStsTimestamp(archiveStsTimestampData: $input) {
      success
    }
  }
`;

const ArchiveDocumentMutation = gql`
  mutation ArchiveDocument($input: ArchiveDocumentInput!) {
    archiveDocument(archiveDocumentData: $input) {
      success
    }
  }
`;

const UpdateStsTimestampMutation = gql`
  mutation UpdateStsTimestamp($input: UpdateStsTimestampInput!) {
    updateStsTimestamp(updateStsTimestampData: $input) {
      ststimestamp {
        id
        created
        localDatetime
      }
    }
  }
`;

const UploadDocument = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

function Entry({ entry, index, refetchList, allowEdit, isFirst, isLast, preview }) {
  const [open, setOpen] = React.useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = React.useState(false);
  const hasFile = !!entry.attachedDocuments.length;

  const [editing, setEditing] = React.useState(false);
  const [description, setDescription] = React.useState(entry.description);
  const [location, setLocation] = React.useState(entry.location || '');
  const [localDatetime, setLocalDatetime] = React.useState(entry.localDatetime);

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocument, {
    onCompleted() {
      refetchList();
    }
  });

  const [executeArchiveTimestamp] = useMutation(ArchiveStsTimestampMutation, {
    variables: {
      input: {
        ststimestampId: entry.id,
      }
    },
    onCompleted(result) {
      refetchList();
    },
  });

  const [executeArchiveDocument] = useMutation(ArchiveDocumentMutation, {
    onCompleted(result) {
      refetchList();
    },
  });

  const [executeUpdateTimestamp] = useMutation(UpdateStsTimestampMutation, {
    variables: {
      input: {
        ststimestampId: entry.id,
        description,
        location,
        localDatetime: moment(localDatetime).format('YYYY-MM-DDTHH:mm'),
      }
    },
    onCompleted(result) {
      setEditing(false);
      setOpen(false);
      refetchList();
    },
  });



  function cancelEdit() {
    setDescription(entry.description);
    setLocation(entry.location);
    setLocalDatetime(entry.localDatetime);
  }

  function removeFile(id) {
    executeArchiveDocument({
      variables: {
        input: {
          documentId: id,
        }
      }
    })
  }

  function addFile(file) {
    executeUploadDocument({
      variables: {
        input: {
          file,
          filename: file.name,
          ststimestampId: entry.id,
        }
      }
    })
  }

  return (
    <Wrapper index={index} open={open}>
      <Indicator isFirst={isFirst} isLast={isLast} isReported={entry.isReported} />
      <Content>
        {
          editing
            ? (
              <KeyboardDateTimePicker
                variant="inline"
                label="Date and time"
                format="DD/MM/YYYY HH:mm"
                id="new-log-entry-date-time"
                value={localDatetime}
                onChange={setLocalDatetime}
                ampm={false}
                inputVariant="outlined"
                helperText="Local time"
              />
            )
            : <Date>{moment(entry.localDatetime).format('DD MMM - HH:mm')} {hasFile && <DocumentIcon />}</Date>
        }
        {
          preview
            ? description
            : (
              <ToggleInput
                id={`description-${entry.id}`}
                label="Description"
                variant="outlined"
                onChange={e => setDescription(e.target.value)}
                value={description}
                type="text"
                fullWidth
                editing={editing}
                style={{ margin: '16px 0' }}
                multiline
                rows={4}
              />
            )
        }
        {open && (
          <ToggleInput
            id={`location-${entry.id}`}
            label="Location"
            variant="outlined"
            onChange={e => setLocation(e.target.value)}
            value={location}
            type="text"
            fullWidth
            editing={editing}
            style={{ margin: '16px 0' }}
          />
        )}
        {open && (
          <Files>
            <FilesLabel>Attached files</FilesLabel>
            {
              entry.attachedDocuments.length
                ? entry.attachedDocuments.map(f => <File key={f.id} file={f} showDelete={editing} removeFile={removeFile} />)
                : <File noResults />
            }
            { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
          </Files>
        )}
        {
          open && editing && (
            <FileUpload
              id="addFile"
              allowedTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv', 'application/vnd.ms-excel']}
              upload={addFile}
            />
          )
        }
      </Content>
      <Buttons>
        {
          !preview && (
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="open entry"
            >
              <StyledDropdownIcon open={open} />
            </IconButton>
          )
        }
        {open && allowEdit && (
          <EntryOptions
            setDeleteConfirmationVisible={setDeleteConfirmationVisible}
            executeUploadDocument={executeUploadDocument}
            setEditing={setEditing}
            editing={editing}
            cancelEdit={cancelEdit}
            saveChanges={executeUpdateTimestamp}
            id={entry.id}
          />
        )}
      </Buttons>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={deleteConfirmationVisible}
        autoHideDuration={6000}
        onClose={() => setDeleteConfirmationVisible(false)}
        message="Delete this entry?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchiveTimestamp}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setDeleteConfirmationVisible(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 112px;
  display: flex;
  background-color: ${props => props.index %2 === 0 ? '#fff' : '#f8f8f8'};
  box-shadow: ${props => props.open && '0px 2px 6px #00000029'};
`;

const Indicator = styled.div`
  width: 32px;
  border-right: ${props => `2px solid ${props.isReported ? props.theme.colors.primaryColor : props.theme.colors.borderColor}`};
  position: relative;
  flex: none;
  margin-top: ${props => props.isFirst && '27px'};
  height: ${props => props.isLast && '27px'};

  &::after {
    content:"";
    position: absolute;
    top: ${props => props.isFirst ? 0 : '27px'};
    right: -7px;
    width: 12px;
    height: 12px;
    background-color: ${props => props.isReported ? props.theme.colors.primaryColor : props.theme.colors.borderColor};
    border-radius: 50%;
  }
`;

const Content = styled.div`
  padding: 24px 0 24px 24px;
  width: 100%;
`;

const Date = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 700;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & svg {
    margin-left: 8px;
  }
`;

const Buttons = styled.div`
  width: 44px;
`;

const StyledDropdownIcon = styled(DropdownIcon)`
  transform: rotate(${props => props.open ? '180deg' : 0});
  transition: ease all .2s
`;

const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: ${props => props.theme.colors.borderColor};
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  font-weight: 500
`;

export default Entry;
import React from 'react';
import styled from 'styled-components';

function Nav({ view, setView }) {
  return (
    <Wrapper style={{ marginBottom: '16px' }}>
      <Group>
        <Option active={view === 'general'} onClick={() => setView('general')}>General</Option>
        <Option active={view === 'jobs'} onClick={() => setView('jobs')}>Job log</Option>
        <Option active={view === 'maintenance'} onClick={() => setView('maintenance')}>Maintenance</Option>
      </Group>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Group = styled.div`
  display: flex;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: ${props => props.hideOnMobile ? 'none' : 'flex'};
  }
`;

const Option = styled.div`
  text-transform: uppercase;
  color: ${props => props.active ? props.theme.colors.primaryColor : props.theme.colors.textColor};
  border-bottom: 2px solid ${props => props.active ? props.theme.colors.primaryColor : 'transparent'};
  font-weight: 700;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 72px;
`;

export default Nav;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Block from 'components/common/block';
import Pagination from 'components/common/pagination';
import { ReactComponent as STSIcon } from 'assets/vectors/vessels-16px.svg';

const StsProjectsQuery = gql`
  query StsProjects ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $userId: ID, $dateStatus: String){
    stsprojects(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, userId: $userId, dateStatus: $dateStatus) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          projectId
          reference
          location
        }
      }
    }
  }
`;

const resultCount = 5;

function Projects({ isAdmin, loggedInUser }) {
  const [paginationVariables, setPaginationVariables] = React.useState({ first: resultCount });

  const { data, loading } = useQuery(StsProjectsQuery, {
    variables: {
      userId: isAdmin ? null : loggedInUser.id,
      sortBy: ['startDate asc'],
      dateStatus: 'ongoing',
      ...paginationVariables,
    },
    fetchPolicy: 'network-only',
  });

  const stsprojects = data ? data.stsprojects.edges.map(u => u.node) : [];
  const pageInfo = data ? data.stsprojects.pageInfo : {};

  return (
    <StyledBlock title={<BlockTitle><STSIcon style={{ marginRight: '8px'}} /> {isAdmin ? 'ONGOING PROJECTS' : 'MY ONGOING PROJECTS'}</BlockTitle>}>
      {
        loading
          ? <CircularProgress size={64} style={{ margin: '64px auto', display: 'block' }} />
          : <TableContainer>
              <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Project ID
                    </TableCell>
                    <TableCell>
                      Reference
                    </TableCell>
                    <TableCell>
                      Location
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    stsprojects.length
                      ? stsprojects.map((project) => (
                          <TableRow key={project.id}>
                            <TableCell>
                              <ProjectLink to={`/dashboard/sts/${project.id}`}>{project.projectId}</ProjectLink>
                            </TableCell>
                            <TableCell>
                              {project.reference}
                            </TableCell>
                            <TableCell>
                              {project.location}
                            </TableCell>
                          </TableRow>
                        ))
                      : <TableRow>
                          <TableCell>No ongoing projects</TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
      }
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
        style={{
          paddingTop: '16px',
          marginTop: 'auto',
        }}
      />
    </StyledBlock>
  );
}

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primaryColor};
`;

const StyledBlock = styled(Block)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ProjectLink = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default Projects;

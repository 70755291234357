import React from 'react';
import styled from 'styled-components';
import Block from 'components/common/block';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import EditableVessel from 'components/common/editableStsVessel';
import EditProject from 'components/common/editProject';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';

const StsVesselsQuery = gql`
  query StsVessels ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsvessels(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          name
          type
          captain
          captainEmail
          shipType
        }
      }
    }
  }
`;

const CreateStsVesselMutation = gql`
  mutation CreateStsVessel($input: CreateStsVesselInput!) {
    createStsVessel(createStsVesselData: $input) {
      stsvessel {
        id
        created
      }
    }
  }
`;

function VesselsTable({ vessels }) {
  if (!vessels.length) return <NoResult>No ships were added</NoResult>;
  return (
    <TableContainer>
      <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
        <TableBody>
          {vessels.map((vessel, index) => (
            <TableRow key={vessel.id}>
              <TableCell style={{ borderBottom: index === vessels.length - 1 && 0, verticalAlign: 'bottom' }}>
                <VesselNumber>Ship {index + 1}</VesselNumber>
                <Key>Name</Key>
                {
                  vessel.name
                    ? <Val>{vessel.name}</Val>
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === vessels.length - 1 && 0, verticalAlign: 'bottom' }}>
                <Key>Ship type</Key>
                {
                  vessel.shipType
                    ? <Val>{vessel.shipType}</Val>
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === vessels.length - 1 && 0, verticalAlign: 'bottom' }}>
                <Key>Type</Key>
                {
                  vessel.type
                    ? <Val>{vessel.type}</Val>
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === vessels.length - 1 && 0, verticalAlign: 'bottom' }}>
                <Key>Captain</Key>
                  {
                    vessel.captain
                      ? <Val>{vessel.captain}</Val>
                      : <NoResult>-</NoResult>
                  }
              </TableCell>
              <TableCell style={{ borderBottom: index === vessels.length - 1 && 0, verticalAlign: 'bottom' }}>
                <Key>Email</Key>
                {
                    vessel.captainEmail
                      ? <Mail href={`mailto:${vessel.captainEmail}`}>{vessel.captainEmail}</Mail>
                      : <NoResult>-</NoResult>
                  }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EditVessels({ vessels, refetch, executeCreateVessel, createVesselLoading }) {
  return (
    <>
      {
        vessels.map((v, i) => <EditableVessel key={v.id} vessel={v} index={i} refetch={refetch} />)
      }
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          disabled={createVesselLoading}
          onClick={executeCreateVessel}
          endIcon={<AddIcon />}
        >
         Add ship
        </Button>
      </ButtonWrapper>
    </>
  );
}

function Vessels({ id, allowEdit }) {
  const [editing, setEditing] = React.useState(false);
  const { data, loading, error, refetch } = useQuery(StsVesselsQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeCreateVessel, { loading: createVesselLoading }] = useMutation(CreateStsVesselMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      refetch()
    },
  });

  function cancel() {
    setEditing(false);
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error) return null

  const vessels = data && data.stsvessels ? data.stsvessels.edges.map(v => v.node) : [];

  return (
    <Block
      title={
        <BlockTitle>
          SHIPS
          {
            allowEdit && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                cancel={cancel}
                noSave
                cancelLabel="Finish"
              /> 
            )
          } 
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      {
        editing
          ? <EditVessels vessels={vessels} refetch={refetch} executeCreateVessel={executeCreateVessel} createVesselLoading={createVesselLoading} />
          : <VesselsTable vessels={vessels} />
      }
    </Block>
  );
}

const VesselNumber = styled.p`
  font-weight: 700;
  font-size: 11px;
  color: ${props => props.theme.colors.textColor};
  margin-bottom: 4px
`;

const Key = styled.p`
  color: black;
  font-size: 11px;
  margin-bottom: 4px;
  font-weight: 500;
`;

const Val = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  text-transform: capitalize;
`;

const Mail = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const NoResult = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  padding: 0 12px;
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Vessels;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { ReactComponent as StartDateIcon } from 'assets/vectors/start-date-16px.svg';
import { ReactComponent as EndDateIcon } from 'assets/vectors/end-date-16px.svg';
import ToggleInput from 'components/common/toggleInput';
import Tag from 'components/common/tag';
import Block from 'components/common/block';
import EditProject from 'components/common/editProject';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        projectId
        startDate
        endDate
        location
        description
        timezoneOffset
        dateStatus
        product
        reference
        quantity
      }
    }
  }
`;

const UpdateStsProjectMutation = gql`
  mutation UpdateStsProject($input: UpdateStsProjectInput!) {
    updateStsProject(updateStsProjectData: $input) {
      stsproject {
        id
        projectId
        startDate
        endDate
        location
        description
        timezoneOffset
        dateStatus
        product
        reference
        quantity
      }
    }
  }
`;

const timeZoneRegex = /GMT[+-][0-9]{2}:[0-9]{2}/g;

function General({ id, allowEdit }) {
  const [editing, setEditing] = React.useState(false);
  const [projectId, setProjectId] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [product, setProduct] = React.useState('');
  const [reference, setReference] = React.useState('');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState('GMT+');
  const [minutesOffset, setMinutesOffset] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [timezoneError, setTimeZoneError] = React.useState(false);

  const { data, error, loading } = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const project = result.node;
      if (!project) return;

      if (project.projectId) setProjectId(project.projectId);
      if (project.startDate) setStartDate(moment(project.startDate), 'YYYY-MM-DD');
      if (project.endDate) setEndDate(moment(project.endDate), 'YYYY-MM-DD');
      if (project.location) setLocation(project.location);
      if (project.description) setDescription(project.description);
      if (project.product) setProduct(project.product);
      if (project.reference) setReference(project.reference);
      if (project.quantity) setQuantity(project.quantity);
      if (project.timezoneOffset) {
        setMinutesOffset(project.timezoneOffset);
        setTimeZone(getTimeZoneFromMinutes(project.timezoneOffset))
      };
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateProjectInformation, { data: updateProjectInfoData }] = useMutation(UpdateStsProjectMutation, {
    variables: {
      input: {
        stsprojectId: id,
        projectId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        location,
        timezoneOffset: minutesOffset,
        description,
        product,
        reference,
        quantity,
      },
    },
  });

  function timeZoneInputHandler(val) {
    if (val.match(timeZoneRegex)) {
      const hoursAsMinutes = parseInt(val.split('GMT')[1].split(':')[0].substring(1)) * 60;
      const minutes = parseInt(val.split(':')[1]);
      const totalMinutes = hoursAsMinutes + minutes;
      const operator = val[3];
      const minutesOffset = `${operator}${totalMinutes}`;

      setMinutesOffset(minutesOffset);
      setTimeZoneError(false);
    } else {
      setTimeZoneError(true);
    }
    setTimeZone(val);
  }

  function getTimeZoneFromMinutes(minutes) {
    let prefix = '+';
    let convertedMinutes = 0;

    if (minutes < 0) {
      prefix = '-';
      convertedMinutes = Math.abs(minutes);
    } else {
      convertedMinutes = minutes;
    }

    const hours = convertedMinutes / 60;
    const roundedHours = JSON.stringify(Math.floor(hours)).padStart(2, '0');
    const remainingMinutes = JSON.stringify(convertedMinutes - (roundedHours * 60)).padStart(2, '0');

    return `GMT${prefix}${roundedHours}:${remainingMinutes}`;
  }

  function save() {
    executeUpdateProjectInformation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateProjectInfoData ? updateProjectInfoData.updateStsProject.stsproject : data.node;

    setProjectId(lastSaved.projectId);
    setStartDate(moment(lastSaved.startDate), 'YYYY-MM-DD');
    setEndDate(moment(lastSaved.endDate), 'YYYY-MM-DD');
    setLocation(lastSaved.location);
    setDescription(lastSaved.description);
    setProduct(lastSaved.product);
    setReference(lastSaved.reference);
    setMinutesOffset(lastSaved.timezoneOffset);
    setTimeZone(getTimeZoneFromMinutes(lastSaved.timezoneOffset));
    setQuantity(lastSaved.quantity);
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const project = data.node;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            allowEdit && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                noSave={timezoneError}
                cancelLabel="Cancel"
              /> 
            )
          } 
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Split>
        <Column style={{ marginTop: 0, paddingTop: 0 }}>
          <Row>
            <Column style={{ marginTop: 0, paddingTop: 0 }}>
              <ToggleInput
                id="projectId"
                label="Project ID"
                variant="outlined"
                onChange={e => setProjectId(e.target.value)}
                value={projectId}
                type="text"
                fullWidth
                editing={editing}
                style={{ marginBottom: '16px' }}
              />
              <ToggleInput
                id="reference"
                label="Client reference"
                variant="outlined"
                onChange={e => setReference(e.target.value)}
                value={reference}
                type="text"
                fullWidth
                editing={editing}
                style={{ marginBottom: '16px' }}
              />
            </Column>
          </Row>
          <ToggleInput
            id="location"
            label="Location"
            variant="outlined"
            onChange={e => setLocation(e.target.value)}
            value={location}
            type="text"
            fullWidth
            editing={editing}
            style={{ marginBottom: '16px' }}
          />
          <ToggleInput
            id="product"
            label="Product"
            variant="outlined"
            onChange={e => setProduct(e.target.value)}
            value={product}
            type="text"
            fullWidth
            editing={editing}
            style={{ marginBottom: '16px' }}
          />
          <ToggleInput
            id="quantity"
            label="Quantity"
            variant="outlined"
            onChange={e => setQuantity(e.target.value)}
            value={quantity}
            type="text"
            fullWidth
            editing={editing}
            style={{ marginBottom: '16px' }}
          />
        </Column>
        <Column>
          {
            editing
              ? (
                <>
                  <KeyboardDatePicker
                    variant="inline"
                    format="DD/MM/YYYY"
                    id="date-picker-start-date"
                    label="Start date"
                    value={startDate}
                    onChange={setStartDate}
                    keyboardIcon={<StartDateIcon />}
                    autoOk
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    inputVariant="outlined"
                  />
                  <KeyboardDatePicker
                    variant="inline"
                    format="DD/MM/YYYY"
                    id="date-picker-end-date"
                    label="End date"
                    value={endDate}
                    onChange={setEndDate}
                    style={{ marginBottom: '16px' }}
                    keyboardIcon={<EndDateIcon />}
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                  />
                </>
              )
              : (
                <>
                  <Key>Start date</Key>
                  <Val>{moment(project.startDate).format('DD/MM/YYYY')}</Val>
                  <Key>End date</Key>
                  <Val>{moment(project.endDate).format('DD/MM/YYYY')}</Val>
                </>
              )
          }
          <ToggleInput
            id="timezone"
            label="Time zone"
            variant="outlined"
            onChange={e => timeZoneInputHandler(e.target.value.toUpperCase())}
            value={timeZone}
            type="text"
            fullWidth
            editing={editing}
            style={{ marginBottom: '16px' }}
            helperText="GMT+01:00"
            error={timezoneError}
            inputProps={{ maxLength: 9 }}
          />
          <Key>Status</Key>
          <Tag color={project.dateStatus === 'planned' ? 'yellow' : project.dateStatus === 'finished' ? 'blue' : 'green'} label={project.dateStatus} style={{ marginLeft: '12px', marginBottom: editing ? '32px' : 0 }} />
        </Column>
      </Split>
      <StyledToggleInput
        id="notes"
        label="Notes"
        variant="outlined"
        onChange={e => setDescription(e.target.value)}
        value={description}
        type="text"
        fullWidth
        editing={editing}
      />
    </Block>
  );
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;

  @media (${props => props.theme.breakpoints.tablet}) {
    margin: 16px 0;
    padding: 16px 0;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;

  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const Key = styled.p`
  color: black;
  font-size: 11px;
  margin-bottom: 4px;
  margin-left: 12px;
  font-weight: 500;
`;

const Val = styled.p`
  color: rgba(0,0,0,0.87);
  margin-bottom: 20px;
  margin-left: 12px
`;

const StyledToggleInput = styled(ToggleInput)`
  @media (${props => props.theme.breakpoints.tablet}) {
    margin-top: 32px;
  }
`;

export default General;

import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { ReactComponent as MoreIcon } from 'assets/vectors/more-menu-16px.svg';


function EntryOptions({ setDeleteConfirmationVisible, id, editing, setEditing, cancelEdit, saveChanges }) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function deleteHandler(e) {
    handleClose(e);
    setDeleteConfirmationVisible(true);
  }

  function editHandler(e) {
    handleClose(e)
    if (editing) cancelEdit();
    setEditing(!editing);
  }

  function saveHandler(e) {
    handleClose(e);
    saveChanges();
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <StyledMoreIcon open={open} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={editHandler}>{editing ? 'Cancel' : 'Edit'}</MenuItem>
                  { editing && <MenuItem onClick={saveHandler}>Save</MenuItem>}
                  <MenuItem onClick={deleteHandler}>Remove entry</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const StyledMoreIcon = styled(MoreIcon)`
  transform: rotate(${props => props.open ? '90deg' : 0});
  transition: ease all .2s
`;

export default EntryOptions;
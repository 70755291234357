import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as PlusIcon } from 'assets/vectors/add-white-16px.svg';
import ActionBar from 'components/common/actionBar';
import Block from 'components/common/block';
import useDebounce from 'helpers/useDebounce';

import Filters from './filters';
import UserTable from './userTable';

const UsersQuery = gql`
  query Users ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $inRoles: [String], $search: String, $showArchived: Boolean){
    users(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, inRoles: $inRoles, search: $search, showArchived: $showArchived) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          fullName
          roles
          email
          phone
          imageUrl
        }
      }
    }
  }
`;

function OverviewUsers() {
  const history = useHistory();
  const urlParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  function resultCountDefaultData() {
    if (urlParams.first || urlParams.last) {
      return urlParams.first || urlParams.last;
    } else {
      return 10;
    }
  }
  
  function searchDefaultData() {
    if (urlParams.search) {
      return urlParams.search;
    } else {
      return '';
    }
  }

  function paginationDefaultData() {
    if (urlParams.after) {
      return { first: resultCount, after: urlParams.after };
    } else if (urlParams.before) {
      return { last: resultCount, before: urlParams.before };
    } else {
      return { first: resultCount };
    }
  }

  function sortByDefaultData() {
    if (urlParams.sortBy) {
      return { type: urlParams.sortBy.split('.')[0], direction: urlParams.sortBy.split('.')[1]};
    } else {
      return { type: 'firstName', direction: 'asc' };
    }
  }

  const [resultCount, setResultCount] = React.useState(resultCountDefaultData());
  const [paginationVariables, setPaginationVariables] = React.useState(paginationDefaultData());
  const [search, setSearch] = React.useState(searchDefaultData());
  const [sortBy, setSortBy] = React.useState(sortByDefaultData());
  const debouncedSearch = useDebounce(search, 300);

  React.useEffect(() => {
    if ((paginationVariables.before || paginationVariables.after) && debouncedSearch.length && debouncedSearch !== urlParams.search) {
      setPaginationVariables({
        first: resultCount,
      })
    }
  }, [debouncedSearch, paginationVariables, resultCount, urlParams]);

  React.useEffect(() => {
    const params = {
      search: debouncedSearch.length ? debouncedSearch : undefined,
      after: paginationVariables.after || undefined,
      first: paginationVariables.first || undefined,
      last: paginationVariables.last || undefined,
      before: paginationVariables.before || undefined,
      sortBy: `${sortBy.type}.${sortBy.direction}`,
    }

    window.history.replaceState(null, "", `/dashboard/users?${qs.stringify(params)}`);
    // history.push(`/dashboard/users?${qs.stringify(params)}`);
  }, [debouncedSearch, paginationVariables, history, resultCount, sortBy]);

  const { data, loading } = useQuery(UsersQuery, {
    variables: {
      search: debouncedSearch.length ? debouncedSearch : null,
      sortBy: [`${sortBy.type} ${sortBy.direction}`],
      ...paginationVariables,
    },
    fetchPolicy: 'network-only',
  });

  const users = data ? data.users.edges.map(u => u.node) : [];
  const pageInfo = data ? data.users.pageInfo : {};

  return (
    <>
      {
        isAdmin && (
          <StyledActionBar>
            <StyledLink to="/dashboard/users/new">
              <Button
                variant="contained"
                color="primary"
                endIcon={<PlusIcon />}
              >
                Add user 
              </Button>
            </StyledLink>
          </StyledActionBar>
        )
      }
      <Block noTitle>
        <Filters
          resultCount={resultCount}
          setResultCount={setResultCount}
          search={search}
          setSearch={setSearch}
          setPaginationVariables={setPaginationVariables}
        />
        {
          loading
            ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
            : <UserTable
                users={users}
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
        }
      </Block>
    </>
  );
}

const StyledActionBar = styled(ActionBar)`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: block;
`;

export default OverviewUsers;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Nav from './nav';
import GeneralInformation from './general/generalInformation';
import JobLog from './general/jobLog';
import Maintenance from './general/maintenance';
import Documents from './general/documents';
import JobLogOverview from './jobs/jobLogOverview';
import MaintenanceOverview from './maintenance/maintenanceOverview';

function Reducer() {
  const history = useHistory();
  const id = history.location.pathname.split('/reducers/')[1];
  const [view, setView] = React.useState('general');

  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  return (
    <Wrapper>
      <Nav view={view} setView={setView} />
      {
        view === 'general'
          ? <GeneralContent>
              <GeneralInformation id={id} isAdmin={isAdmin} />
              <JobLog id={id} />
              <Maintenance id={id} />
              <Documents id={id} isAdmin={isAdmin} />
            </GeneralContent>
          : view === 'jobs'
            ? <Content>
                <JobLogOverview id={id} />
              </Content>
            : <Content>
                <MaintenanceOverview id={id} />
              </Content>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${props => props.theme.breakpoints.tablet}) {
    padding-bottom: 32px;
  }
`;

const GeneralContent = styled.div`
  width: 1200px;
  max-width: 100%;
`;

const Content = styled.div`
  width: 1200px;
  max-width: 100%;
`;

export default Reducer;

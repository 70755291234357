import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import Block from 'components/common/block';

const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const AddUserMutation = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(addUserData: $input) {
      success
    }
  }
`;

function NewUser() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [addedSnackbarOpen, setAddedSnackbarOpen] = React.useState(false);

  const [executeAddUser, { loading }] = useMutation(AddUserMutation, {
    variables: {
      input: {
        firstName,
        lastName,
        phone,
        email,
      },
    },
    onCompleted(result) {
      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      setAddedSnackbarOpen(true);
    },
  });

  function emailInputHandler(val) {
    if (val.match(mailRegex)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setEmail(val)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAddedSnackbarOpen(false);
  };

  return (
    <StyledBlock title="INVITE USER">
      <Content>
        <Column>
          <TextField
            id="firstName"
            label="First name"
            variant="outlined"
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
            type="text"
            fullWidth
            style={{ marginBottom: '32px' }}
          />
          <TextField
            id="lastName"
            label="Last name"
            variant="outlined"
            onChange={e => setLastName(e.target.value)}
            value={lastName}
            type="text"
            fullWidth
          />
        </Column>
        <Column>
          <TextField
            id="phone"
            label="Phone"
            variant="outlined"
            onChange={e => setPhone(e.target.value)}
            value={phone}
            type="text"
            fullWidth
            style={{ marginBottom: '32px' }}
          />
          <TextField
            id="email"
            label="Email address"
            variant="outlined"
            onChange={e => emailInputHandler(e.target.value)}
            value={email}
            type="email"
            fullWidth
            error={emailError}
          />
        </Column>
      </Content>
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !firstName.length || !lastName.length || !email.length || emailError}
          onClick={executeAddUser}
        >
          Invite user
        </Button>
      </ButtonWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={addedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="User successfully added"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;
  margin-bottom: 32px;

  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default NewUser;

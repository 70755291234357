import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Block from 'components/common/block';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';

import EditProject from 'components/common/editProject';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        ststimestampStart {
          id
          localDatetime
          description
        }
        ststimestampEnd {
          id
          localDatetime
          description
        }
      }
    }
  }
`;

const UpdateStsProjectMutation = gql`
  mutation UpdateStsProject($input: UpdateStsProjectInput!) {
    updateStsProject(updateStsProjectData: $input) {
      stsproject {
        id
        ststimestampStart {
          id
          localDatetime
          description
        }
        ststimestampEnd {
          id
          localDatetime
          description
        }
      }
    }
  }
`;

function Invoicing({ id, allowEdit, timestamps }) {
  const [editing, setEditing] = React.useState(false);
  const [startTimestamp, setStartTimestamp] = React.useState('');
  const [endTimestamp, setEndTimestamp] = React.useState('');

  const { data: queryData, error: queryError, loading: queryLoading } = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const project = result.node;
      if (!project) return;

      setStartTimestamp(project.ststimestampStart || '')
      setEndTimestamp(project.ststimestampEnd || '')
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateProjectInformation, { data: editData, loading: editLoading, error: editError }] = useMutation(UpdateStsProjectMutation, {
    variables: {
      input: {
        stsprojectId: id,
        ststimestampStartId: startTimestamp.id || null,
        ststimestampEndId: endTimestamp.id || null,
      },
    },
    skip: !id,
  });

  function save() {
    executeUpdateProjectInformation();
    setEditing(false);
  }

  function cancel() {
    const lastSavedEquipment = editData ? editData.updateStsProject.stsproject : queryData.node;
    setStartTimestamp(lastSavedEquipment.ststimestampStart || '');
    setEndTimestamp(lastSavedEquipment.ststimestampEnd || '');
    setEditing(false);
  }

  function startHandler(e) {
    const selected = timestamps.filter(t => t.id === e.target.value)[0];

    setStartTimestamp(selected)
  }

  function endHandler(e) {
    const selected = timestamps.filter(t => t.id === e.target.value)[0];

    setEndTimestamp(selected)
  }

  function calcHours() {
    const diff = moment.duration(moment(endTimestamp.localDatetime).diff(moment(startTimestamp.localDatetime)));
    const hours = diff.asHours();
    const flooredHours = Math.floor(hours);
    const remainder = hours - flooredHours;
    const flooredMinutes = Math.floor(remainder * 60);

    const hoursString = `${flooredHours}`.padStart(2, '0');
    const minutesString = `${flooredMinutes}`.padStart(2, '0')

    return <b>{hoursString} hours and {minutesString} minutes</b>;
  }

  if (queryLoading || editLoading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (queryError || editError || !queryData.node) return null;

  const chronologicalTimestamps = timestamps.sort((a, b) => a.localDatetime.localeCompare(b.localDatetime));

  return (
    <Block
      title={
        <BlockTitle>
          INVOICING
          {
            allowEdit && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
              /> 
            )
          } 
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <SelectWrapper>
        <FormControl fullWidth>
          <InputLabel id="startTimestampLabel">Start of invoicing</InputLabel>
          <Select
            labelId="startTimestampLabel"
            id="startTimestamp"
            value={startTimestamp.id || ''}
            onChange={startHandler}
            disabled={!editing}
          >
            {
              chronologicalTimestamps.length
                ? chronologicalTimestamps.map((t) => (
                    <MenuItem
                      key={t.id}
                      value={t.id}
                      disabled={moment(t.localDatetime).isSameOrAfter(moment(endTimestamp.localDatetime))}
                    >
                      {moment(t.localDatetime).format('DD/MM/YY - HH:mm')} - {t.description.substring(0, 25)}
                    </MenuItem>
                  ))
                : <MenuItem disabled>No timestamps available</MenuItem>
            }
          </Select>
        </FormControl>
      </SelectWrapper>
      <SelectWrapper>
        <FormControl fullWidth>
          <InputLabel id="startTimestampLabel">End of invoicing</InputLabel>
          <Select
            labelId="endTimestampLabel"
            id="endTimestamp"
            value={endTimestamp.id || ''}
            onChange={endHandler}
            disabled={!editing}
          >
            {
              chronologicalTimestamps.length
                ? chronologicalTimestamps.map((t) => (
                    <MenuItem
                      key={t.id}
                      value={t.id}
                      disabled={moment(t.localDatetime).isSameOrBefore(moment(startTimestamp.localDatetime))}
                    >
                      {moment(t.localDatetime).format('DD/MM/YY - HH:mm')} - {t.description.substring(0, 25)}
                    </MenuItem>
                  ))
                : <MenuItem disabled>No timestamps available</MenuItem>
            }
          </Select>
        </FormControl>
      </SelectWrapper>
      <InvoiceHours>Duration: {startTimestamp && endTimestamp ? calcHours() : 0}</InvoiceHours>
    </Block>
  );
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  width: 320px;
  margin-bottom: 16px;
  padding: 0 12px;
`;

const InvoiceHours = styled.p`
  color: ${props => props.theme.colors.textColor};
  padding: 0 12px;
`;

export default Invoicing;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Block from 'components/common/block';
import File from 'components/common/fileRow';
import FileUpload from 'components/common/fileUpload';

import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';

const GetStsProjectQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        equipment
        attachedDocuments {
          id
          filename
        }
      }
    }
  }
`;

const UploadDocument = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

const ArchiveDocumentMutation = gql`
  mutation ArchiveDocument($input: ArchiveDocumentInput!) {
    archiveDocument(archiveDocumentData: $input) {
      success
    }
  }
`;

function EquipmentAndDocs({ id }) {
  const { data, refetch } = useQuery(GetStsProjectQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocument, {
    onCompleted() {
      refetch();
    }
  });

  const [executeArchiveDocument] = useMutation(ArchiveDocumentMutation, {
    onCompleted() {
      refetch();
    },
  });

  function removeFile(id) {
    executeArchiveDocument({
      variables: {
        input: {
          documentId: id,
        }
      }
    })
  }

  function addFile(file) {
    executeUploadDocument({
      variables: {
        input: {
          file,
          filename: file.name,
          stsprojectId: id,
        }
      }
    })
  }

  return (
   <StyledBlock title="DOCUMENTS" style={{ marginTop: '16px' }}>
      <UploadWrapper>
        <FileUpload
          id="newFile"
          allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
          upload={addFile}
        >
          <Add htmlFor="newFile">
            ADD FILE <DocumentIcon />
            <input
              type="file"
              required
              id="newFile"
              onChange={addFile}
              style={{ display: 'none' }}
            />
          </Add>
        </FileUpload>
      </UploadWrapper>
      <Files>
        <FilesLabel>Attached files</FilesLabel>
        {
          data && data.node.attachedDocuments.length
            ? data.node.attachedDocuments.map(f => <File key={f.id} file={f} showDelete removeFile={removeFile} />)
            : <File noResults />
        }
        { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
      </Files>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
`;

const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: ${props => props.theme.colors.borderColor};
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  font-weight: 500;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;


const Add = styled.label`
  color: ${props => props.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  padding: 8px;
  border-radius: 4px;

  & svg {
    margin-left: 8px;
    opacity: .5;
  }
`;


export default EquipmentAndDocs;
import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ActionBar from 'components/common/actionBar';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CheckIcon } from 'assets/vectors/check-white-16px.svg';

function Nav({ currentStep, goToStep, generalComplete, vesselsComplete, usersComplete, equipmentComplete, docsComplete, id }) {
  const history = useHistory();
  function navigate(step) {
    // if (currentStep === 1 && !generalComplete) {
    //   return;
    // }

    goToStep(step);
  }

  function nextButtonDisabled() {
    if (currentStep === 1 && !generalComplete) return true;
    return false;
  }

  function nextNav() {
    if (currentStep === 5) {
      history.push(`/dashboard/sts/${id}`)
    } else {
      navigate(currentStep + 1);
    }
  }

  function backNav() {
    navigate(currentStep - 1);
  }

  return (
    <>
      <Desktop>
        <Step onClick={() => navigate(1)}>
          <Bubble active={currentStep === 1} complete={generalComplete}>
            {generalComplete ? <CheckIcon /> : '1'}
          </Bubble>
          <Label active={currentStep === 1}>General</Label>
        </Step>
        <Step onClick={() => navigate(2)}>
          <Line active={currentStep === 2} complete={vesselsComplete} />
          <Bubble active={currentStep === 2} complete={vesselsComplete}>
            {vesselsComplete ? <CheckIcon /> : '2'}
          </Bubble>
          <Label active={currentStep === 2}>Ships</Label>
        </Step>
        <Step onClick={() => navigate(3)}>
          <Line active={currentStep === 3} complete={usersComplete} />
          <Bubble active={currentStep === 3} complete={usersComplete}>
            {usersComplete ? <CheckIcon /> : '3'}
          </Bubble>
          <Label active={currentStep === 3} >Users</Label>
        </Step>
        <Step onClick={() => navigate(4)}>
          <Line active={currentStep === 4} complete={equipmentComplete} />
          <Bubble active={currentStep === 4} complete={equipmentComplete}>
            {equipmentComplete ? <CheckIcon /> : '4'}
          </Bubble>
          <Label active={currentStep === 4}>Equipment</Label>
        </Step>
        <Step onClick={() => navigate(5)}>
          <Line active={currentStep === 5} complete={docsComplete} />
          <Bubble active={currentStep === 5} complete={docsComplete}>
            {docsComplete ? <CheckIcon /> : '5'}
          </Bubble>
          <Label active={currentStep === 5}>Docs</Label>
        </Step>
        <ButtonWrapper>
          <Button
            style={{
              marginRight: '8px',
              visibility: currentStep > 1 ? 'visible' : 'hidden',
            }}
            onClick={backNav}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={nextButtonDisabled()}
            onClick={nextNav}
          >
            {currentStep === 5 && generalComplete ? 'Create' : 'Next'}
          </Button>
        </ButtonWrapper>
      </Desktop>
      <Mobile style={{ border: 0, margin: 0 }}>
        <Label active>{currentStep === 1 ? 'General' : currentStep === 2 ? 'Vessels' : currentStep === 3 ? 'Users' : 'Equipment and documents'}</Label>
        <Line style={{ width: '100%', opacity: 0.1 }} />
        <MobileBubbles>
          <Bubble active={currentStep === 1} complete={generalComplete}>
            {generalComplete ? <CheckIcon /> : '1'}
          </Bubble>
          <Bubble active={currentStep === 2} complete={vesselsComplete}>
            {vesselsComplete ? <CheckIcon /> : '2'}
          </Bubble>
          <Bubble active={currentStep === 3} complete={usersComplete}>
            {usersComplete ? <CheckIcon /> : '3'}
          </Bubble>
          <Bubble active={currentStep === 4} complete={equipmentComplete}>
            {equipmentComplete ? <CheckIcon /> : '4'}
          </Bubble>
          <Bubble active={currentStep === 5} complete={docsComplete}>
            {docsComplete ? <CheckIcon /> : '5'}
          </Bubble>
        </MobileBubbles>
        <ButtonWrapper>
          <Button
            style={{
              marginRight: '8px',
              visibility: currentStep > 1 ? 'visible' : 'hidden',
            }}
            onClick={backNav}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={nextButtonDisabled()}
            onClick={nextNav}
          >
            {currentStep === 5 && generalComplete ? 'Create' : 'Next'}
          </Button>
        </ButtonWrapper>
      </Mobile>
    </>
  );
}

const Desktop = styled(ActionBar)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${props => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

const Mobile = styled(ActionBar)`
  display: none;
  justify-content: space-between;
  align-items: center;

  @media (${props => props.theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const MobileBubbles = styled.div`
  display: flex;
  align-items: center;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Bubble = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${props => props.complete ? props.theme.colors.primaryColor : props.active ? '#D2D2D2' : '#ECEFEC'};
  color: ${props => props.active ? props.theme.colors.textColor : '#BCBEBC'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex: none;

  @media (${props => props.theme.breakpoints.laptop}) {
    height: ${props => props.active ? '32px' : '16px'};
    width: ${props => props.active ? '32px' : '16px'};
    font-size: 11px;

    & svg {
      height: 11px;
      width: 11px;
    }
  }
`;

const Label = styled.p`
  margin-right: 8px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.active ? props.theme.colors.textColor : '#BCBEBC'};
  flex: none;
`;

const Line = styled.div`
  width: 28px;
  border-bottom: 1px solid ${props => props.active || props.complete ? props.theme.colors.textColor : props.theme.colors.borderColor};
  margin-right: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;

  @media (${props => props.theme.breakpoints.laptop}) {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    z-index: 0;
    background: #F9FCF9;
    padding: 16px;
    z-index: 2;
    border-top: 1px solid ${props => props.theme.colors.borderColor};
  }
`;

export default Nav;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetInsulationFlangeQuery = gql`
  query GetInsulationFlange($id: ID!) {
    node(id: $id) {
      ... on InsulationFlange {
        id
        plNumber
        serialNumber
        size
        type
        location
        status
      }
    }
  }
`;

const UpdateInsulationFlangeMutation = gql`
  mutation UpdateInsulationFlange($input: UpdateInsulationFlangeInput!) {
    updateInsulationFlange(updateInsulationFlangeData: $input) {
      insulationFlange {
        id
      }
    }
  }
`;

const ArchiveHoseInsulationFlangeMutation = gql`
  mutation ArchiveInsulationFlange($input: ArchiveInsulationFlangeInput!) {
    archiveInsulationFlange(archiveInsulationFlangeData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [plNumber, setPlNumber] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [size, setSize] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [type, setType] = React.useState('');
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);

  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetInsulationFlangeQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const insulationFlange = result.node;
      if (!insulationFlange) return;

      if (insulationFlange.plNumber) setPlNumber(insulationFlange.plNumber);
      if (insulationFlange.serialNumber) setSerialNumber(insulationFlange.serialNumber);
      if (insulationFlange.size) setSize(insulationFlange.size);
      if (insulationFlange.location) setLocation(insulationFlange.location);
      if (insulationFlange.status) setStatus(insulationFlange.status);
      if (insulationFlange.type) setType(insulationFlange.type);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateInsulationFlangeMutation, {
    variables: {
      input: {
        insulationFlangeId: id,
        serialNumber,
        size: size || 0,
        location,
        plNumber,
        status,
        type,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveHoseInsulationFlangeMutation, {
    variables: {
      input: {
        insulationFlangeId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/insulationFlanges');
    },
    onError: (e) => {
      console.log(e)
    }
  });

  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateInsulationFlange.insulationFlange : data.node;

    setSerialNumber(lastSaved.serialNumber)
    setSize(lastSaved.size)
    setLocation(lastSaved.location)
    setPlNumber(lastSaved.plNumber)
    setStatus(lastSaved.status)
    setType(lastSaved.type)
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'size', label: 'Size in inches', onChange: setSize, value: size, type: 'number'}, 
    {id: 'type', label: 'Type', onChange: setType, value: type, type: 'text'}, 
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this insulation flange?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

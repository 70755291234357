import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';

import File from 'components/common/fileRow';
import Block from 'components/common/block';
import Pagination from 'components/common/pagination';
import { ReactComponent as EditIcon } from 'assets/vectors/edit-16px.svg';

import MaintenanceModal from './maintenanceModal';
import MaintenanceDocuments from './maintenanceDocuments';

const GetFenderQuery = gql`
  query GetFender($id: ID!) {
    node(id: $id) {
      ... on Fender {
        id
        attachedLogDocuments {
          id
          filename
          created
          documentUrl
        }
      }
    }
  }
`;

const GetFenderMaintenanceQuery = gql`
  query EquipmentLog ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $fenderId: ID){
    equipmentLogs(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, fenderId: $fenderId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          timestamp
          location
          remarks
          attachedDocuments {
            id
            filename
            documentUrl
          }
          createdByUser {
            id
            fullName
          }
        }
      }
    }
  }
`;

const resultCount = 10;

function MaintenanceOverview({ id }) {
  const [showMaintenanceModal, setShowMaintenanceModal] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [paginationVariables, setPaginationVariables] = React.useState({first: resultCount});

  const { data: docsData, error: docsError, loading: docsLoading, refetch: docsRefetch } = useQuery(GetFenderQuery, {
    variables: {
      id,
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });


  const { data, error, loading, refetch } = useQuery(GetFenderMaintenanceQuery, {
    variables: {
      fenderId: id,
      ...paginationVariables,
      sortBy: ['timestamp desc']
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });


  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.equipmentLogs) return null;

  const maintenances = data.equipmentLogs.edges.map(j => j.node);
  const pageInfo = data ? data.equipmentLogs.pageInfo : {};

  function openEditModal(id) {
    setEditId(id);
    setShowMaintenanceModal(true)
  }

  function refetchData() {
    refetch();
    docsRefetch();
  }

  const docs = docsData ? docsData.node.attachedLogDocuments : [];

  return (
    <>
      <StyledBlock title="MAINTENANCE AND INSPECTIONS LOG">
      <TableContainer>
        <Table style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell>
                Signature
              </TableCell>
              <TableCell>
                Remarks
              </TableCell>
              <TableCell>
                Files
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !maintenances.length
                ? <TableRow>
                    <TableCell>No results</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                : (
                  maintenances.map((maintenance) => (
                    <TableRow key={maintenance.id}>
                      <TableCell>
                        {moment(maintenance.timestamp).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {maintenance.location}
                      </TableCell>
                      <TableCell>
                        {maintenance.createdByUser.fullName}
                      </TableCell>
                      <TableCell style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '300px'}}>
                        {maintenance.remarks}
                      </TableCell>
                      <TableCell>
                        {maintenance.attachedDocuments.map(d => <File key={d.id} file={d} />)}
                      </TableCell>
                      <TableCell align="right">
                      <IconButton style={{ marginLeft: '16px' }} size="small" aria-label="close" color="inherit" onClick={() => openEditModal(maintenance.id)}>
                        <EditIcon />
                      </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<EditIcon />}
          style={{ marginRight: '8px', textDecoration: 'none', marginTop: '16px' }}
          onClick={() => setShowMaintenanceModal(true)}
        >Add maintenance</Button>
        <Pagination
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
          resultCount={resultCount}
          pageInfo={pageInfo}
        />
      </ButtonWrapper>
        {showMaintenanceModal && <MaintenanceModal fenderId={id} setShowMaintenanceModal={setShowMaintenanceModal} refetchMaintenance={refetchData} id={editId} setEditId={setEditId} />}
      </StyledBlock>
      <MaintenanceDocuments documents={docs} docsError={docsError} docsLoading={docsLoading} />
    </>
  )
}

const StyledBlock = styled(Block)`
  max-width: 100%;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export default MaintenanceOverview;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';

import Block from 'components/common/block';
import ToggleInput from 'components/common/toggleInput';
import { ReactComponent as AddIcon } from 'assets/vectors/add-16px.svg';
import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';
import { ReactComponent as EditIcon } from 'assets/vectors/edit-16px.svg';
import { ReactComponent as CheckIcon } from 'assets/vectors/check-16px.svg';

const StsTimestampSuggestionsQuery = gql`
  query StsTimestampSuggestions ($first: Int, $after: String, $sortBy: [String], $search: String){
    ststimestampSuggestions(first: $first, after: $after sortBy: $sortBy, search: $search) {
      edges {
        node {
          id
          value
        }
      }
    }
  }
`;

const CreateStsTimestampSuggestionMutation = gql`
  mutation CreateStsTimestampSuggestion($input: CreateStsTimestampSuggestionInput!) {
    createStsTimestampSuggestion(createStsTimestampSuggestionData: $input) {
      ststimestampSuggestion {
        id
        value
      }
    }
  }
`;

const ArchiveStsTimestampSuggestionMutation = gql`
  mutation ArchiveStsTimestampSuggestion($input: ArchiveStsTimestampSuggestionInput!) {
    archiveStsTimestampSuggestion(archiveStsTimestampSuggestionData: $input) {
      success
    }
  }
`;

const UpdateStsTimestampSuggestionMutation = gql`
  mutation UpdateStsTimestampSuggestion($input: UpdateStsTimestampSuggestionInput!) {
    updateStsTimestampSuggestion(updateStsTimestampSuggestionData: $input) {
      ststimestampSuggestion {
        id
        value
      }
    }
  }
`;

function Suggestion({ item, refetch, loading }) {
  const [editing, setEditing] = React.useState(false);
  const [itemValue, setItemValue] = React.useState(item.value || '');
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const [executeUpdate, { loading: updateLoading }] = useMutation(UpdateStsTimestampSuggestionMutation, {
    variables: {
      input: {
        ststimestampSuggestionId: item.id,
        value: itemValue,
      },
    },
    onCompleted() {
      setEditing(false);
    }
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveStsTimestampSuggestionMutation, {
    variables: {
      input: {
        ststimestampSuggestionId: item.id,
      },
    },
    onCompleted() {
      refetch();
    }
  });

  function editHandler() {
    const hasDiff = item.value !== itemValue;

    if (!editing) {
      setEditing(true);
    } else if (editing && hasDiff) {
      executeUpdate();
    } else {
      setEditing(false);
    }
  }

  const isLoading = loading || archiveLoading || updateLoading;

  return (
    <TableRow>
      <TableCell>
        <ToggleInput
          id={`suggestion-${itemValue}`}
          label="Timestamp suggestion"
          variant="outlined"
          onChange={e => setItemValue(e.target.value)}
          value={itemValue}
          type="text"
          fullWidth
          editing={editing}
          noLabel
        />
      </TableCell>
      <TableCell size="small" align="right" style={{ width: '112px' }}>
        <IconButton
          onClick={() => setShowDeleteConfirm(true)}
          disabled={isLoading}
          aria-label="delete item"
        >
          <TrashIcon />
        </IconButton>
        {
          !editing
            ? (
              <IconButton
                onClick={editHandler}
                aria-label="edit item"
              >
                <EditIcon />
              </IconButton>
            )
            : (
              <IconButton
                onClick={editHandler}
                aria-label="save item"
                disabled={isLoading}
              >
                <CheckIcon />
              </IconButton>
            )
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showDeleteConfirm}
          autoHideDuration={6000}
          onClose={() => setShowDeleteConfirm(false)}
          message="Delete this entry?"
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={executeArchive}>
                YES
              </Button>
              <Button color="secondary" size="small" onClick={() => setShowDeleteConfirm(false)}>
                NO
              </Button>
            </React.Fragment>
          }
        />
      </TableCell>
    </TableRow>
  );
}

function TimestampSuggestions() {
  const [newItem, setNewItem] = React.useState('');
  const { data, loading: queryLoading, refetch } = useQuery(StsTimestampSuggestionsQuery, {
    variables: {
      sortBy: ['value asc'],
    },
    fetchPolicy: 'network-only',
  });

  const [executeCreateSuggestion, { loading: createLoading }] = useMutation(CreateStsTimestampSuggestionMutation, {
    variables: {
      input: {
        value: newItem,
      },
    },
    onCompleted() {
      refetch();
      setNewItem('');
    },
  });

  const suggestions = data ? data.ststimestampSuggestions.edges.map(s => s.node) : [];

  return (
    <StyledBlock title="TIMESTAMP SUGGESTIONS">
      <TableContainer>
        <Table aria-label="timestamp suggestions table" style={{ whiteSpace: 'nowrap' }}>
          <TableBody>
            { suggestions.map(item => <Suggestion key={item.id} item={item} refetch={refetch} loading={queryLoading || createLoading} />) }
            <TableRow>
              <TableCell style={{ borderBottom: 0 }}>
                <TextField
                  id="new timestamp suggestion"
                  label="New item"
                  variant="outlined"
                  onChange={e => setNewItem(e.target.value)}
                  value={newItem}
                  type="email"
                  fullWidth
                  multiline
                  rows={4}
                />
              </TableCell>
              <TableCell style={{ borderBottom: 0, width: '112px', verticalAlign: 'bottom' }} align="right">
                <IconButton
                  disabled={queryLoading || createLoading}
                  onClick={executeCreateSuggestion}
                  aria-label="delete client"
                  type="submit"
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
`;

export default TimestampSuggestions;

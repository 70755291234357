import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import Block from 'components/common/block';
import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';
import { ReactComponent as HoseBuonIcon } from 'assets/vectors/hoseBunIcon.svg';

import HoseBunsModal from './hoseBunsModal';

const ArchiveStsProjectEquipment = gql`
  mutation ArchiveStsProjectEquipment($input: ArchiveStsProjectEquipmentInput!) {
    archiveStsProjectEquipment(archiveStsProjectEquipmentData: $input) {
      success
    }
  }
`;

const GetHoseBuns = gql`
  query StsProjectEquipments ($sortBy: [String], $stsprojectId: ID){
    stsprojectEquipments(sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          hoseBun {
            id
            serialNumber
            plNumber
            status
            location
            diameter
            type
            expireDate
          }
        }
      }
    }
  }
`;

function HoseBuns({ projectId, onAdd, allowEdit }) {
  const [showHoseBunsModal, setShowHoseBunsModal] = React.useState(false);

  const {data, refetch} = useQuery(GetHoseBuns, {
    variables: {
      stsprojectId: projectId,
      sortBy: ['created desc']
    },
    onError(e) {
      console.log(e);
    }
  });

  const [executeArchiveStsProjectEquipment, { loading: archiveLoading }] = useMutation(ArchiveStsProjectEquipment, {
    onCompleted() {
      refetch();
    }
  });

  function addHoseBun() {
    setShowHoseBunsModal(true);
  }

  function removeHoseBun(equipmentId) {
    executeArchiveStsProjectEquipment({
      variables: {
        input: {
          stsprojectEquipmentId: equipmentId,
        }
      }
    })
  }

  const projectEquipments = data ? data.stsprojectEquipments.edges.map(r => r.node).filter(r => !!r.hoseBun) : [];
  const addedHoseBuns = projectEquipments.map(r => r.hoseBun.id);

  return (
    <StyledBlock title={<BlockTitle><HoseBuonIcon style={{ marginRight: '8px'}} /> Hose buns</BlockTitle>}>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                PL Nr.
              </TableCell>
              <TableCell>
                Serial Nr.
              </TableCell>
              <TableCell>
                Diameter
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell>
                Type
              </TableCell>
              <TableCell>
                Expire date
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {
              !projectEquipments.length
                ? <TableRow>
                    <TableCell>None added</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                : (
                  projectEquipments.map(equipment => (
                    <TableRow key={equipment.id}>
                      <TableCell>
                        <Link href={`/dashboard/equipment/hoseBuns/${equipment.hoseBun.id}`} target="_blank">{equipment.hoseBun.plNumber}</Link>
                      </TableCell>
                      <TableCell>
                        {equipment.hoseBun.serialNumber}
                      </TableCell>
                      <TableCell>
                        {equipment.hoseBun.diameter}
                      </TableCell>
                      <TableCell>
                        {equipment.hoseBun.location}
                      </TableCell>
                      <TableCell>
                        {equipment.hoseBun.type}
                      </TableCell>
                      <TableCell>
                        {equipment.hoseBun.expireDate ? moment(equipment.hoseBun.expireDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}
                      </TableCell>
                      <TableCell align="right">
                        {
                          allowEdit && (
                            <IconButton
                              onClick={() => removeHoseBun(equipment.id)}
                              aria-label="delete hoseBun"
                              className="deleteButton"
                              disabled={archiveLoading}
                            >
                              <TrashIcon />
                            </IconButton>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        allowEdit && (
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={addHoseBun}
              endIcon={<AddIcon />}
            >
            Add hoseBun
            </Button>
          </ButtonWrapper>
        )
      }
      { showHoseBunsModal && <HoseBunsModal setShowHoseBunsModal={setShowHoseBunsModal} projectId={projectId} refetch={refetch} addedHoseBuns={addedHoseBuns} onAdd={onAdd} /> }
    </StyledBlock>
  );
}

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.textColor};
`;

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
  margin-bottom: 16px;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export default HoseBuns;

import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import useDebounce from 'helpers/useDebounce';

import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';

const filter = createFilterOptions();

const StsVesselsAutocompleteQuery = gql`
  query StsVesselAutocomplete($search: String!){
    stsvesselAutocomplete(search: $search)
  }
`;


const UpdateStsVesselMutation = gql`
  mutation UpdateStsVessel($input: UpdateStsVesselInput!) {
    updateStsVessel(updateStsVesselData: $input) {
      stsvessel {
        id
        name
        type
        captain
        captainEmail
        created
        shipType
      }
    }
  }
`;

const ArchiveStsVesselMutation = gql`
  mutation ArchiveStsVessel($input: ArchiveStsVesselInput!) {
    archiveStsVessel(archiveStsVesselData: $input) {
      success
    }
  }
`;

const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function EditableStsVessel({ vessel, index, refetch }) {
  const vesselRef = React.useRef(null);
  const [type, setType] = React.useState(vessel.type || '');
  const [shipType, setShipType] = React.useState(vessel.shipType || '');
  const [captain, setCaptain] = React.useState(vessel.captain || '');
  const [captainEmail, setCaptainEmail] = React.useState(vessel.captainEmail || '');
  const [emailError, setEmailError] = React.useState(false);

  const [name, setName] = React.useState(vessel.name || '');
  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search, 300);

  const { data: vesselsQueryData, loading: vesselsQueryLoading } = useQuery(StsVesselsAutocompleteQuery, {
    variables: {
      search: debouncedSearch.length ? debouncedSearch : '',
    },
    skip: !debouncedSearch.length,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateStsVessel, { loading: updateLoading }] = useMutation(UpdateStsVesselMutation, {
    variables: {
      input: {
        stsvesselId: vessel.id,
        name,
        type,
        captain,
        captainEmail,
        shipType
      },
    },
  });

  const [executeArchiveStsVessel, { loading: archiveLoading }] = useMutation(ArchiveStsVesselMutation, {
    variables: {
      input: {
        stsvesselId: vessel.id,
      },
    },
    onCompleted() {
      refetch();
    }
  });

  function hasMadeChanges() {
    const currentData = {
      stsvesselId: vessel.id,
      name,
      type,
      captain,
      captainEmail,
      shipType
    };

    const prevData = {
      stsvesselId: vessel.id,
      name: vessel.name,
      type: vessel.type || '',
      shipType: vessel.shipType || '',
      captain: vessel.captain || '',
      captainEmail: vessel.captainEmail || ''
    };

    return !_.isEqual(currentData, prevData);
  }

  const handleClickOutside = (event) => {
    if (vesselRef.current && vesselRef.current.contains(event.target)) {
      return;
    }

    if (archiveLoading || updateLoading || vesselsQueryLoading) {
      return;
    }

    if (emailError) {
      return;
    }
    
    if (hasMadeChanges()) {
      executeUpdateStsVessel();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  function emailInputHandler(val) {
    if (val.match(mailRegex) || !val) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setCaptainEmail(val)
  }

  const vessels = vesselsQueryData ? vesselsQueryData.stsvesselAutocomplete : [];

  return (
    <VesselWrapper key={vessel.id} ref={vesselRef}>
      <VesselLabel>Ship {index + 1}</VesselLabel>
      <VesselInfo>
        <Column>
          <Autocomplete
            value={name}
            // onChange={(event, newValue) => setName(newValue || '')}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setName(newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setName(newValue.inputValue);
              } else {
                setName(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option;
            }}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            inputValue={search}
            onInputChange={(event, newInputValue) => setSearch(newInputValue)}
            id={`vessel-${vessel.id}-name`}
            options={vessels}
            style={{ marginBottom: '32px' }}
            autoHighlight
            noOptionsText="No results"
            renderOption={(option) => (
              <React.Fragment>
                <span>{option}</span>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Ship type</InputLabel>
            <Select
              labelId={`vessel-${vessel.id}-shiptype-label`}
              id={`vessel-${vessel.id}-shiptype`}
              value={shipType}
              onChange={e => setShipType(e.target.value)}
              label="Ship type"
              fullWidth
              style={{ marginBottom: '32px' }}
            >
              <MenuItem value="barge">Barge</MenuItem>
              <MenuItem value="vessel">Vessel</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
            <Select
              labelId={`vessel-${vessel.id}-type-label`}
              id={`vessel-${vessel.id}-type`}
              value={type}
              onChange={e => setType(e.target.value)}
              label="Type"
              fullWidth
            >
              <MenuItem value="discharging">Discharging</MenuItem>
              <MenuItem value="loading">Loading</MenuItem>
            </Select>
          </FormControl>
        </Column>
        <Column>
          <TextField
            id={`vessel-${vessel.id}-captainName`}
            label="Captain"
            variant="outlined"
            onChange={e => setCaptain(e.target.value)}
            value={captain}
            type="text"
            fullWidth
            style={{ marginBottom: '32px' }}
          />
          <TextField
            id={`vessel-${vessel.id}-captainEmail`}
            label="Email"
            variant="outlined"
            onChange={e => emailInputHandler(e.target.value)}
            value={captainEmail}
            type="email"
            error={emailError}
            fullWidth
          />
          <RemoveButtonWrapper>
            <Button
              onClick={executeArchiveStsVessel}
              style={{ textTransform: 'lowercase', margin: '8px 0' }}
              disabled={updateLoading || archiveLoading}
              aria-label="delete ship"
            >
              <span style={{ fontSize: '11px'}}>remove</span>
              <TrashIcon />
            </Button>
          </RemoveButtonWrapper>
        </Column>
      </VesselInfo>
    </VesselWrapper>
  )
}

const VesselWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  margin-bottom: 32px;
`;

const VesselLabel = styled.p`
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.colors.textColor};
  margin-bottom: 16px;
`;

const VesselInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${props => props.theme.breakpoints.laptop}) {
    & > :first-of-type {
      padding-right: 8px;
    }

    & > :last-of-type {
      padding-left: 8px;
    }
  }

  @media (${props => props.theme.breakpoints.tablet}) {
    display: block;
    & > :first-of-type {
      padding-right: 0;
    }

    & > :last-of-type {
      padding-left: 0;
    }
  }
`;

const Column = styled.div`
  width: 376px;
  max-width: 100%;
  margin-bottom: 32px;



  @media (${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default EditableStsVessel;

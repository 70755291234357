import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import Block from 'components/common/block';
import Tag from 'components/common/tag';

const GetHoseBunJobsQuery = gql`
  query StsProjectEquipments ($first: Int, $after: String, $sortBy: [String], $hoseBunId: ID){
    stsprojectEquipments(first: $first, after: $after sortBy: $sortBy, hoseBunId: $hoseBunId) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          customProjectId
          customProduct
          customLocation
          customStartDate
          customEndDate
          stsproject {
            id
            projectId
            product
            location
            startDate
            endDate
            dateStatus
          }
        }
      }
    }
  }
`;

function tagColor(status) {
  switch(status) {
    case 'planned':
      return 'yellow';
    case 'finished':
      return 'blue';
    default:
      return 'green'
  }
}

function JobLog({ id }) {
  const { data, error, loading } = useQuery(GetHoseBunJobsQuery, {
    variables: {
      hoseBunId: id,
      first: 5,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.stsprojectEquipments) return null;
  const jobs = data.stsprojectEquipments.edges.map(j => j.node);

  return (
    <StyledBlock title="JOB LOG">
      <TableContainer>
        <Table style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Project ID
              </TableCell>
              <TableCell>
                Product
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell>
                Start Date
              </TableCell>
              <TableCell>
                End Date
              </TableCell>
              <TableCell>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !jobs.length
                ? <TableRow>
                    <TableCell>No results</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                : (
                  jobs.map((job) => (
                    <TableRow key={job.id}>
                      {
                        job.stsproject
                          ? <>
                              <TableCell>
                                <Link to={`/dashboard/sts/${job.stsproject.id}`}>{job.stsproject.projectId}</Link>
                              </TableCell>
                              <TableCell>
                                {job.stsproject.product}
                              </TableCell>
                              <TableCell>
                                {job.stsproject.location}
                              </TableCell>
                              <TableCell>
                                {moment(job.stsproject.startDate).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell>
                                {moment(job.stsproject.endDate).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell>
                                <Tag color={tagColor(job.stsproject.dateStatus)} label={job.stsproject.dateStatus} />
                              </TableCell>
                            </>
                          : <>
                              <TableCell>{job.customProjectId}</TableCell>
                              <TableCell>{job.customProduct}</TableCell>
                              <TableCell>{job.customLocation}</TableCell>
                              <TableCell>{moment(job.customStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                              <TableCell>{moment(job.customEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                              <TableCell />
                            </>
                      }
                      
                    </TableRow>
                  ))
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBlock>
  )
}

const StyledBlock = styled(Block)`
  max-width: 100%;
  margin-bottom: 16px;
`;

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default JobLog;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import Block from 'components/common/block';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const AddHoseMutation = gql`
  mutation CreateHose($input: CreateHoseInput!) {
    createHose(createHoseData: $input) {
      hose {
        id
      }
    }
  }
`;

function NewHose() {
  const [plNumber, setPlNumber] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [diameter, setDiameter] = React.useState(0);
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [flange, setFlange] = React.useState('');
  const [type, setType] = React.useState('');
  const [hoseType, setHoseType] = React.useState('');
  const [manufacturer, setManufacturer] = React.useState('');
  const [color, setColor] = React.useState('');
  const [testDate, setTestDate] = React.useState(null);
  const [expireDate, setExpireDate] = React.useState(null);
  const [manufactureDate, setManufactureDate] = React.useState(null);
  const [remark, setRemark] = React.useState('');
  const [totalThroughput, setTotalThroughput] = React.useState(0);
  const [hoursInUse, setHoursInUse] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [addedSnackbarOpen, setAddedSnackbarOpen] = React.useState(false);

  const {statusValues} = useEquipmentStatus();

  const [addHose, { loading }] = useMutation(AddHoseMutation, {
    variables: {
      input: {
        serialNumber,
        diameter: diameter || 0,
        length: length || 0,
        location,
        plNumber,
        status,
        flange,
        type,
        hoseType,
        manufacturer,
        color,
        expireDate: expireDate ? moment(expireDate).format('YYYY-MM-DD') : null,
        testDate: testDate ? moment(testDate).format('YYYY-MM-DD') : null,
        manufactureDate: manufactureDate ? moment(manufactureDate).format('YYYY-MM-DD') : null,
        remark,
        totalThroughput: totalThroughput || 0,
        hoursInUse: hoursInUse || 0,
        weight: weight || 0,
      },
    },
    onCompleted() {
      setSerialNumber('');
      setDiameter(0);
      setLength(0);
      setLocation('');
      setPlNumber('');
      setStatus('');
      setFlange('');
      setType('');
      setHoseType('');
      setManufacturer('');
      setColor('');
      setTestDate(null);
      setExpireDate(null);
      setManufactureDate(null);
      setRemark('');
      setTotalThroughput(0)
      setHoursInUse(0)
      setWeight(0)
      setAddedSnackbarOpen(true);
    },
    onError(e) {
      console.log(e)
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAddedSnackbarOpen(false);
  };

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'},
    {id: 'diameter', label: 'Diameter in inches', onChange: setDiameter, value: diameter, type: 'number'}, 
    {id: 'length', label: 'Length in meters', onChange: setLength, value: length, type: 'number'}, 
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'flange', label: 'Flange', onChange: setFlange, value: flange, type: 'text'}, 
    {id: 'type', label: 'Type', onChange: setType, value: type, type: 'text'}, 
    {id: 'hoseType', label: 'Hose type', onChange: setHoseType, value: hoseType, type: 'text'},
    {id: 'manufacturer', label: 'Manufacturer', onChange: setManufacturer, value: manufacturer, type: 'text'},
    {id: 'color', label: 'Color', onChange: setColor, value: color, type: 'text'},
    {id: 'testDate', label: 'Test date', onChange: setTestDate, value: testDate, type: 'date'}, 
    {id: 'expireDate', label: 'Expire date', onChange: setExpireDate, value: expireDate, type: 'date'}, 
    {id: 'manufactureDate', label: 'Manufacture date', onChange: setManufactureDate, value: manufactureDate, type: 'date'}, 
    {id: 'totalThroughput', label: 'Total throughput in Mtons', onChange: setTotalThroughput, value: totalThroughput, type: 'number'}, 
    {id: 'hoursInUse', label: 'Total hours in use', onChange: setHoursInUse, value: hoursInUse, type: 'number'}, 
    {id: 'remark', label: 'Remark', onChange: setRemark, value: remark, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading;

  return (
    <StyledBlock title="ADD HOSE">
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} forceEdit />)
        }
      </Grid>
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={addHose}
          disabled={disabled}
        >
          Add hose
        </Button>
      </ButtonWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={addedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Hose successfully added"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  max-width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export default NewHose;

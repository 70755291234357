import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { ReactComponent as PlusIcon } from 'assets/vectors/add-white-16px.svg';
import ActionBar from 'components/common/actionBar';
import Block from 'components/common/block';

import Filters from 'components/common/equipment/filters';
import MiscellanyTable from './miscellanyTable';

const MiscellaniesQuery = gql`
  query Miscellanies ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $status: String, $location: String){
    miscellanies(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, status: $status, location: $location) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          plNumber
          location
          status
          contents
          lastProducts
        }
      }
    }
  }
`;

const resultCount = 10;

function Miscellanies() {
  const history = useHistory();
  const urlParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  
  function paginationDefaultData() {
    if (urlParams.after) {
      return { first: resultCount, after: urlParams.after };
    } else if (urlParams.before) {
      return { last: resultCount, before: urlParams.before };
    } else {
      return { first: resultCount };
    }
  }

  function sortByDefaultData() {
    if (urlParams.sortBy) {
      return { type: urlParams.sortBy.split('.')[0], direction: urlParams.sortBy.split('.')[1]};
    } else {
      return { type: 'plNumber', direction: 'asc' };
    }
  }

  function filtersDefaultData() {
    if (urlParams.filters) {
      const filterObj = JSON.parse(urlParams.filters);
      const arr = Object.keys(filterObj).map(key => {
        return {
          key,
          val: filterObj[key],
        }
      });
      return arr
    } else {
      return [];
    }
  }

  const [sortBy, setSortBy] = React.useState(sortByDefaultData());
  const [paginationVariables, setPaginationVariables] = React.useState(paginationDefaultData());
  const [filters, setFilters] = React.useState(filtersDefaultData());
  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  const filterVariables = React.useMemo(() => {
    return filters.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.key]: cur.val,
      }
    }, {});
  }, [filters]);

  const { data, loading } = useQuery(MiscellaniesQuery, {
    variables: {
      ...paginationVariables,
      ...filterVariables,
      sortBy: [`${sortBy.type} ${sortBy.direction}`]
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    const newFilterObj = {};
    filters.forEach(filter => {
      newFilterObj[filter.key] = filter.val;
    })

    const params = {
      filters: Object.keys(newFilterObj).length ? JSON.stringify(newFilterObj) : undefined,
      after: paginationVariables.after || undefined,
      first: paginationVariables.first || undefined,
      last: paginationVariables.last || undefined,
      before: paginationVariables.before || undefined,
      sortBy: `${sortBy.type}.${sortBy.direction}`,
    };

    window.history.replaceState(null, "", `/dashboard/equipment/miscellaneous${qs.stringify(params, {addQueryPrefix: true})}`);
  }, [filters, paginationVariables, sortBy]);

  const miscellanies = data ? data.miscellanies.edges.map(u => u.node) : [];
  const pageInfo = data ? data.miscellanies.pageInfo : {};

  return (
    <>
      {
        isAdmin && (
          <StyledActionBar>
            <StyledLink to="/dashboard/equipment/miscellaneous/new">
              <Button
                variant="contained"
                color="primary"
                endIcon={<PlusIcon />}
              >
                Add miscellaneous 
              </Button>
            </StyledLink>
          </StyledActionBar>
        )
      }
      <Block noTitle>
      <Filters filters={filters} setFilters={setFilters} equipmentType="miscellany" />
        {
          loading
            ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
            : <MiscellanyTable
                miscellanies={miscellanies}
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
        }
      </Block>
    </>
  );
}

const StyledActionBar = styled(ActionBar)`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: block;
`;

export default Miscellanies;

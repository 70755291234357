import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as STSIcon } from 'assets/vectors/vessels-16px.svg';
import { ReactComponent as UserIcon } from 'assets/vectors/users-16px.svg';
import { ReactComponent as DashboardIcon } from 'assets/vectors/dashboard-24px.svg';
import { ReactComponent as FenderIcon } from 'assets/vectors/fenderIcon.svg';
import { ReactComponent as ReducerIcon } from 'assets/vectors/reducerIcon.svg';
import { ReactComponent as HoseIcon } from 'assets/vectors/hoseIcon.svg';
import { ReactComponent as HoseBunIcon } from 'assets/vectors/hoseBunIcon.svg';
import { ReactComponent as InsulationFlangeIcon } from 'assets/vectors/insulationFlangeIcon.svg';
import { ReactComponent as StsBoxIcon } from 'assets/vectors/stsBoxIcon.svg';
import { ReactComponent as MiscIcon } from 'assets/vectors/miscIcon.svg';
/* import { ReactComponent as SettingsIcon } from 'assets/vectors/settings-16px.svg'; */
import { SET_MENU_OPEN } from 'redux/reducers/settings';

import Section from './section';
import SectionItem from './sectionItem';
import ChildlessSectionItem from './childlessSectionItem';

function SideMenu() {
  const location = useLocation()
  const dispatch = useDispatch();
  const menuRef = React.useRef(null);
  const menuOpen = useSelector(state => state.settings.menuOpen);
  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  const handleClickOutside = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }

    dispatch({
      type: SET_MENU_OPEN,
      payload: {
        menuOpen: false,
      }
    });
  };

  React.useEffect(() => {
    dispatch({
      type: SET_MENU_OPEN,
      payload: {
        menuOpen: false,
      }
    });
  }, [location.pathname, dispatch])

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const stsRoutes = [{
    id: 1,
    path: '/sts',
    label: 'Overview',
  }]

  if (isAdmin) {
    stsRoutes.push({
      id: 2,
      path: '/sts/new',
      label: 'Add project',
    })
    stsRoutes.push({
      id: 3,
      path: '/sts/settings',
      label: 'STS settings',
    })
  }

  const userRoutes = [{
    id: 1,
    path: '/users',
    label: 'Overview',
  }]

  if (isAdmin) {
    userRoutes.push({
      id: 2,
      path: '/users/new',
      label: 'Add user',
    })
  }

  return (
    <Wrapper menuOpen={menuOpen} ref={menuRef}>
      <ChildlessSectionItem
        itemTitle="Dashboard"
        itemIcon={<DashboardIcon />}
        itemPath="/"
      />
      <Section title="services">
        <SectionItem
          itemTitle="Ship To Ship Transfer"
          itemPath="/sts"
          itemIcon={<STSIcon />}
          itemOptions={stsRoutes}
        />
      </Section>
      <Section title="general">
        <SectionItem
          itemTitle="Users"
          itemPath="/users"
          itemIcon={<UserIcon />}
          itemOptions={userRoutes}
        />
      </Section>
      <Section title="Equipment">
        <ChildlessSectionItem
          itemTitle="Reducers"
          itemIcon={<ReducerIcon />}
          itemPath="/equipment/reducers"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="Fenders"
          itemIcon={<FenderIcon />}
          itemPath="/equipment/fenders"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="Hoses"
          itemIcon={<HoseIcon />}
          itemPath="/equipment/hoses"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="Hose buns"
          itemIcon={<HoseBunIcon />}
          itemPath="/equipment/hoseBuns"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="Insulation flanges"
          itemIcon={<InsulationFlangeIcon />}
          itemPath="/equipment/insulationFlanges"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="STS boxes"
          itemIcon={<StsBoxIcon />}
          itemPath="/equipment/stsBoxes"
          secondary
        />
        <ChildlessSectionItem
          itemTitle="Miscellaneous"
          itemIcon={<MiscIcon />}
          itemPath="/equipment/miscellaneous"
          secondary
        />
      </Section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 48px);
  width: 240px;
  background: white;
  z-index: 0;
  flex: none;
  position: relative;
  white-space:nowrap;
  overflow-x: scroll;

  @media (${props => props.theme.breakpoints.tablet}) {
    transition: all 0.1s ease;
    position: absolute;
    left: 0;
    width: ${props => !props.menuOpen && 0};
    z-index: 1;
    background: white;
    box-shadow: 0px 3px 2px #00000029;
  }
`;

export default SideMenu;

import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import Entry from './entry';

function List({ id, timestamps, loading, refetchList, allowEdit, preview }) {
  if (loading) return <CircularProgress size={64} style={{ margin: '64px auto', display: 'block' }} />;
  if (!timestamps.length) return <NoResults>No entries yet</NoResults>
  return (
    <Wrapper preview={preview}>
      {
        timestamps.map((t, i) => (
          <Entry
            key={t.id}
            entry={t}
            index={i}
            refetchList={refetchList}
            allowEdit={allowEdit}
            isFirst={i === 0}
            isLast={i === timestamps.length - 1}
            preview={preview}
          />
        ))
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: ${props => props.preview ? '416px' : 'calc(50% - 12px)'};
  box-shadow: 0px 2px 6px #00000029;

  @media (${props => props.theme.breakpoints.tablet}) {
   width: ${props => !props.preview && '100%'};
   margin-bottom: 64px;
  }
`;

const NoResults = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  margin: 16px;
`;

export default List;
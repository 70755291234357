import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tag from 'components/common/tag';
import Pagination from 'components/common/pagination';
import Modal from 'components/common/modal';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';
import useEquipmentStatus from 'helpers/useEquipmentStatus';
import Filters from 'components/common/equipment/filters';
import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';

const ReducersQuery = gql`
  query Reducers ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $status: String, $length: Float, $material: String, $location: String, $aConnection: Float, $aFlange: String, $bConnection: Float, $bFlange: String){
    reducers(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, status: $status, length: $length, material: $material, location: $location, aConnection: $aConnection, aFlange: $aFlange, bConnection: $bConnection, bFlange: $bFlange) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          plNumber
          status
          length
          material
          location
          aConnection
          aFlange
          bConnection
          bFlange
          serialNumber
          remark
          lastProducts
        }
      }
    }
  }
`;

const AddReducersMutation = gql`
  mutation AddStsProjectEquipment($input: AddStsProjectEquipmentInput!) {
    addStsProjectEquipment(addStsProjectEquipmentData: $input) {
      success
    }
  }
`;

const resultCount = 10;

function ReducersModal({ setShowReducersModal, projectId, refetch, addedReducers, onAdd }) {
  const [selectedReducers, setSelectedReducers] = React.useState([]);
  const [paginationVariables, setPaginationVariables] = React.useState({first: resultCount});
  const {tagColor, equipmentStatusLabel} = useEquipmentStatus();
  const [filters, setFilters] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({ type: 'plNumber', direction: 'asc' });

  const filterVariables = React.useMemo(() => {
    return filters.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.key]: cur.val,
      }
    }, {});
  }, [filters]);

  const { data, loading: listLoading } = useQuery(ReducersQuery, {
    variables: {
      ...paginationVariables,
      ...filterVariables,
      sortBy: [`${sortBy.type} ${sortBy.direction}`]
    },
    fetchPolicy: 'network-only',
  });

  const [addReducers, { loading: addLoading }] = useMutation(AddReducersMutation, {
    variables: {
      input: {
        stsprojectId: projectId,
        equipmentIds: selectedReducers,
        equipmentType: 'reducer',
      },
    },
    onCompleted() {
      refetch();
      onAdd && onAdd();
      setShowReducersModal(false);
    },
    onError(e) {
      console.log(e)
    }
  });

  const reducers = data ? data.reducers.edges.map(u => u.node) : [];
  const pageInfo = data ? data.reducers.pageInfo : {};

  function checkboxHandler(reducerId) {
    if (selectedReducers.includes(reducerId)) {
      setSelectedReducers(selectedReducers.filter(id => id !== reducerId));
    } else {
      setSelectedReducers([...selectedReducers, reducerId]);
    }
  }

  function checkboxDisabled(reducer) {
    return addedReducers.includes(reducer.id) || reducer.status !== 'stand_by';
  }

  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }

  const addButtonDisabled = listLoading || addLoading || !selectedReducers.length

  return (
    <Modal onClose={() => setShowReducersModal(false)} title="REDUCERS" style={{ width: '90%'}} preventOutsideClick>
      <Title>ADD REDUCERS</Title>
      <Filters filters={filters} setFilters={setFilters} equipmentType="reducer" />
      {
        listLoading
          ? <CircularProgress size={64} style={{ margin: '128px auto', display: 'block' }} />
          : (
            <>
              <TableContainer>
                <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('plNumber')}>
                        PL Nr. <StyledOrderIcon active={sortBy.type === 'plNumber' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('serialNumber')}>
                        Length <StyledOrderIcon active={sortBy.type === 'serialNumber' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('material')}>
                        Material <StyledOrderIcon active={sortBy.type === 'material' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('aConnection')}>
                        Connection A <StyledOrderIcon active={sortBy.type === 'aConnection' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('aFlange')}>
                        Flange A <StyledOrderIcon active={sortBy.type === 'aFlange' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('bConnection')}>
                        Connection B <StyledOrderIcon active={sortBy.type === 'bConnection' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('bFlange')}>
                        Flange B <StyledOrderIcon active={sortBy.type === 'bFlange' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('location')}>
                        Location <StyledOrderIcon active={sortBy.type === 'location' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('status')}>
                        Status <StyledOrderIcon active={sortBy.type === 'status' ? 1 : 0} direction={sortBy.direction} />
                      </TableCell>
                      <TableCell>
                        Last products
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    !reducers.length
                      ? <TableRow>
                          <TableCell>No results</TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      : (
                        reducers.map(reducer => (
                          <TableRow key={reducer.id}>
                            <TableCell>
                              <Checkbox
                                checked={selectedReducers.includes(reducer.id)}
                                onChange={() => checkboxHandler(reducer.id)}
                                name="Now"
                                color="primary"
                                disabled={checkboxDisabled(reducer)}
                              />
                            </TableCell>
                            <TableCell>
                              <Link target="_blank" href={`/dashboard/equipment/reducers/${reducer.id}`}>{reducer.plNumber}</Link>
                            </TableCell>
                            <TableCell>
                              {reducer.length}
                            </TableCell>
                            <TableCell>
                              {reducer.material}
                            </TableCell>
                            <TableCell>
                              {reducer.aConnection}
                            </TableCell>
                            <TableCell>
                              {reducer.aFlange}
                            </TableCell>
                            <TableCell>
                              {reducer.bConnection}
                            </TableCell>
                            <TableCell>
                              {reducer.bFlange}
                            </TableCell>
                            <TableCell>
                              {reducer.location}
                            </TableCell>
                            <TableCell>
                              <Tag color={tagColor(reducer.status)} label={equipmentStatusLabel(reducer.status)} />
                            </TableCell>
                            <TableCell>
                              {reducer.lastProducts}
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                resultCount={resultCount}
                pageInfo={pageInfo}
              />
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addReducers}
                  endIcon={<AddIcon />}
                  disabled={addButtonDisabled}
                >
                Add
                </Button>
              </ButtonWrapper>
            </>
          )
      }
    </Modal>
  );
}

const Link = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default ReducersModal;

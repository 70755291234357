export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';

const initialState = {
  loggedIn: null,
  user: null,
};

export default function auth(state = initialState, action: Object) {
  switch (action.type) {
  case LOGOUT:
    localStorage.removeItem('token');
    return {
      loggedIn: false,
      user: null,
    };
  case LOGIN:
    localStorage.setItem('token', action.payload.token);
    return {
      loggedIn: true,
      user: action.payload.user,
    };
  case UPDATE_AVATAR:
    return {
      ...state,
      user: {
        ...state.user,
        imageUrl: action.payload.imageUrl,
      },
    };
  default:
    return {
      ...state,
    };
  }
}

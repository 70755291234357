import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-16px.svg';

function InnerModal({ children, onClose, preventOutsideClick, style }) {
  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && !preventOutsideClick) {
      onClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <InnerModalWrapper ref={ref} style={style}>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
      {children}
    </InnerModalWrapper>
  );
}

function Modal ({ children, onClose, preventOutsideClick, style }) {
  return (
    <Wrapper>
      <InnerModal onClose={onClose} preventOutsideClick={preventOutsideClick} style={style}>
        {children}
      </InnerModal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const InnerModalWrapper = styled.div`
  width: 800px;
  max-width: 90%;
  max-height: 75%;
  overflow: scroll;
  background: white;
  position: relative;
  padding: 24px;
  box-shadow: 0px 2px 6px #00000029;
  overflow-x: hidden;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;

export default Modal;

import React from 'react';

import TimestampSuggestions from './timestampSuggestions';

function STSSettings() {
  return (
    <>
      <TimestampSuggestions />
    </>
  );
}

export default STSSettings;

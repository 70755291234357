import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as SendIcon } from 'assets/vectors/send-16px.svg';
import { ReactComponent as SendIconGrey } from 'assets/vectors/send-grey-16px.svg';
import { ReactComponent as DownloadIcon } from 'assets/vectors/download-green-16px.svg';
import { ReactComponent as DownloadIconGrey } from 'assets/vectors/download-grey-16px.svg';
import { ReactComponent as AddIcon } from 'assets/vectors/add-white-16px.svg';

function Nav({ view, setView, setShowReportModal, hasTimestamps, timestamps }) {
  const topRef = React.useRef()

  const parsedTimestamps = timestamps.map(t => ({
    date: moment(t.localDatetime).format('DD/MM/YYYY'),
    time: moment(t.localDatetime).format('HH:mm'),
    description: t.description,
    location: t.location || '-'
  })).reverse();

  function scrollTop() {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Wrapper style={{ marginBottom: '16px' }} ref={topRef}>
      <Group>
        <Option active={view === 'general'} onClick={() => setView('general')}>General</Option>
        <Option active={view === 'timeline'} onClick={() => setView('timeline')}>Timeline</Option>
        <Option active={view === 'equipment'} onClick={() => setView('equipment')}>Equipment</Option>
      </Group>
      {
        view === 'timeline' && (
          <Group hideOnMobile>
            <Button
              variant="outlined"
              color="primary"
              disabled={!hasTimestamps}
              endIcon={<DownloadIcon />}
              style={{ marginRight: '8px', textDecoration: 'none' }}
            >
              <CSVLink
                data={parsedTimestamps}
                filename="export.csv"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Download report
              </CSVLink>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!hasTimestamps}
              endIcon={<SendIcon />}
              onClick={() => setShowReportModal(true)}
            >
              Send report
            </Button>
          </Group>
        )
      }
      {
        view === 'timeline' && (
          <MobileBar>
            <IconButton
              variant="contained"
              color="primary"
              onClick={scrollTop}
              style={{
                backgroundColor: '#48AC41',
                height: '56px',
                width: '56px',
                position: 'absolute',
                left: '50%',
                transform: 'translateY(-50%) translateX(-50%)'
              }}
            >
              <AddIcon />
            </IconButton>
            <MobileButtons>
              <IconButton
                variant="contained"
                color="primary"
                disabled={!hasTimestamps}
                style={{ padding: 0 }}
              >
                <CSVLink
                  data={parsedTimestamps}
                  filename="timesheet.csv"
                  style={{ textDecoration: 'none', padding: '12px' }}
                >
                  <DownloadIconGrey />
                </CSVLink>
              </IconButton>
              <IconButton
                variant="contained"
                color="primary"
                disabled={!hasTimestamps}
                onClick={() => setShowReportModal(true)}
              >
                <SendIconGrey />
              </IconButton>
            </MobileButtons>
          </MobileBar>
        )
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Group = styled.div`
  display: flex;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: ${props => props.hideOnMobile ? 'none' : 'flex'};
  }
`;

const Option = styled.div`
  text-transform: uppercase;
  color: ${props => props.active ? props.theme.colors.primaryColor : props.theme.colors.textColor};
  border-bottom: 2px solid ${props => props.active ? props.theme.colors.primaryColor : 'transparent'};
  font-weight: 700;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 72px;
`;

const MobileBar = styled.div`
  background: #F9FCF9 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  align-items: center;

  & svg {
    height: 24px;
    width: 24px
  }

  @media (${props => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const MobileButtons = styled.div`
  margin-left: auto;
`;

export default Nav;

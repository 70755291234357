import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';

const GetDocumentQuery = gql`
  query GetDocument($id: ID!) {
    node(id: $id) {
      ... on Document {
        id
        filename
        documentUrl
      }
    }
  }
`;

function File({ file, removeFile, noResults, showDelete, uploadDate }) {
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = React.useState(false);

  const [executeGetDocument] = useLazyQuery(GetDocumentQuery, {
    onCompleted(result) {
      window.open(result.node.documentUrl)
    },
  });

  function getFile(id) {
    executeGetDocument({
      variables: {
        id,
      }
    })
  }

  if (noResults) {
    return (
      <FileRow>
        <FileLabel noResults>
          None
        </FileLabel>
      </FileRow>
    )
  }

  return (
    <>
      <FileRow>
        <FileData>
          <FileLabel onClick={() => getFile(file.id)}>
            {file.filename}
          </FileLabel>
          {
            uploadDate && <p>{uploadDate}</p>
          }
        </FileData>
        {
          showDelete && (
            <IconButton style={{ marginLeft: '16px' }} size="small" aria-label="close" color="inherit" onClick={() => setDeleteConfirmationVisible(true)}>
              <TrashIcon fontSize="small" />
            </IconButton>
          )
        }
      </FileRow>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={deleteConfirmationVisible}
        autoHideDuration={6000}
        onClose={() => setDeleteConfirmationVisible(false)}
        message="Delete this file?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => removeFile(file.id)}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setDeleteConfirmationVisible(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
}

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px 0;
`;

const FileData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // margin-right: 16px;
`;

const FileLabel = styled.p`
  color: ${props => props.noResults ? props.theme.colors.textColor : props.theme.colors.primaryColor};
  cursor: ${props => !props.noResults && 'pointer'};
  font-weight: ${props => !props.noResults && 500};
`;

export default File;

import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import Block from 'components/common/block';

const GetHoseMaintenanceQuery = gql`
  query EquipmentLog ($first: Int, $after: String, $last: Int, $before: String, $sortBy: [String], $fenderId: ID){
    equipmentLogs(first: $first, after: $after, last: $last, before: $before, sortBy: $sortBy, fenderId: $fenderId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          timestamp
          location
          remarks
          attachedDocuments {
            id
          }
          createdByUser {
            id
            fullName
          }
        }
      }
    }
  }
`;

function Maintenance({ id }) {
  const { data, error, loading} = useQuery(GetHoseMaintenanceQuery, {
    variables: {
      fenderId: id,
      first: 5,
      sortBy: ['timestamp desc']
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });
  
  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.equipmentLogs) return null;

  const maintenances = data.equipmentLogs.edges.map(j => j.node);
  return (
    <StyledBlock title="MAINTENANCE AND INSPECTIONS LOG">
      <TableContainer>
        <Table style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell>
                Signature
              </TableCell>
              <TableCell>
                Remarks
              </TableCell>
              <TableCell>
                Files
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !maintenances.length
                ? <TableRow>
                    <TableCell>No results</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                : (
                  maintenances.map((maintenance) => (
                    <TableRow key={maintenance.id}>
                      <TableCell>
                        {moment(maintenance.timestamp).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {maintenance.location}
                      </TableCell>
                      <TableCell>
                        {maintenance.createdByUser.fullName}
                      </TableCell>
                      <TableCell style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '300px'}}>
                        {maintenance.remarks}
                      </TableCell>
                      <TableCell>
                        {`${maintenance.attachedDocuments.length} file${maintenance.attachedDocuments.length !== 1 ? 's' : ''}`}
                      </TableCell>
                    </TableRow>
                  ))
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBlock>
  )
}

const StyledBlock = styled(Block)`
  max-width: 100%;
  margin-bottom: 16px;
`;

export default Maintenance;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as CloseIcon } from 'assets/vectors/close-white-16px.svg';
import AuthForm from 'components/common/authForm';
import Block from 'components/common/block';

const RESET_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(resetPasswordData: $input) {
      success
    }
  }
`;

function Reset() {
  let history = useHistory();
  const token = history.location.pathname.split('/reset/')[1];
  const [password, setPassword] = React.useState('');
  const [resetSnackbarOpen, setResetSnackbarOpen] = React.useState(false);

  const [executeReset, { loading, data }] = useMutation(RESET_MUTATION, {
    variables: {
      input: {
        token,
        password,
      },
    },
    onCompleted(result) {
      setResetSnackbarOpen(true);
    },
    onError(e) {

    }
  });

  React.useEffect(() => {
    if (data) {
      setTimeout(() => {
        history.push('/');
      }, 3000);
    }
  }, [data, history]);

  function submitHandler(e) {
    e.preventDefault();
    executeReset();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setResetSnackbarOpen(false);
  };

  const isDisabled = loading || !password.length || !!data;

  return (
    <AuthForm onSubmit={submitHandler}>
      <Block title="RESET PASSWORD">
        <TextField
          id="password"
          label="New password"
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
          value={password}
          type="password"
          autoComplete="new-password"
          fullWidth
          style={{ marginBottom: '32px' }}
        />
        <ButtonWrapper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Submit
          </Button>
        </ButtonWrapper>
      </Block>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={resetSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your password has been reset."
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </AuthForm>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default Reset;

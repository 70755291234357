import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/vectors/logo.svg';
import { ReactComponent as Menu } from 'assets/vectors/menu-16px.svg';
import { ReactComponent as MenuClose } from 'assets/vectors/menu-open-16px.svg';
import { SET_MENU_OPEN } from 'redux/reducers/settings';

import LoggedInUser from './loggedInUser';

function TopBar() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.auth.user);
  const menuOpen = useSelector(state => state.settings.menuOpen);

  function menuButtonHandler() {
    dispatch({
      type: SET_MENU_OPEN,
      payload: {
        menuOpen: !menuOpen,
      }
    });
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <MenuButtonWrapper role="button" onClick={menuButtonHandler}>
          { menuOpen ? <MenuClose /> : <Menu /> }
        </MenuButtonWrapper>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </LogoWrapper>
      { loggedIn && <LoggedInUser user={user} /> }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 48px;
  background: white;
  box-shadow: 0px 2px 6px #00000029;
  padding: 12px 24px;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${props => props.theme.breakpoints.tablet}) {
    padding-left: 8px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuButtonWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  margin-right: 8px;
  cursor: pointer;

  @media (${props => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export default TopBar;

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

let defaultEnv = {
  api: "__API_URL__"
}

if (window.location.host.startsWith("localhost:")) {
  defaultEnv = {
    api: "http://localhost:6543"
  }
}

const cache = new InMemoryCache();
const link = createUploadLink({
  uri: `${defaultEnv.api}/graphql`,
});

const errorLink = onError(({ graphQLErrors }) => {

});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  const newHeaders = {
    ...headers,
  };

  if (token) {
    newHeaders.authorization = `JWT ${token}`;
  }

  return {
    headers: newHeaders,
  };
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(link)),
  cache,
});

export default client;

export const SET_MENU_OPEN = 'SET_MENU_OPEN';

const initialState = {
  menuOpen: false,
};

export default function settings(state = initialState, action: Object) {
  switch (action.type) {
  case SET_MENU_OPEN:
    return {
      ...state,
      menuOpen: action.payload.menuOpen,
    };
  default:
    return {
      ...state,
    };
  }
}

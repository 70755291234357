import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetHoseQuery = gql`
  query GetHose($id: ID!) {
    node(id: $id) {
      ... on Hose {
        id
        plNumber
        status
        diameter
        length
        location
        flange
        type
        hoseType
        manufacturer
        color
        testDate
        expireDate
        manufactureDate
        totalThroughput
        hoursInUse
        serialNumber
        remark
        weight
      }
    }
  }
`;

const UpdateHoseMutation = gql`
  mutation UpdateHose($input: UpdateHoseInput!) {
    updateHose(updateHoseData: $input) {
      hose {
        id
      }
    }
  }
`;

const ArchiveHoseMutation = gql`
  mutation ArchiveHose($input: ArchiveHoseInput!) {
    archiveHose(archiveHoseData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [plNumber, setPlNumber] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [diameter, setDiameter] = React.useState(0);
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [flange, setFlange] = React.useState('');
  const [type, setType] = React.useState('');
  const [hoseType, setHoseType] = React.useState('');
  const [manufacturer, setManufacturer] = React.useState('');
  const [color, setColor] = React.useState('');
  const [testDate, setTestDate] = React.useState(null);
  const [expireDate, setExpireDate] = React.useState(null);
  const [manufactureDate, setManufactureDate] = React.useState(null);
  const [remark, setRemark] = React.useState('');
  const [totalThroughput, setTotalThroughput] = React.useState(0);
  const [hoursInUse, setHoursInUse] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);

  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetHoseQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const hose = result.node;
      if (!hose) return;

      if (hose.serialNumber) setSerialNumber(hose.serialNumber);
      if (hose.diameter) setDiameter(hose.diameter);
      if (hose.length) setLength(hose.length);
      if (hose.location) setLocation(hose.location);
      if (hose.plNumber) setPlNumber(hose.plNumber);
      if (hose.status) setStatus(hose.status);
      if (hose.flange) setFlange(hose.flange);
      if (hose.type) setType(hose.type);
      if (hose.hoseType) setHoseType(hose.hoseType);
      if (hose.manufacturer) setManufacturer(hose.manufacturer);
      if (hose.color) setColor(hose.color);
      if (hose.testDate) setTestDate(hose.testDate);
      if (hose.expireDate) setExpireDate(hose.expireDate);
      if (hose.manufactureDate) setManufactureDate(hose.manufactureDate);
      if (hose.remark) setRemark(hose.remark);
      if (hose.totalThroughput) setTotalThroughput(hose.totalThroughput);
      if (hose.hoursInUse) setHoursInUse(hose.hoursInUse);
      if (hose.weight) setWeight(hose.weight);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateHoseMutation, {
    variables: {
      input: {
        hoseId: id,
        serialNumber,
        diameter: diameter || 0,
        length: length || 0,
        location,
        plNumber,
        status,
        flange,
        type,
        hoseType,
        manufacturer,
        color,
        expireDate: expireDate ? moment(expireDate).format('YYYY-MM-DD') : null,
        testDate: testDate ? moment(testDate).format('YYYY-MM-DD') : null,
        manufactureDate: manufactureDate ? moment(manufactureDate).format('YYYY-MM-DD') : null,
        remark,
        totalThroughput: totalThroughput || 0,
        hoursInUse: hoursInUse || 0,
        weight: weight || 0,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveHoseMutation, {
    variables: {
      input: {
        hoseId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/hoses');
    },
    onError: (e) => {
      console.log(e)
    }
  });

  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateHose.hose : data.node;

    setSerialNumber(lastSaved.serialNumber);
    setDiameter(lastSaved.diameter);
    setLength(lastSaved.length);
    setLocation(lastSaved.location);
    setPlNumber(lastSaved.plNumber);
    setStatus(lastSaved.status);
    setFlange(lastSaved.flange);
    setType(lastSaved.type);
    setHoseType(lastSaved.hoseType);
    setManufacturer(lastSaved.manufacturer);
    setColor(lastSaved.color);
    setTestDate(lastSaved.testDate);
    setExpireDate(lastSaved.expireDate);
    setManufactureDate(lastSaved.manufactureDate);
    setRemark(lastSaved.remark);
    setTotalThroughput(lastSaved.totalThroughput);
    setHoursInUse(lastSaved.hoursInUse);
    setWeight(lastSaved.weight);
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'},
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'},
    {id: 'diameter', label: 'Diameter in inches', onChange: setDiameter, value: diameter, type: 'number'}, 
    {id: 'length', label: 'Length in meters', onChange: setLength, value: length, type: 'number'},
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'flange', label: 'Flange', onChange: setFlange, value: flange, type: 'text'}, 
    {id: 'type', label: 'Type', onChange: setType, value: type, type: 'text'}, 
    {id: 'hoseType', label: 'Hose type', onChange: setHoseType, value: hoseType, type: 'text'},
    {id: 'manufacturer', label: 'Manufacturer', onChange: setManufacturer, value: manufacturer, type: 'text'},
    {id: 'color', label: 'Color', onChange: setColor, value: color, type: 'text'},
    {id: 'testDate', label: 'Test date', onChange: setTestDate, value: testDate, type: 'date'}, 
    {id: 'expireDate', label: 'Expire date', onChange: setExpireDate, value: expireDate, type: 'date', withExpirationHighlight: true}, 
    {id: 'manufactureDate', label: 'Manufacture date', onChange: setManufactureDate, value: manufactureDate, type: 'date'}, 
    {id: 'totalThroughput', label: 'Total throughput in Mtons', onChange: setTotalThroughput, value: totalThroughput, type: 'number'}, 
    {id: 'hoursInUse', label: 'Total hours in use', onChange: setHoursInUse, value: hoursInUse, type: 'number'}, 
    {id: 'remark', label: 'Remark', onChange: setRemark, value: remark, type: 'text'}, 
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this hose?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

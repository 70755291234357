import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOGOUT } from 'redux/reducers/auth';

function Logout() {
  const dispatch = useDispatch();
  let history = useHistory();

  React.useEffect(() => {
    dispatch({ type: LOGOUT });
    history.push('/');
  });

  return null;
}

export default Logout;

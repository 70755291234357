import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Block from 'components/common/block';
import EditProject from 'components/common/editProject';
import GeneralInformationField from 'components/common/generalInformationField';
import useEquipmentStatus from 'helpers/useEquipmentStatus';

const GetFenderQuery = gql`
  query GetFender($id: ID!) {
    node(id: $id) {
      ... on Fender {
        id
        plNumber
        status
        diameter
        length
        location
        manufacturer
        manufactureDate
        dateInService
        serialNumber
        pressure
        testDatePvValve
        expireDatePvValve
        pvIdNumber
        rope
        hoursInUse
        created
        lastUpdated
        weight
      }
    }
  }
`;

const UpdateFenderMutation = gql`
  mutation UpdateFender($input: UpdateFenderInput!) {
    updateFender(updateFenderData: $input) {
      fender {
        id
      }
    }
  }
`;

const ArchiveFenderMutation = gql`
  mutation ArchiveFender($input: ArchiveFenderInput!) {
    archiveFender(archiveFenderData: $input) {
      success
    }
  }
`;

function GeneralInformation({ id, isAdmin }) {
  const [editing, setEditing] = React.useState(false);
  const [serialNumber, setSerialNumber] = React.useState('');
  const [diameter, setDiameter] = React.useState(0);
  const [length, setLength] = React.useState(0);
  const [location, setLocation] = React.useState('');
  const [plNumber, setPlNumber] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [manufacturer, setManufacturer] = React.useState('');
  const [manufactureDate, setManufactureDate] = React.useState(null);
  const [dateInService, setDateInService] = React.useState(null);
  const [pressure, setPressure] = React.useState('');
  const [testDatePvValve, setTestDatePvValve] = React.useState(null);
  const [expireDatePvValve, setExpireDatePvValve] = React.useState(null);
  const [pvIdNumber, setPvIdNumber] = React.useState('');
  const [rope, setRope] = React.useState('');
  const [hoursInUse, setHoursInUse] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [showArchiveSnackbar, setShowArchiveSnackbar] = React.useState(false);

  const history = useHistory();

  const {statusValues} = useEquipmentStatus();

  const { data, error, loading } = useQuery(GetFenderQuery, {
    variables: {
      id,
    },
    onCompleted(result) {
      const fender = result.node;
      if (!fender) return;

      if (fender.serialNumber) setSerialNumber(fender.serialNumber);
      if (fender.diameter) setDiameter(fender.diameter);
      if (fender.length) setLength(fender.length);
      if (fender.location) setLocation(fender.location);
      if (fender.plNumber) setPlNumber(fender.plNumber);
      if (fender.status) setStatus(fender.status);
      if (fender.manufacturer) setManufacturer(fender.manufacturer);
      if (fender.manufactureDate) setManufactureDate(moment(fender.manufactureDate), 'YYYY-MM-DD');
      if (fender.dateInService) setDateInService(moment(fender.dateInService), 'YYYY-MM-DD');
      if (fender.pressure) setPressure(fender.pressure);
      if (fender.testDatePvValve) setTestDatePvValve(moment(fender.testDatePvValve), 'YYYY-MM-DD');
      if (fender.expireDatePvValve) setExpireDatePvValve(moment(fender.expireDatePvValve), 'YYYY-MM-DD');
      if (fender.pvIdNumber) setPvIdNumber(fender.pvIdNumber);
      if (fender.rope) setRope(fender.rope);
      if (fender.hoursInUse) setHoursInUse(fender.hoursInUse);
      if (fender.weight) setWeight(fender.weight);
    },
    onError(e) {
      console.log(e);
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeUpdateMutation, { data: updateData }] = useMutation(UpdateFenderMutation, {
    variables: {
      input: {
        fenderId: id,
        serialNumber,
        diameter: diameter || 0,
        length: length || 0,
        location,
        plNumber,
        status,
        manufacturer,
        manufactureDate: manufactureDate ? moment(manufactureDate).format('YYYY-MM-DD') : null,
        dateInService: dateInService ? moment(dateInService).format('YYYY-MM-DD') : null,
        pressure,
        testDatePvValve: testDatePvValve ? moment(testDatePvValve).format('YYYY-MM-DD') : null,
        expireDatePvValve: expireDatePvValve ? moment(expireDatePvValve).format('YYYY-MM-DD') : null,
        pvIdNumber,
        rope,
        hoursInUse: hoursInUse || 0,
        weight: weight || 0,
      },
    },
  });

  const [executeArchive, { loading: archiveLoading }] = useMutation(ArchiveFenderMutation, {
    variables: {
      input: {
        fenderId: id,
      },
    },
    onCompleted: () => {
      history.push('/dashboard/equipment/fenders');
    },
    onError: (e) => {
      console.log(e)
    }
  });

  function save() {
    executeUpdateMutation();
    setEditing(false);
  }

  function cancel() {
    const lastSaved = updateData ? updateData.updateFender.fender : data.node;

    setSerialNumber(lastSaved.serialNumber)
    setDiameter(lastSaved.diameter)
    setLength(lastSaved.length)
    setLocation(lastSaved.location)
    setPlNumber(lastSaved.plNumber)
    setStatus(lastSaved.status)
    setManufacturer(lastSaved.manufacturer)
    setManufactureDate(moment(lastSaved.manufactureDate), 'YYYY-MM-DD')
    setDateInService(moment(lastSaved.dateInService), 'YYYY-MM-DD')
    setPressure(lastSaved.pressure)
    setTestDatePvValve(moment(lastSaved.testDatePvValve), 'YYYY-MM-DD')
    setExpireDatePvValve(moment(lastSaved.expireDatePvValve), 'YYYY-MM-DD')
    setPvIdNumber(lastSaved.pvIdNumber)
    setRope(lastSaved.rope)
    setHoursInUse(lastSaved.hoursInUse)
    setWeight(lastSaved.weight)
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error || !data.node) return null;

  const fields = [
    {id: 'plNumber', label: 'PL Nr.', onChange: setPlNumber, value: plNumber, type: 'text'}, 
    {id: 'serialNumber', label: 'Serial Nr.', onChange: setSerialNumber, value: serialNumber, type: 'text'}, 
    {id: 'diameter', label: 'Diameter in meters', onChange: setDiameter, value: diameter, type: 'number'}, 
    {id: 'length', label: 'Length in meters', onChange: setLength, value: length, type: 'number'}, 
    {id: 'weight', label: 'Weight in KG', onChange: setWeight, value: weight, type: 'number'},
    {id: 'location', label: 'Location', onChange: setLocation, value: location, type: 'text'}, 
    {id: 'manufacturer', label: 'Manufacturer', onChange: setManufacturer, value: manufacturer, type: 'text'}, 
    {id: 'manufactureDate', label: 'Manufacture date', onChange: setManufactureDate, value: manufactureDate, type: 'date'}, 
    {id: 'dateInService', label: 'Date in service', onChange: setDateInService, value: dateInService, type: 'date'}, 
    {id: 'pressure', label: 'Pressure', onChange: setPressure, value: pressure, type: 'text'}, 
    {id: 'testDatePvValve', label: 'Test date PV valve', onChange: setTestDatePvValve, value: testDatePvValve, type: 'date'}, 
    {id: 'expireDatePvValve', label: 'Expire date PV valve', onChange: setExpireDatePvValve, value: expireDatePvValve, type: 'date', withExpirationHighlight: true}, 
    {id: 'pvIdNumber', label: 'PV ID Nr.', onChange: setPvIdNumber, value: pvIdNumber, type: 'text'}, 
    {id: 'rope', label: 'Rope', onChange: setRope, value: rope, type: 'text'}, 
    {id: 'hoursInUse', label: 'Hours in use', onChange: setHoursInUse, value: hoursInUse, type: 'number'},
    {id: 'status', label: 'Status', onChange: setStatus, value: status, type: 'select', options: statusValues()},
  ];

  const disabled = !serialNumber.length || !plNumber.length || loading || archiveLoading;

  return (
    <Block
      title={
        <BlockTitle>
          GENERAL INFORMATION
          {
            isAdmin && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                save={save}
                cancel={cancel}
                cancelLabel="Cancel"
                saveDisabled={disabled}
                setShowArchiveSnackbar={() => setShowArchiveSnackbar(true)}
              /> 
            )
          }
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      <Grid container spacing={2}>
        {
          fields.map((field) => <GeneralInformationField key={field.id} field={field} editing={editing} />)
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showArchiveSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowArchiveSnackbar(false)}
        message="Delete this fender?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={executeArchive}>
              YES
            </Button>
            <Button color="secondary" size="small" onClick={() => setShowArchiveSnackbar(false)}>
              NO
            </Button>
          </React.Fragment>
        }
      />
    </Block>
  )
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default GeneralInformation;

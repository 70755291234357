import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Block from 'components/common/block';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import Avatar from 'components/common/avatar';
import Tag from 'components/common/tag';
import EditableUser from 'components/common/editableStsProjectUser';
import EditProject from 'components/common/editProject';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';

const StsProjectUsersQuery = gql`
  query StsProjectUsers ($first: Int, $after: String, $sortBy: [String], $stsprojectId: ID){
    stsprojectUsers(first: $first, after: $after sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          role
          user {
            id
            email
            phone
            fullName
            imageUrl
          }
        }
      }
    }
  }
`;

const CreateStsProjectUserMutation = gql`
  mutation CreateStsProjectUser($input: CreateStsProjectUserInput!) {
    createStsProjectUser(createStsProjectUserData: $input) {
      stsprojectUser {
        id
        role
        user {
          id
          email
          phone
        }
      }
    }
  }
`;

function PersonnelTable({ stsprojectUsers }) {
  function tagColor(role) {
    switch(role) {
      case 'supervisor': return 'green';
      case 'mooringmaster': return 'yellow';
      default: return 'yellow';
    }
  }

  if (!stsprojectUsers.length) return <NoResult>No users were added</NoResult>;
  return (
    <TableContainer>
      <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              Avatar
            </TableCell>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Email
            </TableCell>
            <TableCell>
              Phone
            </TableCell>
            <TableCell>
              Role
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stsprojectUsers.map((stsUser, index) => (
            <TableRow key={stsUser.id}>
              <TableCell style={{ borderBottom: index === stsprojectUsers.length - 1 && 0 }}>
                <Avatar size={32} user={stsUser.user} />
              </TableCell>
              <TableCell style={{ borderBottom: index === stsprojectUsers.length - 1 && 0 }}>
                {
                  stsUser.user
                    ? <User to={`/dashboard/users/${stsUser.user.id}`}>{stsUser.user.fullName}</User>
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === stsprojectUsers.length - 1 && 0 }}>
                {
                  stsUser.user
                    ? <Mail href={`mailto:${stsUser.user.email}`}>{stsUser.user.email}</Mail>
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === stsprojectUsers.length - 1 && 0 }}>
                {
                  stsUser.user
                    ? stsUser.user.phone || 'No phone number known'
                    : <NoResult>-</NoResult>
                }
              </TableCell>
              <TableCell style={{ borderBottom: index === stsprojectUsers.length - 1 && 0 }}>
                {
                  stsUser.role
                    ? <Tag color={tagColor(stsUser.role)} label={stsUser.role} />
                    : <NoResult>-</NoResult>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EditPersonnel({ stsprojectUsers, refetch, executeCreateUser, createUserLoading }) {
  return (
    <>
      { stsprojectUsers.map((stsprojectUser, index) => <EditableUser isLast={index === stsprojectUsers.length - 1} stsprojectUser={stsprojectUser} key={stsprojectUser.id} refetch={refetch} />)}
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          disabled={createUserLoading}
          onClick={executeCreateUser}
          endIcon={<AddIcon />}
        >
         Add user
        </Button>
      </ButtonWrapper>
    </>
  );
}

function Personnel({ id, allowEdit }) {
  const [editing, setEditing] = React.useState(false);
  const { data, loading, error, refetch } = useQuery(StsProjectUsersQuery, {
    variables: {
      stsprojectId: id,
      sortBy: ['created asc'],
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeCreateUser, { loading: createUserLoading }] = useMutation(CreateStsProjectUserMutation, {
    variables: {
      input: {
        stsprojectId: id,
      },
    },
    onCompleted() {
      refetch()
    },
  });

  function cancel() {
    setEditing(false);
  }

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;
  if (error) return null

  const stsprojectUsers = data ? data.stsprojectUsers.edges.map(u => u.node) : [];

  return (
    <Block
      title={
        <BlockTitle>
          PERSONNEL
          {
            allowEdit && (
              <EditProject
                editing={editing}
                setEditing={setEditing}
                cancel={cancel}
                noSave
                cancelLabel="Finish"
              /> 
            )
          } 
        </BlockTitle>
      }
      style={{ marginBottom: '16px' }}
    >
      {
        editing
          ? <EditPersonnel stsprojectUsers={stsprojectUsers} refetch={refetch} executeCreateUser={executeCreateUser} createUserLoading={createUserLoading} />
          : <PersonnelTable stsprojectUsers={stsprojectUsers} />
      }
    </Block>
  );
}

const User = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Mail = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const NoResult = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  padding: 0 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Personnel;

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Avatar from 'components/common/avatar';
import Pagination from 'components/common/pagination';
import Tag from 'components/common/tag';

import { ReactComponent as OrderDownIcon } from 'assets/vectors/order-down-16px.svg';

function UserTable({ users, paginationVariables, setPaginationVariables, resultCount, pageInfo, sortBy, setSortBy }) {
  function sortHandler(type) {
    if (type !== sortBy.type) {
      setSortBy({ type, direction: 'desc' });
    } else if (type === sortBy.type && sortBy.direction === 'desc') {
      setSortBy({ ...sortBy, direction: 'asc'});
    } else if (type === sortBy.type && sortBy.direction === 'asc') {
      setSortBy({ ...sortBy, direction: 'desc'});
    }
  }
  return (
    <Wrapper>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Avatar                
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('firstName')}>
                Name <StyledOrderIcon active={sortBy.type === 'firstName' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell>
                Roles
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('email')}>
                Email <StyledOrderIcon active={sortBy.type === 'email' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
              <TableCell style={{ cursor: 'pointer'}} onClick={() => sortHandler('phone')}>
                Phone <StyledOrderIcon active={sortBy.type === 'phone' ? 1 : 0} direction={sortBy.direction} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Avatar size={32} user={user} />
                </TableCell>
                <TableCell>
                  <User to={`/dashboard/users/${user.id}`}>{user.fullName}</User>
                </TableCell>
                <TableCell>
                  <Roles>
                    {user.roles.sort().map(r => <Tag color={r === 'member' ? 'yellow' : 'green'} label={r} key={r} style={{marginRight: '8px'}} />)}
                  </Roles>
                </TableCell>
                <TableCell>
                  <Mail href={`mailto:${user.email}`}>{user.email}</Mail>
                </TableCell>
                <TableCell>
                  {user.phone}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        paginationVariables={paginationVariables}
        setPaginationVariables={setPaginationVariables}
        resultCount={resultCount}
        pageInfo={pageInfo}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  
`;

const Mail = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const User = styled(NavLink)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Roles = styled.div`
  display: flex;
`;

const StyledOrderIcon = styled(OrderDownIcon)`
  display: ${props => props.active ? 'inline-flex' : 'none'};
  transform: ${props => props.direction === 'desc' ? 'none' : 'rotate(180deg)'};
  margin-left: 4px;
  position: relative;
  top: 4px;
`;

export default UserTable;
import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

import Modal from 'components/common/modal';
import { ReactComponent as SendIcon } from 'assets/vectors/send-white-16px.svg';

const SendStsProjectReportMutation = gql`
  mutation CreateStsProjectEquipment($input: CreateStsProjectEquipmentInput!) {
    createStsProjectEquipment(createStsProjectEquipmentData: $input) {
      stsprojectEquipment {
        id
        created
      }
    }
  }
`;

function JobLogModal({ stsBoxId, setShowJobLogModal, refetchJobs }) {
  const [id, setId] = React.useState('CUSTOM-ID: ');
  const [product, setProduct] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [executeSendReport, { loading }] = useMutation(SendStsProjectReportMutation, {
    variables: {
      input: {
        stsboxId: stsBoxId,
        customProjectId: id,
        customProduct: product,
        customLocation: location,
        customStartDate: moment(startDate).format('YYYY-MM-DD'),
        customEndDate: moment(endDate).format('YYYY-MM-DD'),
      }
    },
    onCompleted() {
      refetchJobs();
      setShowJobLogModal();
    },
  });

  const disabled = !id.length || !product.length || !location.length || !startDate || !endDate || loading;

  return (
    <Modal onClose={() => setShowJobLogModal(false)} title="SEND REPORT" preventOutsideClick>
      <Title>JOB LOG ENTRY</Title>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          <TextField
            id="product"
            label="Product"
            variant="outlined"
            onChange={e => setProduct(e.target.value)}
            value={product}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <TextField
            id="location"
            label="Location"
            variant="outlined"
            onChange={e => setLocation(e.target.value)}
            value={location}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/YYYY"
            id="startDate"
            label="Start date"
            value={startDate}
            onChange={setStartDate}
            autoOk
            fullWidth
            inputVariant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/YYYY"
            id="endDate"
            label="End date"
            value={endDate}
            onChange={setEndDate}
            autoOk
            fullWidth
            inputVariant="outlined"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            id="notes"
            label="Notes"
            variant="outlined"
            onChange={e => setId(e.target.value)}
            value={id}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>

      <ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={executeSendReport}
          endIcon={<SendIcon />}
        >
          Add entry
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export default JobLogModal;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import Block from 'components/common/block';
import File from 'components/common/fileRow';
import EditProject from 'components/common/editProject';
import FileUpload from 'components/common/fileUpload';
import { ReactComponent as DocumentIcon } from 'assets/vectors/document-16px.svg';

const GetStsDocumentsQuery = gql`
  query GetStsProject($id: ID!) {
    node(id: $id) {
      ... on StsProject {
        id
        attachedDocuments {
          id
          filename
        }
        attachedStstimestampDocuments {
          id
          filename
        }
      }
    }
  }
`;

const ArchiveDocumentMutation = gql`
  mutation ArchiveDocument($input: ArchiveDocumentInput!) {
    archiveDocument(archiveDocumentData: $input) {
      success
    }
  }
`;

const UploadDocument = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(uploadDocumentData: $input) {
      document {
        id
        documentUrl
      }
    }
  }
`;

function Documents({ id, allowEdit }) {
  const [editing, setEditing] = React.useState(false);
  const { data, loading, refetch } = useQuery(GetStsDocumentsQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [executeArchiveDocument] = useMutation(ArchiveDocumentMutation, {
    onCompleted(result) {
      refetch();
    },
  });

  const [executeUploadDocument, { loading: documentLoading }] = useMutation(UploadDocument, {
    onCompleted() {
      refetch();
    }
  });

  if (loading) return <CircularProgress size={64} style={{ margin: '32px auto', display: 'block' }} />;

  function removeFile(id) {
    executeArchiveDocument({
      variables: {
        input: {
          documentId: id,
        }
      }
    })
  }

  function addFile(file) {
    executeUploadDocument({
      variables: {
        input: {
          file,
          filename: file.name,
          stsprojectId: id,
        }
      }
    })
  }

  if (!data.node) return null;
  const documents = data.node.attachedDocuments;
  const timestampDocuments = data.node.attachedStstimestampDocuments;

  return (
    <>
      <Block
        title={
          <BlockTitle>
            DOCUMENTS
            {
              allowEdit && (
                <EditProject
                  editing={editing}
                  setEditing={setEditing}
                  noSave
                  cancel={() => setEditing(false)}
                  cancelLabel="Finish"
                />
              )
            }
          </BlockTitle>
        }
        style={{ marginBottom: '16px' }}
      >
        <Inner>
          {
            editing && (
              <UploadWrapper>
                <FileUpload
                  id="newFile"
                  allowedTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv', 'application/vnd.ms-excel']}
                  upload={addFile}
                >
                  <Add htmlFor="newFile">
                    ADD FILE <DocumentIcon />
                    <input
                      type="file"
                      required
                      id="newFile"
                      onChange={addFile}
                      style={{ display: 'none' }}
                    />
                  </Add>
                </FileUpload>
              </UploadWrapper>
            )
          }
          <Files>
            <FilesLabel>Attached files</FilesLabel>
            {
              documents.length
                ? documents.map(f => <File key={f.id} file={f} showDelete={editing} removeFile={removeFile} />)
                : <File noResults />
            }
            { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
          </Files>
          <Title>TIMELINE DOCUMENTS</Title>
          <Files>
            <FilesLabel>Attached files</FilesLabel>
            {
              timestampDocuments.length
                ? timestampDocuments.map(f => <File key={f.id} file={f} showDelete={editing} removeFile={removeFile} />)
                : <File noResults />
            }
            { documentLoading && <CircularProgress size={16} style={{ marginTop: '16px' }} /> }
          </Files>
        </Inner>
      </Block>
    </>
  );
}

const Inner = styled.div`
  padding: 0 12px;
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Add = styled.label`
  color: ${props => props.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  padding: 8px;
  border-radius: 4px;

  & svg {
    margin-left: 8px;
    opacity: .5
  }
`;

const Files = styled.div`
  margin-top: 32px;
`;

const FilesLabel = styled.p`
  font-size: 11px;
  color: black;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  font-weight: 500;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 700;
  margin: 36px 0 36px -12px;
`;

export default Documents;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute ({ component, allowedRoles, ...otherProps }) {
  const loggedInUserRoles = useSelector(state => state.auth.user.roles);
  const isPermitted = allowedRoles.some(r => loggedInUserRoles.includes(r));

  if (isPermitted) {
    return <Route component={component} {...otherProps} />
  } else {
    return <Redirect to={{ pathname: '/' }} />
  }
};

export default ProtectedRoute;

import React from 'react';
import styled from 'styled-components';

function Section({ title, children }) {
  return (
    <Wrapper>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const SectionTitle = styled.p`
  padding: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.theme.colors.textColor};
`;

export default Section;

import React from 'react';
import styled from 'styled-components';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

import SideMenu from 'components/sideMenu';
import NotFound from 'components/common/404';
import ProtectedRoute from 'helpers/protectedRoute';

import Home from './home';
import STSOverview from './sts/overview';
import STSNew from './sts/new';
import STSDetail from './sts/detail';
import STSSettings from './sts/settings';
import UsersOverview from './users/overview';
import UsersNew from './users/new';
import UserDetail from './users/detail';

import EquipmentFenders from './equipment/fenders/overview';
import EquipmentFendersNew from './equipment/fenders/new';
import EquipmentFenderDetail from './equipment/fenders/detail';

import EquipmentReducers from './equipment/reducers/overview';
import EquipmentReducersNew from './equipment/reducers/new';
import EquipmentReducerDetail from './equipment/reducers/detail';

import EquipmentHoses from './equipment/hoses/overview';
import EquipmentHosesNew from './equipment/hoses/new';
import EquipmentHoseDetail from './equipment/hoses/detail';

import EquipmentHoseBuns from './equipment/hoseBuns/overview';
import EquipmentHoseBunsNew from './equipment/hoseBuns/new';
import EquipmentHoseBunDetail from './equipment/hoseBuns/detail';

import EquipmentInsulationFlanges from './equipment/insulationFlanges/overview';
import EquipmentInsulationFlangesNew from './equipment/insulationFlanges/new';
import EquipmentInsulationFlangeDetail from './equipment/insulationFlanges/detail';

import EquipmentStsBoxes from './equipment/stsBoxes/overview';
import EquipmentStsBoxesNew from './equipment/stsBoxes/new';
import EquipmentStsBoxDetail from './equipment/stsBoxes/detail';

import EquipmentMiscellaneous from './equipment/miscellaneous/overview';
import EquipmentMiscellaneousNew from './equipment/miscellaneous/new';
import EquipmentMiscellaneousDetail from './equipment/miscellaneous/detail';

function Dashboard() {
  let { path } = useRouteMatch();

  return (
    <Wrapper>
      <SideMenu />
      <Content>
        <Switch>
          <Route exact path={path} component={Home} />
          {/* STS Projects */}
          <Route exact path={`${path}/sts`} component={STSOverview} />
          <ProtectedRoute exact path={`${path}/sts/new`} component={STSNew} allowedRoles={['admin']} />
          <ProtectedRoute exact path={`${path}/sts/settings`} component={STSSettings} allowedRoles={['admin']} />
          <Route exact path={`${path}/sts/:id`} component={STSDetail} />
          {/* Users */}
          <Route exact path={`${path}/users`} component={UsersOverview} />
          <ProtectedRoute exact path={`${path}/users/new`} component={UsersNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/users/:id`} component={UserDetail} />
          {/* Fenders */}
          <Route exact path={`${path}/equipment/fenders`} component={EquipmentFenders} />
          <ProtectedRoute exact path={`${path}/equipment/fenders/new`} component={EquipmentFendersNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/fenders/:id`} component={EquipmentFenderDetail} />
          {/* Reducers */}
          <Route exact path={`${path}/equipment/reducers`} component={EquipmentReducers} />
          <ProtectedRoute exact path={`${path}/equipment/reducers/new`} component={EquipmentReducersNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/reducers/:id`} component={EquipmentReducerDetail} />
          {/* Hoses */}
          <Route exact path={`${path}/equipment/hoses`} component={EquipmentHoses} />
          <ProtectedRoute exact path={`${path}/equipment/hoses/new`} component={EquipmentHosesNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/hoses/:id`} component={EquipmentHoseDetail} />
          {/* Hose buns */}
          <Route exact path={`${path}/equipment/hoseBuns`} component={EquipmentHoseBuns} />
          <ProtectedRoute exact path={`${path}/equipment/hoseBuns/new`} component={EquipmentHoseBunsNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/hoseBuns/:id`} component={EquipmentHoseBunDetail} />
          {/* Insulation flanges */}
          <Route exact path={`${path}/equipment/insulationFlanges`} component={EquipmentInsulationFlanges} />
          <ProtectedRoute exact path={`${path}/equipment/insulationFlanges/new`} component={EquipmentInsulationFlangesNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/insulationFlanges/:id`} component={EquipmentInsulationFlangeDetail} />
          {/* Sts boxes */}
          <Route exact path={`${path}/equipment/stsBoxes`} component={EquipmentStsBoxes} />
          <ProtectedRoute exact path={`${path}/equipment/stsBoxes/new`} component={EquipmentStsBoxesNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/stsBoxes/:id`} component={EquipmentStsBoxDetail} />
          {/* Miscellaneous */}
          <Route exact path={`${path}/equipment/miscellaneous`} component={EquipmentMiscellaneous} />
          <ProtectedRoute exact path={`${path}/equipment/miscellaneous/new`} component={EquipmentMiscellaneousNew} allowedRoles={['admin']} />
          <Route exact path={`${path}/equipment/miscellaneous/:id`} component={EquipmentMiscellaneousDetail} />
          <Route component={NotFound} />
        </Switch>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100% - 48px);
`;

const Content = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
  padding: 24px;

  @media (${props => props.theme.breakpoints.tablet}) {
    padding: 16px;
  }
`;

export default Dashboard;

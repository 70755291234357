import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

function Pagination({ paginationVariables, setPaginationVariables, resultCount, pageInfo, style }) {
  function prev(startCursor) {
    setPaginationVariables({
      last: resultCount,
      before: startCursor,
    });
  };

  function next(endCursor) {
    setPaginationVariables({
      first: resultCount,
      after: endCursor,
    });
  };


  const prevDisabled = !pageInfo.hasPreviousPage && !paginationVariables.after;
  const nextDisabled = !pageInfo.hasNextPage && !paginationVariables.before;

  return (
    <Wrapper style={style}>
      <Button
        variant="outlined"
        color="primary"
        disabled={prevDisabled}
        onClick={() => prev(pageInfo.startCursor)}
        style={{ marginRight: '8px' }}
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        color="primary"
        disabled={nextDisabled}
        onClick={() => next(pageInfo.endCursor)}
        style={{ marginLeft: '8px' }}
      >
        Next
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`;

export default Pagination;

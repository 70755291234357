import React from 'react';
import styled from 'styled-components';

function NotFound() {
  return (
    <Wrapper>
      404
      <p style={{ marginTop: '32px' }}>The page could not be found</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin: 32px;
  color: ${props => props.theme.colors.primaryColor};
  font-size: 40px;
  font-weight: 700;
`;

export default NotFound;
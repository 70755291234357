import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { ReactComponent as ChevronDown } from 'assets/vectors/dropdown-16px.svg';
import Avatar from 'components/common/avatar';

function LoggedInUser({ user }) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          paddingRight: 0,
        }}
      >
        <Avatar size={32} user={user} />
        <StyledChevronDown open={open} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <StyledNavLink to={`/dashboard/users/${user.id}`}><MenuItem onClick={handleClose}>My account</MenuItem></StyledNavLink>
                  <StyledNavLink to="/logout"><MenuItem onClick={handleClose}>Logout</MenuItem></StyledNavLink>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const StyledChevronDown = styled(ChevronDown)`
  transform: rotate(${props => props.open ? '180deg' : 0});
  transition: all ease .2s;
  margin-left: 8px;
  flex: none;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
`;

export default LoggedInUser;
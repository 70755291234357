import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as WhiteCloseIcon } from 'assets/vectors/close-white-16px.svg';

function FileUpload({ id, allowedTypes, upload, children, disabled }) {
  const [errorSnackbarVisible, setErrorSnackbarVisible] = React.useState(false);

  const mapMimetoExtensions = React.useCallback((val) => {
    switch (val) {
      case 'image/png': return ['.png'];
      case 'image/jpeg': return ['.jpeg'];
      case 'image/jpg': return ['.jpg'];
      case 'application/pdf': return ['.pdf'];
      case 'text/csv': return ['.csv'];
      case 'application/vnd.ms-excel': return ['.xls', '.xlsx'];
      default: return [''];
    }
  }, [])

  const mapped = allowedTypes.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: mapMimetoExtensions(cur),
    }
  }, {});

  const onDrop = React.useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      upload(file)
    })
  }, [upload]);

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({ onDrop, accept: mapped })

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarVisible(false);
  };

  return (
    <>
      {
        !disabled && (
          <UploadZone {...getRootProps()}>
            <input id={id} {...getInputProps()} />
            {
              isDragReject ?
              <p>One or more files are not allowed</p> :
              isDragActive ?
                <p>Drop the files here ...</p> :
                  <p>Drop files here, or click to select</p>
            }
            {
              fileRejections.length ? 
              <Rejected>
                <p>The following files are of a non-accepted file type:</p>
                {fileRejections.map((file) => {
                return <p key={file.file.name}>{file.file.name}</p>
              })}
              </Rejected>
              : null
            }
          </UploadZone>
        )
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Invalid file type"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <WhiteCloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )
}

const UploadZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 128px;
  border: 2px dashed ${props => props.theme.colors.primaryColor};
  width: 100%;
  cursor: pointer;
`;

const Rejected = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 16px;

& > p {
  font-size: 12px
}
`;

export default FileUpload;

const userNode = `
  user {
    id
    firstName
    lastName
    fullName
    phone
    email
    roles
    imageUrl
  }
`;

export default userNode;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute ({ component, ...otherProps }) {
  const loggedIn = useSelector(state => state.auth.loggedIn);

  if (loggedIn) {
    return <Route component={component} {...otherProps} />;
  } else if (loggedIn === false) {
    return <Redirect to={{ pathname: '/', state: { returnUrl: otherProps.location.pathname} }} />;
  } else {
    return null;
  }
};

export default PrivateRoute;

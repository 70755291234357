import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Block from 'components/common/block';
import { ReactComponent as DashboardIcon } from 'assets/vectors/dashboard-24px.svg';

import Projects from './projects';
import Hoses from './hoses';
import HoseBuns from './hoseBuns';
import Fenders from './fenders';

function DashboardHome() {
  const loggedInUser = useSelector(state => state.auth.user);
  const isAdmin = loggedInUser.roles.includes('admin');

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Block title={<BlockTitle><DashboardIcon style={{ marginRight: '8px'}} /> DASHBOARD</BlockTitle>}>Welcome, {loggedInUser.firstName}</Block>
        </Grid>
        <Grid item md={6} xs={12}>
          <Projects isAdmin={isAdmin} loggedInUser={loggedInUser} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Hoses />
        </Grid>
        <Grid item md={6} xs={12}>
          <HoseBuns />
        </Grid>
        <Grid item md={6} xs={12}>
          <Fenders />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primaryColor};
`;

const Wrapper = styled.div`
  margin-top: 0;
  
  @media (${props => props.theme.breakpoints.tablet}) {
    margin-top: 16px;
  }
`;

export default DashboardHome;

import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Block from 'components/common/block';
import { ReactComponent as TrashIcon } from 'assets/vectors/remove-16px.svg';
import { ReactComponent as AddIcon } from 'assets/vectors/add-green-16px.svg';
import { ReactComponent as FenderIcon } from 'assets/vectors/fenderIcon.svg';

import FendersModal from './fendersModal';

const ArchiveStsProjectEquipment = gql`
  mutation ArchiveStsProjectEquipment($input: ArchiveStsProjectEquipmentInput!) {
    archiveStsProjectEquipment(archiveStsProjectEquipmentData: $input) {
      success
    }
  }
`;

const GetFenders = gql`
  query StsProjectEquipments ($sortBy: [String], $stsprojectId: ID){
    stsprojectEquipments(sortBy: $sortBy, stsprojectId: $stsprojectId) {
      edges {
        node {
          id
          fender {
            id
            plNumber
            serialNumber
            diameter
            length
            location
          }
        }
      }
    }
  }
`;

function Fenders({ projectId, onAdd, allowEdit }) {
  const [showFendersModal, setShowFendersModal] = React.useState(false);

  const {data, refetch} = useQuery(GetFenders, {
    variables: {
      stsprojectId: projectId,
      sortBy: ['created desc']
    },
    onError(e) {
      console.log(e);
    }
  });

  const [executeArchiveStsProjectEquipment, { loading: archiveLoading }] = useMutation(ArchiveStsProjectEquipment, {
    onCompleted() {
      refetch();
    }
  });

  function addFender() {
    setShowFendersModal(true);
  }

  function removeFender(equipmentId) {
    executeArchiveStsProjectEquipment({
      variables: {
        input: {
          stsprojectEquipmentId: equipmentId,
        }
      }
    })
  }

  const projectEquipments = data ? data.stsprojectEquipments.edges.map(r => r.node).filter(r => !!r.fender) : [];
  const addedFenders = projectEquipments.map(r => r.fender.id);

  return (
    <StyledBlock title={<BlockTitle><FenderIcon style={{ marginRight: '8px'}} /> Fenders</BlockTitle>}>
      <TableContainer>
        <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                PL Nr.
              </TableCell>
              <TableCell>
                Serial Nr.
              </TableCell>
              <TableCell>
                Diameter
              </TableCell>
              <TableCell>
                Length
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {
              !projectEquipments.length
                ? <TableRow>
                    <TableCell>None added</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                : (
                  projectEquipments.map(equipment => (
                    <TableRow key={equipment.id}>
                      <TableCell>
                        <Link href={`/dashboard/equipment/fenders/${equipment.fender.id}`} target="_blank">{equipment.fender.plNumber}</Link>
                      </TableCell>
                      <TableCell>
                        {equipment.fender.serialNumber}
                      </TableCell>
                      <TableCell>
                        {equipment.fender.diameter}
                      </TableCell>
                      <TableCell>
                        {equipment.fender.length}
                      </TableCell>
                      <TableCell>
                        {equipment.fender.location}
                      </TableCell>
                      <TableCell align="right">
                        {
                          allowEdit && (
                            <IconButton
                              onClick={() => removeFender(equipment.id)}
                              aria-label="delete fender"
                              className="deleteButton"
                              disabled={archiveLoading}
                            >
                              <TrashIcon />
                            </IconButton>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        allowEdit && (
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={addFender}
              endIcon={<AddIcon />}
            >
            Add fender
            </Button>
          </ButtonWrapper>
        )
      }
      { showFendersModal && <FendersModal setShowFendersModal={setShowFendersModal} projectId={projectId} refetch={refetch} addedFenders={addedFenders} onAdd={onAdd} /> }
    </StyledBlock>
  );
}

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.textColor};
`;

const StyledBlock = styled(Block)`
  width: 1080px;
  max-width: 100%;
  margin-bottom: 16px;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  @media (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export default Fenders;
